import React, { useState, useEffect } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { FaBullhorn } from "react-icons/fa";
import { FaCalendarDay, FaBell } from "react-icons/fa";
import {
  update_user_account_details,
  update_admin_account_details,
  resellersendOTP,
  resellerverifyOTP,
  resellerupdatepassword,
  sendCallbackRequest,
  getCallbackDetails,
  updateCallbackStatus,
} from "../../utils/api";
import UserRegistrationFormModal from "./UserRegistrationFormModal"; // Import the UserRegistrationFormModal component
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";

const Navbar = ({ data, isAdmin }) => {
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [hasUpcomingEvents, setHasUpcomingEvents] = useState(true); // This can be based on your event data
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(true); // Based on your notification data
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showCallBackRequestModal, setShowCallBackRequestModal] =
    useState(false);
  const [profileInfo, setProfileInfo] = useState({
    name: "",
    companyName: "",
    email: "",
    phoneNumber: "",
  });
  const [resetPasswordStage, setResetPasswordStage] = useState(1); // 1: email input, 2: OTP input, 3: password reset
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [announcementCount, setAnnouncementCount] = useState(3); // Replace with actual data
  const [notificationCount, setNotificationCount] = useState(5); // Replace with actual data
  const [loading, setLoading] = useState(false);
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const admindetailsmodules = localStorage.getItem("admindetails");
  // const role = JSON.parse(admindetailsmodules).role || "";
  // const admin_id = JSON.parse(admindetailsmodules).adminid;
  const [callBackRequestsDetails, setCallBackRequestsDetails] = useState([]);
  const [pendingCallBackRequestCount, setPendingCallBackRequestCount] =
    useState(0);

  // Inline styles for icon containers and dot indicators
  const iconContainerStyle = {
    position: "relative",
    display: "inline-block",
    marginRight: "15px",
  };

  const badgeStyle = {
    position: "absolute",
    top: "-5px",
    right: "-10px",
    backgroundColor: "#e74c3c",
    color: "white",
    borderRadius: "50%",
    padding: "2px 6px",
    fontSize: "0.75em",
    fontWeight: "bold",
  };

  const dotStyle = {
    position: "absolute",
    top: "0",
    right: "0",
    width: "8px",
    height: "8px",
    backgroundColor: "#f39c12",
    borderRadius: "50%",
  };

  // Set default values based on isAdmin
  useEffect(() => {
    if (isAdmin) {
      setProfileInfo({
        name: data.username || "",
        email: data.email || "",
        phoneNumber: data.phone || "",
      });
    } else {
      setProfileInfo({
        name: data.reseller_name || "",
        companyName: data.company_name || "",
        email: data.reseller_email || "",
        phoneNumber: data.phone_number || "",
      });
    }
  }, [isAdmin, data]);
  // useEffect(() => {
  //   const fetchCallBackRequests = async () => {
  //     try {
  //       setLoading(true);
  //       const callbackdata = await getCallbackDetails(admin_id);
  //       console.log(callbackdata);
  //       setCallBackRequestsDetails(callbackdata);
  //       const pendingCallBackRequests = callbackdata.filter(
  //         (item) => item.status === "Pending"
  //       );

  //       // Update the pending count
  //       setPendingCallBackRequestCount(pendingCallBackRequests.length);
  //     } catch (error) {
  //       console.error(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchCallBackRequests();
  // }, []);
  const handleSignOut = () => {
    let logoutUrl = "/";
    if (isAdmin) {
      logoutUrl = "/adminlogin"; // Redirect to admin login page
    } else {
      logoutUrl = "/userlogin"; // Redirect to user login page
    }
    window.location.href = logoutUrl;
  };

  const handleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const openUserRegistrationPopup = () => {
    setShowRegistrationModal(true); // Show the registration modal
  };

  const handleUpdateProfile = () => {
    const updateFunction = isAdmin
      ? update_admin_account_details
      : update_user_account_details;

    // Validate profileInfo fields
    const isValidProfile = Object.values(profileInfo).every(
      (value) => value !== undefined && value !== ""
    );

    if (isValidProfile) {
      updateFunction(profileInfo)
        .then((response) => {
          window.alert("Profile Updated:", response);
          setShowProfileModal(false);
        })
        .catch((error) => {
          window.alert("Error updating profile:", error);
        });
    } else {
      window.alert("Invalid profile data:", profileInfo);
    }
  };
  const handleResetPasswordModal = () => {
    setResetPasswordStage(1);
    setEmail(profileInfo.email); // Automatically populate with the user's email
    setOtp("");
    setNewPassword("");
    setConfirmPassword("");
    setOtpSent(false);
    setShowResetPasswordModal(!showResetPasswordModal);
  };

  const handleSendOtp = async () => {
    try {
      const response = await resellersendOTP(email);
      const responseData = await response.json();
      window.alert(responseData.message); // Display success message
      setOtpSent(true);
      setResetPasswordStage(2);
    } catch (error) {
      let errorMessage = "Unknown error occurred";
      if (error.response) {
        const errorData = await error.response.json();
        errorMessage = errorData.message || "Error sending OTP";
      } else {
        errorMessage = error.message || "Error sending OTP";
      }
      window.alert("Error sending OTP: " + errorMessage); // Display error message
    }
  };
  const sendCallBackRequestAdmin = async () => {
    try {
      setLoading(true);
      const response = await sendCallbackRequest(
        parsedLoginData.reseller_email
      );
      const responseData = await response.json();
      window.alert(responseData.message);
    } catch (error) {
      let errorMessage = "Error for Sending the Callback Request";
      if (error.response) {
        const errorData = await error.response.json();
        errorMessage =
          errorData.message || "Error for Sending the Callback Request";
      } else {
        errorMessage =
          error.message || "Error for Sending the Callback Request";
      }
      window.alert("Error for Sending the Callback Request: " + errorMessage); // Display error message
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await resellerverifyOTP(email, otp);
      const responseData = await response.json();
      window.alert(responseData.message); // Display success message
      setResetPasswordStage(3);
    } catch (error) {
      let errorMessage = "Unknown error occurred";
      if (error.response) {
        const errorData = await error.response.json();
        errorMessage = errorData.message || "Error verifying OTP";
      } else {
        errorMessage = error.message || "Error verifying OTP";
      }
      window.alert("Error verifying OTP: " + errorMessage); // Display error message
    }
  };

  const handlePasswordReset = async () => {
    if (newPassword === confirmPassword) {
      try {
        const response = await resellerupdatepassword(
          email,
          newPassword,
          confirmPassword
        );
        const responseData = await response.json();
        window.alert(responseData.message + ".\nYou have to log in again."); // Display success message
        setShowResetPasswordModal(false);
        window.location.href = "/userlogin";
      } catch (error) {
        let errorMessage = "Unknown error occurred";
        if (error.response) {
          const errorData = await error.response.json();
          errorMessage = errorData.message || "Error resetting password";
        } else {
          errorMessage = error.message || "Error resetting password";
        }
        window.alert("Error resetting password: " + errorMessage); // Display error message
      }
    } else {
      window.alert("Passwords do not match!"); // Display mismatch error
    }
  };
  const handleAnnouncementClick = () => {
    setShowAnnouncementModal(true); // Show announcement modal
  };
  const handleNotificationClick = () => {
    setShowNotificationModal(true); // Show announcement modal
  };
  const handleCallBackRequestClick = () => {
    setShowCallBackRequestModal(true); // Show announcement modal
  };
  // const fetchCallBackRequests = async () => {
  //   try {
  //     setLoading(true);
  //     const callbackdata = await getCallbackDetails(admin_id);
  //     console.log(callbackdata);
  //     setCallBackRequestsDetails(callbackdata);
  //   } catch (error) {
  //     console.error(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const handleStatusChange = async (e, callbackreq_id) => {
  //   const updatedStatus = e.target.value;
  //   try {
  //     setLoading(true);
  //     const response = await updateCallbackStatus(
  //       callbackreq_id,
  //       updatedStatus
  //     );
  //     const responseData = await response.json();
  //     window.alert(responseData.message); // Display success message
  //     fetchCallBackRequests();
  //   } catch (error) {
  //     window.alert("Error for changing the status");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <UserRegistrationFormModal
        show={showRegistrationModal}
        onHide={() => setShowRegistrationModal(false)}
      />
      <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a className="navbar-brand brand-logo" href="">
          <img src="assets/images/logo.svg" alt="logo" />
        </a>
        <a className="navbar-brand brand-logo-mini" href="">
          <img src="assets/images/logo-mini.svg" alt="logo" />
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block">
            {data.reseller_logo && (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <img
                    src={data.reseller_logo}
                    alt="Reseller Logo"
                    style={{
                      height: "60px",
                      width: "100px",
                      marginRight: "10px",
                    }}
                  />
                </li>
              </ul>
            )}
          </li>
          {/* {!isAdmin && (
            <div>
              <button
                onClick={sendCallBackRequestAdmin}
                title="Click to request a callback from your Account Manager"
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "inherit",
                }}
              >
                <PhoneCallbackIcon style={{ marginRight: "8px" }} /> Request For
                Call
              </button>
            </div>
          )} */}
          {/* <>
            {isAdmin && role === "Account Manager" && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={handleCallBackRequestClick}
                  style={{ cursor: "pointer", position: "relative" }}
                  title="Callback Requests"
                >
                  <PhoneCallbackIcon style={{ marginRight: "8px" }} />
                  {pendingCallBackRequestCount > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-10px",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "50%",
                        padding: "2px 8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {pendingCallBackRequestCount}
                    </span>
                  )}
                </a>
              </li>
            )}
          </> */}
        </ul>

        <ul className="navbar-nav ml-auto">
          {!isAdmin && (
            <>
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={handleAnnouncementClick}
                  style={{ cursor: "pointer", position: "relative" }}
                  title="Announcements"
                >
                  <FaBullhorn size={20} />
                  {announcementCount > 0 && (
                    <span style={badgeStyle}>{announcementCount}</span>
                  )}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  style={{ cursor: "pointer", position: "relative" }}
                  title="Upcoming Events"
                >
                  <FaCalendarDay
                    style={{
                      fontSize: "1.5em",
                      color: hasUpcomingEvents ? "#f39c12" : "#555",
                    }}
                  />
                  {hasUpcomingEvents && <span style={dotStyle}></span>}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={handleNotificationClick}
                  style={{ cursor: "pointer", position: "relative" }}
                  title="Notifications"
                >
                  <FaBell
                    style={{
                      fontSize: "1.5em",
                      color: hasUnreadNotifications ? "#3498db" : "#555",
                    }}
                  />
                  {notificationCount > 0 && (
                    <span style={badgeStyle}>{notificationCount}</span>
                  )}
                </a>
              </li>
            </>
          )}

          <li className="nav-item dropdown d-none d-xl-inline-block user-dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="UserDropdown"
              href="#"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="img-xs rounded-circle"
                src="assets/images/faces/face8.jpg"
                alt="Profile image"
              />
              {data.username && <span className="ml-2">{data.username}</span>}
              {data.reseller_name && (
                <span className="ml-2">{data.reseller_name}</span>
              )}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              <div className="dropdown-header text-center">
                <img
                  className="img-md rounded-circle"
                  src="assets/images/faces/face8.jpg"
                  alt="Profile image"
                />
                {data.username && (
                  <p className="mb-1 mt-3 font-weight-semibold">
                    {data.username}
                  </p>
                )}
                {data.email && (
                  <p className="font-weight-light text-muted mb-0">
                    {data.email}
                  </p>
                )}
                {data.reseller_name && (
                  <p className="mb-1 mt-3 font-weight-semibold">
                    {data.reseller_name}
                  </p>
                )}
                {data.reseller_email && (
                  <p className="font-weight-light text-muted mb-0">
                    {data.reseller_email}
                  </p>
                )}
              </div>
              <a
                className="dropdown-item"
                onClick={handleProfileModal}
                style={{ cursor: "pointer" }}
              >
                My Profile
                <i className="dropdown-item-icon ti-dashboard"></i>
              </a>
              {!isAdmin &&
                data.kyc_status != null &&
                data.kyc_status != "Pending" && (
                  <a
                    className="dropdown-item"
                    onClick={openUserRegistrationPopup}
                    style={{ cursor: "pointer" }}
                  >
                    Registration Details
                    <i className="dropdown-item-icon ti-dashboard"></i>
                  </a>
                )}
              {!isAdmin && (
                <a
                  className="dropdown-item"
                  onClick={handleResetPasswordModal}
                  style={{ cursor: "pointer" }}
                >
                  Reset Password
                  <i className="dropdown-item-icon ti-dashboard"></i>
                </a>
              )}
              <a
                className="dropdown-item"
                onClick={handleSignOut}
                style={{ cursor: "pointer" }}
              >
                Sign Out<i className="dropdown-item-icon ti-power-off"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>

      <Modal show={showProfileModal} onHide={handleProfileModal}>
        <Modal.Header>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {isAdmin ? (
              <>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.name}
                    onChange={(e) =>
                      setProfileInfo({ ...profileInfo, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={profileInfo.email}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={profileInfo.phoneNumber}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.name}
                    onChange={(e) =>
                      setProfileInfo({ ...profileInfo, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.companyName}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        companyName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={profileInfo.email}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={profileInfo.phoneNumber}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleProfileModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateProfile}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showResetPasswordModal} onHide={handleResetPasswordModal}>
        <Modal.Header>
          <Modal.Title>
            {resetPasswordStage === 1
              ? "Reset Password"
              : resetPasswordStage === 2
              ? "Verify OTP"
              : "Set New Password"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resetPasswordStage === 1 && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                />
              </div>
              <Button variant="primary" onClick={handleSendOtp}>
                Send OTP
              </Button>
            </div>
          )}
          {resetPasswordStage === 2 && otpSent && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={resetPasswordStage > 1} // Disable if OTP sent
                />
                <br></br>
                <br></br>
                <label>Enter OTP</label>
                <input
                  type="text"
                  className="form-control"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <Button variant="primary" onClick={handleVerifyOtp}>
                Verify OTP
              </Button>
              <Button variant="link" onClick={handleSendOtp}>
                Resend OTP
              </Button>
            </div>
          )}
          {resetPasswordStage === 3 && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={resetPasswordStage > 1} // Disable if OTP sent
                />
                <br></br>
                <br></br>
                <label>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <Button variant="primary" onClick={handlePasswordReset}>
                Reset Password
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleResetPasswordModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAnnouncementModal}
        onHide={() => setShowAnnouncementModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Announcements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the latest announcements...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAnnouncementModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showNotificationModal}
        onHide={() => setShowNotificationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the latest notifications...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowNotificationModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={showCallBackRequestModal}
        onHide={() => setShowCallBackRequestModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>CallBack Requests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Here are the Call Back Requests will show...</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCallBackRequestModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/* <Modal
        show={showCallBackRequestModal}
        onHide={() => setShowCallBackRequestModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>CallBack Requests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <table className="table" style={{ border: "2px solid #000" }}>
                <thead>
                  <tr
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 100,
                      backgroundColor: "#dee2e6",
                    }}
                  >
                    <th>Requester Name</th>
                    <th>Requester Email</th>
                    <th>Requested Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {callBackRequestsDetails.map((callbackrequest, index) => (
                    <tr key={index}>
                      <td>{callbackrequest.requester_name || "N/A"}</td>
                      <td>{callbackrequest.requester_email || "N/A"}</td>
                      <td>{callbackrequest.timestamp || "N/A"}</td>
                      <td>
                        {callbackrequest.status !== "Pending" ? (
                          callbackrequest.status
                        ) : (
                          <select
                            className="form-control"
                            value={callbackrequest.status || ""}
                            onChange={(e) =>
                              handleStatusChange(
                                e,
                                callbackrequest.callbackreq_id
                              )
                            }
                          >
                            <option value="Pending">Pending</option>
                            <option value="On-Hold">On-Hold</option>
                            <option value="Completed">Completed</option>
                          </select>
                        )}
                      </td>
                      1
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCallBackRequestModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </nav>
  );
};

export default Navbar;
