import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  getCustomerCaseslist,
  get_products_data,
  getCustomerlist,
  registerNewCase,
  updateCaseDetails,
  getCustomerTermsconditions,
  getResellerTermsconditions,
  generateQuotationByCase,
  getSkupricewthPartnerDiscount,
} from "../../utils/api"; // Assuming you have an API function for getting customer cases list
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaSortUp, FaSortDown, FaSort, FaFilter } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CaseList = () => {
  const [cases, setCases] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [showNewCaseModal, setShowNewCaseModal] = useState(false);
  const [newCase, setNewCase] = useState({
    customer_name: "",
    customer_emailid: "",
    reseller_id: "",
    status: "",
    additional_information: "",
    cust_id: "",
    created_date: "",
    domain_name: "",
    prod_details: [],
    plantype: "",
    planduration: "",
    caseType: "",
  });
  const [customers, setCustomers] = useState([]);
  const [productData, setProductData] = useState({});
  const [selectedOEM, setSelectedOEM] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedSubproduct, setSelectedSubproduct] = useState("");
  const [subproductList, setSubproductList] = useState([]);
  const [userCount, setUserCount] = useState("");
  const [products, setProducts] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState(
    selectedCase?.additional_information || ""
  );
  const [planType, setPlanType] = useState(selectedCase?.plan_type || "");
  const [planDuration, setPlanDuration] = useState(
    selectedCase?.plan_duration || ""
  );
  const [newProduct, setNewProduct] = useState({
    oemName: "",
    productName: "",
    subproductName: "",
    userCount: "",
    plan_type: "",
    plan_duration: "",
  });
  const [loading, setLoading] = useState(false); // Loading state
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [statusFilter, setStatusFilter] = useState(""); // For filtering by status
  const [casetypeFilter, setCaseTypeFilter] = useState(""); // For filtering by status
  const [showStatusFilterOptions, setShowStatusFilterOptions] = useState(false); // Toggle for status filter dropdown
  const [showCaseTypeFilterOptions, setShowCaseTypeFilterOptions] =
    useState(false); // Toggle for status filter dropdown
  const statusFilterRef = useRef(null); // Ref for the status filter dropdown
  const caseTypeFilterRef = useRef(null); // Ref for the status filter dropdown
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [selectedQuotationFor, setSelectedQuotationFor] = useState("");
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const reseller_email = parsedLoginData.reseller_email;
  const [customerTerms, setCustomerTerms] = useState("");
  const [resellerTerms, setResellerTerms] = useState("");
  const [productsDetails, setProductsDetails] = useState([]);
  const [partnerDiscount, setPartnerDiscount] = useState("");
  const [caseType, setCaseType] = useState("");

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setLoading(true);
        const data = await get_products_data();
        setProductData(data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, []);
  useEffect(() => {
    const fetchCustomerTerms = async () => {
      try {
        setLoading(true);
        const data = await getCustomerTermsconditions();
        setCustomerTerms(
          data
            .map((item, index) => `${index + 1}. ${item.conditions}`)
            .join("\n")
        );
      } catch (error) {
        console.error("Error fetching customer terms and conditions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerTerms();
  }, []);
  useEffect(() => {
    const fetchResellersTerms = async () => {
      try {
        setLoading(true);
        const data = await getResellerTermsconditions();
        setResellerTerms(
          data
            .map((item, index) => `${index + 1}. ${item.resellers_conditions}`)
            .join("\n")
        );
      } catch (error) {
        console.error("Error fetching reseller terms and conditions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResellersTerms();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const loginDataFromStorage = localStorage.getItem("userDetails");
        if (loginDataFromStorage) {
          const parsedLoginData = JSON.parse(loginDataFromStorage);
          setEmail(parsedLoginData.reseller_email);
          const data = await getCustomerlist(parsedLoginData.reseller_email);
          setCustomers(data);
          // setInitialUserList(data);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  // Function to handle clicks outside of the status filter dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (statusFilterRef.current &&
          !statusFilterRef.current.contains(event.target)) ||
        (caseTypeFilterRef.current &&
          !caseTypeFilterRef.current.contains(event.target))
      ) {
        setShowStatusFilterOptions(false) ||
          setShowCaseTypeFilterOptions(false); // Close the dropdown if clicked outside
      }
    };

    // Add event listener when the dropdown is open
    if (showStatusFilterOptions || showCaseTypeFilterOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showStatusFilterOptions || showCaseTypeFilterOptions]);

  const handleView = (caseId) => {
    const selected = cases.find((c) => c.case_id === caseId);
    setSelectedCase(selected);
    setShowModal(true);
  };

  const handleEdit = (caseId) => {
    const selected = cases.find((c) => c.case_id === caseId);
    setSelectedCase(selected);
    setAdditionalInfo(selected?.additional_information || "");
    setPlanType(selected?.plan_type || "");
    setPlanDuration(selected?.plan_duration || "");
    setShowModal(false);
    setShowEditModal(true);
  };
  const handleQuotation = async (caseId) => {
    const selected = cases.find((c) => c.case_id === caseId);
    setSelectedCase(selected);
    setShowModal(false);
    setShowQuotationModal(true);

    // Extract product names from selectedCase.prod_details
    const skunames = selected.prod_details
      ? selected.prod_details.map((prodDetail) => Object.keys(prodDetail)[0])
      : [];
    try {
      // Call the API function with skunames and case_id
      const result = await getSkupricewthPartnerDiscount(skunames, caseId);
      setPartnerDiscount(result[0].partner_discount);
      setProductsDetails(result);
    } catch (error) {
      console.error("Error fetching products skuprice data:", error);
    }
  };

  const handleNewCaseChange = (e) => {
    const { name, value } = e.target;
    setNewCase((prevCase) => ({
      ...prevCase,
      [name]: value,
    }));
  };
  const handleEndDateChange = (date) => {
    if (date) {
      const endDateWithTime = new Date(date);
      endDateWithTime.setHours(23, 59, 59, 999); // Set time to 23:59:59
      setEndDate(endDateWithTime);
    } else {
      setEndDate(null);
    }
  };
  const filterByDateRange = () => {
    console.log(startDate, endDate);
    if (startDate && endDate) {
      // Check if endDate is before startDate
      if (endDate < startDate) {
        setDateError("End date cannot be less than the start date."); // Set error message
        return;
      }

      setDateError(""); // Clear error message if dates are valid

      const filteredList = initialUserList.filter((cse) => {
        const caseDate = new Date(cse.created_date);
        return caseDate >= startDate && caseDate <= endDate;
      });
      setCases(filteredList);
    } else {
      setDateError(""); // Clear error message if no dates are selected
      setCases(initialUserList);
    }
  };
  const handleSaveNewCase = async () => {
    try {
      setLoading(true);
      const requiredFields = [
        "cust_id",
        "customer_name",
        "customer_emailid",
        "reseller_id",
        "additional_information",
        "domain_name",
        "plantype",
        "planduration",
        "caseType",
      ];
      const missingFields = requiredFields.filter((field) => !newCase[field]);

      if (missingFields.length > 0) {
        window.alert(`Please fill out all required fields.`);
        return; // Exit the function early if any required field is missing
      }
      if (caseType !== "") {
        window.alert("Please select the case type");
        return;
      }

      // Check if any product fields are empty
      if (products.length === 0) {
        window.alert("Please add at least one product");
      }

      // Proceed with form submission if all checks pass
      const loginDataFromStorage = localStorage.getItem("userDetails");
      const parsedLoginData = JSON.parse(loginDataFromStorage);

      // Construct data to send
      const selectedOEMs = products.map((product) => product.oemName).join(",");
      const selectedProducts = products
        .map((product) => product.productName)
        .join(",");
      const selectedSubproducts = products
        .map((product) => product.subproductName)
        .join(",");
      const userCounts = products.map((product) => product.userCount).join(",");

      const formDataToSend = new FormData();
      formDataToSend.append("customer_name", newCase.customer_name);
      formDataToSend.append("customer_emailid", newCase.customer_emailid);
      formDataToSend.append("reseller_email", parsedLoginData.reseller_email);
      formDataToSend.append("status", newCase.status);
      formDataToSend.append(
        "additional_information",
        newCase.additional_information
      );
      formDataToSend.append("cust_id", newCase.cust_id);
      formDataToSend.append("created_date", newCase.created_date);
      formDataToSend.append("domain_name", newCase.domain_name);
      formDataToSend.append("plantype", newCase.plantype);
      formDataToSend.append("planduration", newCase.planduration);
      formDataToSend.append("selectedOEMs", selectedOEMs);
      formDataToSend.append("selectedProducts", selectedProducts);
      formDataToSend.append("selectedSubproducts", selectedSubproducts);
      formDataToSend.append("userCounts", userCounts);
      formDataToSend.append("caseType", caseType);

      const response = await registerNewCase(formDataToSend);
      if (response.status === 200) {
        window.alert("New case registered successfully");
        setShowNewCaseModal(false);
        handleResetModalFields();
        // Fetch updated case list after successful registration
        const updatedCases = await getCustomerCaseslist(email);
        setCases(updatedCases);
      } else {
        throw new Error("Failed to register new case");
      }
    } catch (error) {
      console.error("Error registering new case:", error.message);
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  const handleAddNewProduct = () => {
    if (
      !newProduct.oemName ||
      !newProduct.productName ||
      !newProduct.subproductName ||
      !newProduct.userCount
    ) {
      alert(
        "Please select all fields and enter a user count before adding a product."
      );
      return;
    }

    const newProductEntry = {
      [newProduct.subproductName]: newProduct.userCount,
    };

    const filteredProdDetails = selectedCase.prod_details.filter((product) => {
      const key = Object.keys(product)[0];
      return key && product[key];
    });

    const updatedProducts = [...filteredProdDetails, newProductEntry];

    setSelectedCase((prevCase) => ({
      ...prevCase,
      prod_details: updatedProducts,
    }));

    setNewProduct({
      oemName: "",
      productName: "",
      subproductName: "",
      userCount: "",
      plan_type: "",
      plan_duration: "",
    });
  };

  const handleResetModalFields = () => {
    setNewCase({
      customer_name: "",
      customer_emailid: "",
      reseller_email: "",
      status: "",
      additional_information: "",
      cust_id: "",
      created_date: "",
      domain_name: "",
      prod_details: [],
      plantype: "",
      planduration: "",
      caseType: "",
    });
    setSelectedOEM("");
    setSelectedProduct("");
    setSelectedSubproduct("");
    setSubproductList([]);
    setUserCount("");
    setProducts([]);
  };

  const handleCloseNewCaseModal = () => {
    setShowNewCaseModal(false);
    setNewCase({
      customer_name: "",
      customer_emailid: "",
      reseller_id: "",
      status: "",
      additional_information: "",
      cust_id: "",
      created_date: "",
      domain_name: "",
      prod_details: [],
      plantype: "",
      planduration: "",
      caseType: "",
    });
    setSelectedOEM("");
    setSelectedProduct("");
    setSelectedSubproduct("");
    setSubproductList([]);
    setUserCount("");
    setProducts([]);
  };
  const handleCustomerIdChange = (e) => {
    const selectedCustId = e.target.value;
    setNewCase((prevCase) => ({
      ...prevCase,
      cust_id: selectedCustId,
    }));

    const selectedCustomer = customers.find(
      (customer) => customer.cust_id === selectedCustId
    );

    if (selectedCustomer) {
      setNewCase((prevCase) => ({
        ...prevCase,
        customer_name: selectedCustomer.customer_name,
        customer_emailid: selectedCustomer.customer_emailid,
        reseller_id: email, // Assuming the reseller_id is the logged-in user's email
        domain_name: selectedCustomer.customer_domainname,
      }));
    }
  };
  const handleAddProduct = () => {
    if (!selectedOEM || !selectedProduct || !selectedSubproduct || !userCount) {
      alert(
        "Please select all fields and enter a user count before adding a product."
      );
      return;
    }
    const newProduct = {
      oemName: selectedOEM,
      productName: selectedProduct,
      subproductName: selectedSubproduct,
      userCount,
    };
    setProducts((prevProducts) => [...prevProducts, newProduct]);
    setSelectedOEM("");
    setSelectedProduct("");
    setSelectedSubproduct("");
    setUserCount("");
  };

  const handleDeleteProduct = (index) => {
    setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  const handleEditProduct = (index, product) => {
    setSelectedOEM(product.oemName);
    setSelectedProduct(product.productName);
    setSelectedSubproduct(product.subproductName);
    setUserCount(product.userCount);
    handleDeleteProduct(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const loginDataFromStorage = localStorage.getItem("userDetails");
        if (loginDataFromStorage) {
          const parsedLoginData = JSON.parse(loginDataFromStorage);
          setEmail(parsedLoginData.reseller_email);
          const casedata = await getCustomerCaseslist(
            parsedLoginData.reseller_email
          );
          setCases(casedata);
          setInitialUserList(casedata);
        }
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredCases = cases.filter((item) => {
      if (item) {
        return (
          (item.domain_name &&
            item.domain_name.toLowerCase().includes(value)) ||
          (item.case_name && item.case_name.toLowerCase().includes(value)) ||
          (item.customer_name &&
            item.customer_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setCases(filteredCases);
  };

  const handleReset = () => {
    setSearchInput("");
    setStatusFilter(""); // Clear status filter
    setStartDate("");
    setEndDate("");
    setCases(initialUserList);
  };
  const handleSort = (column) => {
    let sortedList = [...cases];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setCases(sortedList);
  };

  const getStatusStyle = (status) => {
    let color = "";
    let fontWeight = "normal";
    if (status === null) {
      return { color, fontWeight };
    }
    switch (status.toLowerCase()) {
      case "pending":
        color = "orange";
        fontWeight = "bold";
        break;
      case "accepted":
        color = "green";
        fontWeight = "bold";
        break;
      case "rejected":
        color = "red";
        fontWeight = "bold";
        break;
      default:
        break;
    }

    return { color, fontWeight };
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  // Function to handle editing product details
  const handleEditProductDetails = (index, product) => {
    setSelectedProductIndex(index); // Store the index of the product being edited
    setEditedProduct(product); // Set the product to be edited in state
    // You can also populate form fields with the product details here
  };

  // Function to handle deleting product details
  const handleDeleteProductDetails = (index) => {
    const updatedProducts = selectedCase.prod_details.filter(
      (_, i) => i !== index
    );
    setSelectedCase((prevCase) => ({
      ...prevCase,
      prod_details: updatedProducts,
    }));
  };
  const handleSaveEditedCaseDetails = async () => {
    try {
      setLoading(true);
      const newProductEntry = {
        [newProduct.productName]: newProduct.userCount,
      };

      // Filter out any incomplete or empty product details
      const filteredProdDetails = selectedCase.prod_details.filter(
        (product) => {
          const key = Object.keys(product)[0];
          const value = product[key];
          return key.trim() !== "" && value.trim() !== ""; // Check for non-empty keys and values
        }
      );

      const updatedCaseData = {
        ...selectedCase, // Include all existing case details
        prod_details: [
          ...filteredProdDetails, // Existing valid products
          newProductEntry, // New product
        ],
        additional_information: additionalInfo,
        plan_type: planType,
        plan_duration: planDuration,
      };

      // Remove any blank records like {"": ""}
      const cleanedProdDetails = updatedCaseData.prod_details.filter(
        (product) => {
          const key = Object.keys(product)[0];
          return key.trim() !== "";
        }
      );

      updatedCaseData.prod_details = cleanedProdDetails;

      // Transform prod_details into comma-separated strings
      const selectedSubproducts = cleanedProdDetails
        .map((product) => Object.keys(product)[0])
        .join(",");
      const userCounts = cleanedProdDetails
        .map((product) => Object.values(product)[0])
        .join(",");

      updatedCaseData.selectedSubproducts = selectedSubproducts;
      updatedCaseData.userCounts = userCounts;

      // Convert updatedCaseData into FormData
      const formData = new FormData();
      Object.entries(updatedCaseData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, value);
        }
      });

      //Call the updateCaseDetails function to update the case
      const response = await updateCaseDetails(formData);

      if (response.status === 200) {
        // console.log("Case Updated successfully");
        window.alert("Case Updated successfully");
        setShowEditModal(false);
        // handleResetModalFields();
        // window.location.href = "/usermaincomponent";
        // handleResetModalFields();
        // Fetch updated case list after successful registration
        const updatedCases = await getCustomerCaseslist(email);
        setCases(updatedCases);
      } else {
        window.alert("Failed to Update  case");
      }
    } catch (error) {
      console.error("Error saving edited case details:", error.message);
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  // Handle the filter by status
  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    const filteredList = cases.filter((user) => user.status === status);
    setCases(filteredList);
    setShowStatusFilterOptions(false); // Hide dropdown after selection
  };
  // Handle the filter by case type
  const handleCaseTypeFilter = (casetype) => {
    setCaseTypeFilter(casetype);
    const filteredList = cases.filter((user) => user.case_type === casetype);
    setCases(filteredList);
    setShowCaseTypeFilterOptions(false); // Hide dropdown after selection
  };
  const handleQuotationForChange = (event) => {
    setSelectedQuotationFor(event.target.value);
  };
  const handleGenerateQuotation = async () => {
    try {
      setLoading(true);
      if (!selectedQuotationFor) {
        alert("Please select the Quotation for Option");
        return;
      }
      const response = await generateQuotationByCase(
        selectedCase.case_id,
        reseller_email,
        selectedQuotationFor,
        customerTerms,
        resellerTerms
      );
      if (response.status === 200) {
        const responseData = await response.json();
        window.alert(responseData.message);
        setShowQuotationModal(false);
        setPartnerDiscount("");
        setSelectedQuotationFor("");
      } else {
        window.alert("Failed to Generate the Quotation");
      }
    } catch (error) {
      console.error("Error for generating the quotation:", error.message);
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
          .status-filter-dropdown {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            padding: 10px;
            z-index: 150;
          }
          .status-filter-dropdown p {
            color: #000; /* Default text color */
            transition: color 0.2s ease-in-out;
          }

          .status-filter-dropdown p:hover {
            color: #007bff; /* Change this to your preferred hover color */
            cursor: pointer; /* Add pointer cursor on hover */
          }
          .status-filter-item {
            padding: 5px;
            cursor: pointer;
            transition: background-color 0.2s ease, color 0.2s ease;
          }
          .casetype-filter-dropdown {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            padding: 10px;
            z-index: 150;
          }
          .casetype-filter-dropdown p {
            color: #000; /* Default text color */
            transition: color 0.2s ease-in-out;
          }

          .casetype-filter-dropdown p:hover {
            color: #007bff; /* Change this to your preferred hover color */
            cursor: pointer; /* Add pointer cursor on hover */
          }
          .casetype-filter-item {
            padding: 5px;
            cursor: pointer;
            transition: background-color 0.2s ease, color 0.2s ease;
          }


          .filter-icon {
            cursor: pointer;
          }
          .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
          .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Cases Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left", width: "174px" }} // Specify width here
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={() => setShowNewCaseModal(true)}
                style={{ float: "left" }}
                title="Register New Case"
              >
                Register Case
              </button>
              <div className="date-filter-container" style={{ float: "right" }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <button className="btn btn-primary" onClick={filterByDateRange}>
                  Filter
                </button>
              </div>
            </div>
            {dateError && <div className="text-danger mt-2">{dateError}</div>}
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Case Name</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("domain_name")}
                    >
                      Domain Name
                      {sortColumn === "domain_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles} className="sortable-header">
                      Case Type
                      <FaFilter
                        className="filter-icon"
                        onClick={() =>
                          setShowCaseTypeFilterOptions(
                            !showCaseTypeFilterOptions
                          )
                        }
                      />
                      {/* Status Filter Dropdown */}
                      {showCaseTypeFilterOptions && (
                        <div
                          ref={caseTypeFilterRef}
                          className="casetype-filter-dropdown"
                        >
                          <p
                            className="casetype-filter-item"
                            onClick={() => handleCaseTypeFilter("new_case")}
                          >
                            New Case
                          </p>
                          <p
                            className="casetype-filter-item"
                            onClick={() => handleCaseTypeFilter("renewal_case")}
                          >
                            Renewal Case
                          </p>
                        </div>
                      )}
                    </th>
                    <th style={thStyles}>Created Date</th>
                    <th style={thStyles} className="sortable-header">
                      Status
                      <FaFilter
                        className="filter-icon"
                        onClick={() =>
                          setShowStatusFilterOptions(!showStatusFilterOptions)
                        }
                      />
                      {/* Status Filter Dropdown */}
                      {showStatusFilterOptions && (
                        <div
                          ref={statusFilterRef}
                          className="status-filter-dropdown"
                        >
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("Accepted")}
                          >
                            Accepted
                          </p>
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("pending")}
                          >
                            Pending
                          </p>
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("Rejected")}
                          >
                            Rejected
                          </p>
                        </div>
                      )}
                    </th>
                    {/* <th style={thStyles}>Reseller ID</th> */}
                    {/* <th style={thStyles}>Quotation Request</th> */}
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {cases.map((user) => (
                    <tr
                      key={user.case_id}
                      onClick={() => handleView(user.case_id)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{user.case_name}</td>
                      <td>{user.customer_name}</td>
                      <td>{user.domain_name}</td>
                      <td>
                        {user.case_type
                          ? user.case_type
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")
                          : user.case_type}
                      </td>
                      <td>
                        {new Date(user.created_date).toLocaleDateString()}
                      </td>

                      <td style={getStatusStyle(user.status)}>{user.status}</td>
                      {/* <td>{user.reseller_id}</td> */}
                      {/* <td style={getStatusStyle(user.quotation_request)}>
                        {user.quotation_request}
                      </td> */}
                      {/* <td>{renderActionButtons(user)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedCase && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Header>
            <Modal.Title>
              Case Details :- {""}
              {selectedCase.case_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong> Case Type</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="casetype"
                        value={
                          selectedCase.case_type
                            ? selectedCase.case_type
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")
                            : selectedCase.case_type || ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong> Customer Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={selectedCase.customer_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong> Email ID</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={selectedCase.customer_emailid || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong> Reseller ID</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="reseller_id"
                        value={selectedCase.reseller_id || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Status</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="status"
                        value={selectedCase.status || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Additional Information</strong>
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        id="additional_information"
                        value={selectedCase.additional_information || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer ID</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="cust_id"
                        value={selectedCase.cust_id || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Created Date</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="created_date"
                        value={selectedCase.created_date || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong> Domain Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="domain_name"
                        value={selectedCase.domain_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Plan Type</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_type"
                        value={selectedCase.plan_type || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Plan Duration</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_duration"
                        value={selectedCase.plan_duration || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <h5>
              <strong>Product Details</strong>
            </h5>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>User Count</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCase.prod_details &&
                  selectedCase.prod_details.length > 0 ? (
                    selectedCase.prod_details.map((prodDetail, index) => (
                      <tr key={index}>
                        <td>{Object.keys(prodDetail)[0]}</td>
                        <td>{Object.values(prodDetail)[0]}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No product details available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {selectedCase.status.toLowerCase() === "pending" && (
              <Button
                variant="primary"
                onClick={() => handleEdit(selectedCase.case_id)}
              >
                Edit Case Details
              </Button>
            )}
            {selectedCase.status === "Accepted" && (
              <Button
                variant="primary"
                onClick={() => handleQuotation(selectedCase.case_id)}
              >
                Generate Quotation
              </Button>
            )}
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        show={showNewCaseModal}
        onHide={handleCloseNewCaseModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Register New Case</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Select Case Type<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <select
                      value={caseType}
                      onChange={(e) => setCaseType(e.target.value)}
                      style={{ width: "100%" }}
                      className="form-control"
                      required
                    >
                      <option value="">Select Case Type</option>
                      <option value="new_case">New Case</option>
                      <option value="renewal_case">Renewal Case</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer Name<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-control"
                      id="cust_id"
                      name="cust_id"
                      value={newCase.cust_id}
                      onChange={handleCustomerIdChange}
                    >
                      <option value="">Select Customer Name</option>
                      {customers.map((customer) => (
                        <option key={customer.cust_id} value={customer.cust_id}>
                          {/* {customer.cust_id}-{customer.customer_name} */}
                          {customer.customer_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer Email ID<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      className="form-control"
                      id="customer_emailid"
                      name="customer_emailid"
                      value={newCase.customer_emailid}
                      onChange={handleNewCaseChange}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Reseller ID<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="reseller_id"
                      name="reseller_id"
                      value={newCase.reseller_id}
                      onChange={handleNewCaseChange}
                      // readOnly
                    />
                  </div>
                </div>
              </div> */}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Additional Information<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      className="form-control"
                      id="additional_information"
                      name="additional_information"
                      value={newCase.additional_information}
                      onChange={handleNewCaseChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Domain Name<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="domain_name"
                      name="domain_name"
                      value={newCase.domain_name}
                      onChange={handleNewCaseChange}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Type<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <select
                      value={newCase.plantype}
                      onChange={(e) =>
                        setNewCase({ ...newCase, plantype: e.target.value })
                      }
                      style={{ width: "100%" }}
                      className="form-control"
                      required
                    >
                      <option value="">Plan Type</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                      {/* <option value="half yearly">Half Yearly</option>
                      <option value="quarterly">Quarterly</option> */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Duration<span className="text-danger">*</span></strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      value={newCase.planduration}
                      onChange={(e) =>
                        setNewCase({
                          ...newCase,
                          planduration: e.target.value,
                        })
                      }
                      placeholder="Plan Duration"
                      style={{ width: "100%" }}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                <strong>Product Details</strong>
              </label>
              <div className="d-flex">
                <select
                  className="form-control mr-2"
                  value={selectedOEM}
                  onChange={(e) => {
                    setSelectedOEM(e.target.value);
                    setSelectedProduct("");
                    setSelectedSubproduct("");
                  }}
                >
                  <option value="">Select OEM</option>
                  {Object.keys(productData).map((oem) => (
                    <option key={oem} value={oem}>
                      {oem}
                    </option>
                  ))}
                </select>
                <select
                  className="form-control mr-2"
                  value={selectedProduct}
                  onChange={(e) => {
                    setSelectedProduct(e.target.value);
                    setSubproductList(
                      productData[selectedOEM][e.target.value] || []
                    );
                    setSelectedSubproduct("");
                  }}
                  required
                >
                  <option value="">Select Product</option>
                  {selectedOEM &&
                    Object.keys(productData[selectedOEM]).map((product) => (
                      <option key={product} value={product}>
                        {product}
                      </option>
                    ))}
                </select>
                <select
                  className="form-control mr-2"
                  value={selectedSubproduct}
                  onChange={(e) => setSelectedSubproduct(e.target.value)}
                  required
                >
                  <option value="">Select Skuname</option>
                  {subproductList.map((subproduct) => (
                    <option key={subproduct} value={subproduct}>
                      {subproduct}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  className="form-control mr-2"
                  placeholder="User Count"
                  value={userCount}
                  onChange={(e) => setUserCount(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddProduct}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="form-group">
              <label>
                <strong>Added Products</strong>
              </label>
              <div className="row mt-4">
                <div className="col-12">
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>OEM Name</th>
                          <th>Product Name</th>
                          <th>SkuName</th>
                          <th>User Count</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, index) => (
                          <tr key={index}>
                            <td>{product.oemName}</td>
                            <td>{product.productName}</td>
                            <td>{product.subproductName}</td>
                            <td>{product.userCount}</td>
                            <td>
                              <span
                                variant="warning"
                                size="sm"
                                style={{
                                  marginLeft: "10px",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleEditProduct(index, product)
                                }
                              >
                                <FaEdit />
                              </span>
                              {"   "}
                              <span
                                variant="danger"
                                size="sm"
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDeleteProduct(index)}
                              >
                                ✘
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseNewCaseModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveNewCase}>
            Register
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Edit Case Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Select Case Type</strong>
                  </label>
                  <div className="col-sm-8">
                    <select
                      value={caseType}
                      onChange={(e) => setCaseType(e.target.value)}
                      style={{ width: "100%" }}
                      className="form-control"
                      required
                    >
                      <option value="">Select Case Type</option>
                      <option value="new_case">New Case</option>
                      <option value="renewal_case">Renewal Case</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={selectedCase?.customer_name || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Email ID</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={selectedCase?.customer_emailid || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Reseller ID</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="reseller_id"
                      value={selectedCase?.reseller_id || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Status</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="status"
                      value={selectedCase?.status || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Additional Information</strong>
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      className="form-control"
                      id="additional_information"
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer ID</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="cust_id"
                      value={selectedCase?.cust_id || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong> Created Date</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="created_date"
                      value={selectedCase?.created_date || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Domain Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="domain_name"
                      value={selectedCase?.domain_name || ""}
                      // readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Type</strong>
                  </label>
                  <div className="col-sm-8">
                    <select
                      value={planType}
                      onChange={(e) => setPlanType(e.target.value)}
                      style={{ width: "100%" }}
                      className="form-control"
                      required
                    >
                      <option value="">Plan Type</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="half yearly">Half Yearly</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Duration</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      value={planDuration}
                      onChange={(e) => setPlanDuration(e.target.value)}
                      placeholder="Plan Duration"
                      style={{ width: "100%" }}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <h5>Earlier Product Details</h5>
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>User Count</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedCase?.prod_details &&
                selectedCase?.prod_details.length > 0 ? (
                  selectedCase?.prod_details.map((prodDetail, index) => (
                    <tr key={index}>
                      <td>{Object.keys(prodDetail)[0]}</td>
                      <td>{Object.values(prodDetail)[0]}</td>
                      <td>
                        <span
                          variant="info"
                          style={{
                            marginLeft: "10px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleEditProductDetails(index, prodDetail)
                          }
                        >
                          <FaEdit />
                        </span>
                        {"  "}
                        <span
                          variant="danger"
                          style={{
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDeleteProductDetails(index)}
                        >
                          ✘
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No product details available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <br></br>
          <div className="form-group">
            <label>Add New Product Details</label>
            <br></br>
            <div className="d-flex">
              <select
                className="form-control mr-2"
                value={newProduct.oemName}
                onChange={(e) =>
                  setNewProduct((prevProduct) => ({
                    ...prevProduct,
                    oemName: e.target.value,
                  }))
                }
              >
                {/* Options for OEM */}
                <option value="">Select OEM</option>
                {Object.keys(productData).map((oem) => (
                  <option key={oem} value={oem}>
                    {oem}
                  </option>
                ))}
              </select>
              <select
                className="form-control mr-2"
                value={newProduct.productName}
                onChange={(e) =>
                  setNewProduct((prevProduct) => ({
                    ...prevProduct,
                    productName: e.target.value,
                  }))
                }
              >
                {/* Options for Product */}
                <option value="">Select Product</option>
                {newProduct.oemName &&
                  Object.keys(productData[newProduct.oemName]).map(
                    (product) => (
                      <option key={product} value={product}>
                        {product}
                      </option>
                    )
                  )}
              </select>
              <select
                className="form-control mr-2"
                value={newProduct.subproductName}
                onChange={(e) =>
                  setNewProduct((prevProduct) => ({
                    ...prevProduct,
                    subproductName: e.target.value,
                  }))
                }
              >
                {/* Options for Subproduct */}
                <option value="">Select SkuName</option>
                {newProduct.productName &&
                  productData[newProduct.oemName][newProduct.productName]?.map(
                    (subproduct) => (
                      <option key={subproduct} value={subproduct}>
                        {subproduct}
                      </option>
                    )
                  )}
              </select>
              <input
                type="number"
                className="form-control mr-2"
                placeholder="User Count"
                value={newProduct.userCount}
                onChange={(e) =>
                  setNewProduct((prevProduct) => ({
                    ...prevProduct,
                    userCount: e.target.value,
                  }))
                }
              />
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddNewProduct}
              >
                Add
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEditedCaseDetails}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showQuotationModal}
        onHide={() => setShowQuotationModal(false)}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Generate Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Partner Discount</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="partner_discount"
                      value={`${partnerDiscount || ""}%`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <p style={{ color: "red" }}>
              Note:- Here "Shiviom Price" means partner discounted price.
            </p>
            <h5>
              <strong>Product Details</strong>
            </h5>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>SKU Name</th>
                    <th>User Count</th>
                    <th>List Price</th>
                    <th>Shiviom Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {productsDetails && productsDetails.length > 0 ? (
                    productsDetails.map((prodDetail, index) => (
                      <tr key={index}>
                        <td>{Object.values(prodDetail)[0]}</td>
                        <td>{Object.values(prodDetail)[1]}</td>
                        <td>{Object.values(prodDetail)[2]}</td>
                        <td>{Object.values(prodDetail)[3]}</td>
                        <td>{Object.values(prodDetail)[4]}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No product details available</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  {(() => {
                    const finalTotal = productsDetails.reduce(
                      (acc, prodDetail) =>
                        acc + parseInt(prodDetail.subtotal || 0, 10),
                      0
                    );
                    const gstAmount = parseInt(finalTotal * 0.18, 10); // 18% GST
                    const totalWithGst = finalTotal + gstAmount;

                    return (
                      <>
                        <tr>
                          <td colSpan="4" style={{ fontWeight: "bold" }}>
                            Final Total
                          </td>
                          <td style={{ fontWeight: "bold" }}>{finalTotal}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" style={{ fontWeight: "bold" }}>
                            GST Amount (18%)
                          </td>
                          <td style={{ fontWeight: "bold" }}>{gstAmount}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" style={{ fontWeight: "bold" }}>
                            Final Total with GST
                          </td>
                          <td style={{ fontWeight: "bold" }}>{totalWithGst}</td>
                        </tr>
                      </>
                    );
                  })()}
                </tfoot>
              </table>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Send Quotation For</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-control"
                      value={selectedQuotationFor}
                      onChange={handleQuotationForChange}
                    >
                      <option value="">Select</option>
                      <option value="Both">Both</option>
                      <option value="Reseller">Own</option>
                      <option value="Customer">Customer</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Row for Terms & Conditions */}
            <div className="row">
              {/* Reseller Terms & Conditions */}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    <strong>Reseller Terms & Conditions</strong>
                  </label>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    resellerTerms
                      .split("\n")
                      .map((term, index) => <p key={index}>{term}</p>)
                  )}
                </div>
              </div>

              {/* Customer Terms & Conditions */}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    <strong>Customer Terms & Conditions</strong>
                  </label>
                  <textarea
                    value={customerTerms}
                    onChange={(e) => setCustomerTerms(e.target.value)}
                    rows="3"
                    className="form-control"
                    placeholder="Enter your terms and conditions for customers here..."
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowQuotationModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleGenerateQuotation}>
            Generate Quotation
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CaseList;
