import React, { Fragment, useState, useEffect } from "react";
import {
  getRenewalDetailsReseller,
  placeOrderRenewalByAdmin,
  getApprovalForLicenceRedcution,
  getLicenceApprovalStatus,
} from "../../utils/api";
import { Modal, Button } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort, FaMinus, FaPlus } from "react-icons/fa";

const ResellerRenewalDetails = () => {
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [resellerRenewalDetails, setResellerRenewalDetails] = useState([]);
  const [resellerInitialRenewalList, setResellerInitialRenewalList] = useState(
    []
  );
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const [licenseCount, setLicenseCount] = useState(null);
  const [planDuration, setPlanDuration] = useState(1); // Default to 1 month if plan is monthly
  const [expiryStatus, setExpiryStatus] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [approvalData, setApprovalData] = useState([]);
  useEffect(() => {
    async function fetchRenewalDetails() {
      try {
        setLoading(true);
        const data = await getRenewalDetailsReseller(
          parsedLoginData.reseller_email
        );
        setResellerRenewalDetails(data);
        setResellerInitialRenewalList(data);
      } catch (error) {
        console.error("Failed to fetch renewal details:", error.message);
      } finally {
        setLoading(false);
      }
    }
    fetchRenewalDetails();
  }, []);
  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = resellerInitialRenewalList.filter((renewal) => {
      if (renewal) {
        return (
          (renewal.customer_name &&
            renewal.customer_name.toLowerCase().includes(value)) ||
          (renewal.customer_domainname &&
            renewal.customer_domainname.toLowerCase().includes(value)) ||
          (renewal.reseller_name &&
            renewal.reseller_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setResellerRenewalDetails(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setResellerInitialRenewalList(resellerInitialRenewalList);
  };
  const handleSort = (column) => {
    let sortedList = [...filteredCustomerList];
    if (sortColumn === column) {
      sortedList.reverse();
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredCustomerList(sortedList);
  };
  const renderActionButtons = (customer, text) => (
    <Fragment>
      <button
        className="btn btn-primary"
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click from triggering
          openRenewalModal(customer, text);
        }}
      >
        View Details
      </button>
    </Fragment>
  );
  const handleLicenseCountChange = (e) => {
    setLicenseCount(e.target.value);
  };

  const handlePlanDurationChange = (e) => {
    setPlanDuration(e.target.value);
  };
  // Subtotal calculation logic
  const calculateSubtotal = () => {
    if (!selectedCustomer) return 0;
    const shiviomPrice = selectedCustomer.shiviom_price;
    if (selectedCustomer.plan_type === "monthly") {
      return licenseCount * shiviomPrice * planDuration;
    } else if (selectedCustomer.plan_type === "yearly") {
      return licenseCount * shiviomPrice * planDuration * 12;
    }
    return 0;
  };

  const calculatetotalwthgst = () => {
    if (!selectedCustomer) return 0;
    const totalwthgst = parseInt(
      calculateSubtotal() + (calculateSubtotal() * 18) / 100
    );
    return totalwthgst;
  };

  const calculateExpiryStatus = (renewalDate) => {
    const currentDate = new Date();
    const daysRemaining = Math.floor(
      (new Date(renewalDate) - currentDate) / (1000 * 60 * 60 * 24)
    );

    if (daysRemaining < 0) {
      return { text: "Expired", color: "red", fontWeight: "bold" };
    } else if (daysRemaining === 1) {
      return { text: "Expiring Tomorrow", color: "orange", fontWeight: "bold" };
    } else if (daysRemaining > 1 && daysRemaining < 7) {
      return {
        text: `Expiring in ${daysRemaining} days`,
        color: "orange",
        fontWeight: "bold",
      };
    } else if (daysRemaining >= 7 && daysRemaining <= 90) {
      return {
        text: `Expiring in ${daysRemaining} days`,
        color: "orange",
        fontWeight: "bold",
      };
    } else {
      return { text: "Active", color: "green", fontWeight: "bold" };
    }
  };
  const openRenewalModal = async (customer, text) => {
    const currentDate = new Date();
    const expiryDate = new Date(customer.renewal_date);
    const startDate =
      expiryDate > currentDate
        ? new Date(expiryDate.setDate(expiryDate.getDate() + 1))
        : currentDate;

    setSelectedCustomer({
      ...customer,
      start_date: startDate.toLocaleDateString(),
    });
    setLicenseCount(customer.usercount);
    setPlanDuration(customer.plan_duration);
    setExpiryStatus(text);
    const response = await getLicenceApprovalStatus(customer.lic_id);
    setApprovalData(response[0]);

    setShowRenewalModal(true);
  };

  const closeRenewalModal = () => {
    setShowRenewalModal(false);
    setExpiryStatus("");
    setPlanDuration("");
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const increaseLicenseCount = () =>
    setLicenseCount(parseInt(licenseCount) + 1);
  const decreaseLicenseCount = () => {
    if (parseInt(licenseCount) > 1) setLicenseCount(parseInt(licenseCount) - 1);
  };
  const handleRenew = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("licid", selectedCustomer.lic_id);
      formData.append("plantype", selectedCustomer.plan_type);
      formData.append("planduration", planDuration);
      formData.append("custid", selectedCustomer.cust_id);
      formData.append("skuname", selectedCustomer.sku_name);
      formData.append("licencecount", licenseCount);
      formData.append("listprice", selectedCustomer.price);
      formData.append("shiviomprice", selectedCustomer.shiviom_price);
      formData.append("subtotal", calculateSubtotal());
      formData.append("totalwthgst", calculatetotalwthgst());
      const response = await placeOrderRenewalByAdmin(formData);
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        setShowRenewalModal(false);
        const renewaldata = await getRenewalDetailsReseller(
          parsedLoginData.reseller_email
        );
        setResellerRenewalDetails(renewaldata);
        setResellerInitialRenewalList(renewaldata);
      } else {
        throw new Error("Failed to place order for renewal");
      }
    } catch (error) {
      console.error("Error placing order for renewal:", error.message);
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  const handleSendRequest = async (initialLicenseCount, newLicenseCount) => {
    try {
      setLoading(true);
      console.log("Initial License Count:", initialLicenseCount);
      console.log("Reduction in licenses:", newLicenseCount);

      // Logic to handle the license count reduction
      const reduction = initialLicenseCount - newLicenseCount;

      console.log("New License Count:", reduction);
      const formData = new FormData();
      formData.append("licid", selectedCustomer.lic_id);
      formData.append("reseller_email", parsedLoginData.reseller_email);
      formData.append("initiallicencecnt", initialLicenseCount);
      formData.append("newlicencecount", reduction);
      formData.append("custid", selectedCustomer.cust_id);
      formData.append("customerdomain", selectedCustomer.customer_domainname);
      formData.append("customername", selectedCustomer.customer_name);
      formData.append("resellername", selectedCustomer.reseller_name);
      formData.append("plantype", selectedCustomer.plan_type);
      formData.append("planduration", selectedCustomer.plan_duration);

      const response = await getApprovalForLicenceRedcution(formData);
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        setShowRenewalModal(false);
        const renewaldata = await getRenewalDetailsReseller(
          parsedLoginData.reseller_email
        );
        setResellerRenewalDetails(renewaldata);
        setResellerInitialRenewalList(renewaldata);
      } else {
        throw new Error("Failed to send request for licence reduction");
      }
    } catch (error) {
      console.error(
        "Error to send request for licence reduction:",
        error.message
      );
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  return (
    <Fragment>
      <style>
        {`
      .sortable-header {
        cursor: pointer;
        position: relative;
      }

      .sort-icon {
        margin-left: 5px;
        color: #6c757d;
        transition: color 0.2s ease;
      }

      .sortable-header:hover .sort-icon {
        color: #007bff;
      }

      .table-responsive {
        max-height: 500px;
        overflow-y: auto;
      }
    `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Renewal Details</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left", width: "250px" }} // Specify width here
                className="form-control"
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
            </div>
            <br />
            <div className="table-responsive">
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                      style={thStyles}
                    >
                      Customer Domain Name
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                      style={thStyles}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>SKU Name</th>
                    <th style={thStyles}>Expiry Date</th>
                    <th style={thStyles}>Licence Count</th>
                    <th style={thStyles}>Plan Type</th>
                    <th style={thStyles}>Plan Duration</th>
                    {/* <th style={thStyles}>List Price</th> */}
                    {/* <th style={thStyles}>Shiviom Price</th> */}
                    <th style={thStyles}>Expiry Status</th>
                    <th style={thStyles}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {resellerRenewalDetails.map((renewal, index) => {
                    const { text, color, fontWeight } = calculateExpiryStatus(
                      renewal.renewal_date
                    );
                    const uniqueKey = `${renewal.cust_id}-${renewal.customer_domainname}-${index}`; // Add index to make the key unique
                    return (
                      <tr
                        key={uniqueKey}
                        // onClick={() => handleViewDetails(renewal)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{renewal.customer_domainname}</td>
                        <td>{renewal.customer_name}</td>
                        <td>{renewal.sku_name}</td>
                        <td>
                          {new Date(renewal.renewal_date).toLocaleDateString()}
                        </td>
                        <td>{renewal.usercount}</td>
                        <td>{renewal.plan_type}</td>
                        <td>{renewal.plan_duration}</td>
                        <td style={{ color: color, fontWeight: fontWeight }}>
                          {text}
                        </td>
                        <td>{renderActionButtons(renewal, text)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showRenewalModal}
        onHide={closeRenewalModal}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Domain Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add renewal form content here */}
          <form className="form-sample">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="customername"
                      value={selectedCustomer?.customer_name || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer Domain Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      className="form-control"
                      id="customeremail"
                      value={selectedCustomer?.customer_domainname || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Type</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="plantype"
                      value={selectedCustomer?.plan_type || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Duration</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      className="form-control"
                      id="planduration"
                      value={planDuration}
                      onChange={handlePlanDurationChange}
                      {...(expiryStatus !== "Expired" && { readOnly: true })} // Conditionally add readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Reseller Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="resellername"
                      value={selectedCustomer?.reseller_name || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Partner Discount</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="partnerdiscount"
                      value={`${selectedCustomer?.partner_discount}%`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Expiry Date</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="resellername"
                      value={new Date(
                        selectedCustomer?.renewal_date || ""
                      ).toLocaleDateString()}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <h5>
              <strong>Product Details</strong>
            </h5>
            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                border: "2px solid #000",
              }}
            >
              <table className="table">
                <thead>
                  <tr
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 100,
                      backgroundColor: "#dee2e6",
                    }}
                  >
                    <th>SKU Name</th>
                    <th>Licence Count</th>
                    <th>List Price</th>
                    <th>Shiviom Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody style={{ paddingTop: "30px" }}>
                  <tr>
                    <td>{selectedCustomer?.sku_name}</td>
                    <td>
                      <Button
                        variant="outline-danger" // Use outline style for a cleaner look
                        onClick={decreaseLicenseCount}
                        title="Reduce License Count" // Provide a clear title
                        aria-label="Reduce License Count" // Accessibility label
                      >
                        <FaMinus /> {/* Replace text with an icon */}
                      </Button>{" "}
                      <input
                        type="text"
                        className="form-control form-control-sm text-center"
                        value={licenseCount || ""}
                        onChange={handleLicenseCountChange}
                        style={{ width: "80px" }} // Adjusted width
                        {...(expiryStatus !== "Expired" && { readOnly: true })} // Conditionally add readOnly
                      />{" "}
                      <Button
                        variant="outline-success" // Use outline style for a cleaner look
                        onClick={increaseLicenseCount}
                        title="Add License Count" // Provide a clear title
                        aria-label="Add License Count" // Accessibility label
                      >
                        <FaPlus /> {/* Replace text with an icon */}
                      </Button>
                    </td>
                    <td>{Number(selectedCustomer?.price).toLocaleString()}</td>
                    <td>
                      {Number(selectedCustomer?.shiviom_price).toLocaleString()}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={calculateSubtotal()}
                        readOnly
                        style={{ width: "80px" }} // Adjusted width
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Total</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="total"
                      value={calculateSubtotal()}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Total with GST(18%)</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="total"
                      value={calculatetotalwthgst()}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeRenewalModal}>
            Close
          </Button>
          {expiryStatus === "Expired" &&
            (licenseCount < selectedCustomer.usercount &&
            parseInt(licenseCount, 10) !==
              parseInt(approvalData?.new_requested_count, 10) ? (
              <Button
                variant="danger"
                title="Send Request for reduce license"
                onClick={() =>
                  handleSendRequest(
                    selectedCustomer.usercount,
                    selectedCustomer.usercount - licenseCount
                  )
                }
              >
                Get Approval
              </Button>
            ) : (
              (() => {
                // Debugging the values before the comparison
                console.log("License Count:", licenseCount);
                console.log("Approval Data:", approvalData);
                console.log("Approval Status:", approvalData?.approval_status);
                console.log(
                  "Requested Count:",
                  approvalData?.new_requested_count
                );
                console.log(
                  "Comparison:",
                  parseInt(licenseCount, 10),
                  parseInt(approvalData?.new_requested_count, 10)
                );

                // Check condition
                const isApproved =
                  approvalData?.approval_status === "Accepted" &&
                  parseInt(licenseCount, 10) ===
                    parseInt(approvalData?.new_requested_count, 10);

                return (
                  <Button
                    variant="primary"
                    title="Renew the license"
                    onClick={handleRenew}
                  >
                    {isApproved ? "Renew" : "Renew"}
                  </Button>
                );
              })()
            ))}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ResellerRenewalDetails;
