import React, { Fragment, useState, useEffect } from "react";
import {
  submitFormData,
  get_bank_details,
  getCompanyTypes,
} from "../../utils/api";
import { useCookies } from "react-cookie";
import { Tab, Tabs, Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";

const UserRegistrationForm = () => {
  const [formData, setFormData] = useState({
    ownerfullname: "",
    owneremailid: "",
    secondcontactname: "",
    secondcontactemailid: "",
    accountcontactname: "",
    accountcontactemailid: "",
    udyogadharflag: "",
    udyogadhaarnumber: "",
    msmeflag: "",
    msmeregnumber: "",
    pannumber: "",
    gstnumber: "",
    tannumber: "",
    pr_address: "",
    pr_city: "",
    pr_state: "",
    pr_pincode: "",
    contactnumber: "",
    bankname: "",
    branchname: "",
    accountnumber: "",
    ifsccode: "",
    reseller_email: "",
    gstcertificate: null,
    panCard: null,
    cancelCheque: null,
    gstcertificateURL: "",
    pancardURL: "",
    cancelChequeURL: "",
    paymentmode: "",
    userRegID: "",
    secondarycontactnumber: "",
    accountcontactnumber: "",
    region: "",
    accountholdername: "",
    typeofcompany: "",
    companycategory: "",
    pr_officeaddress: "",
    pr_officecity: "",
    pr_officestate: "",
    pr_officepincode: "",
    isOfficeSameAsRegistered: "",
    turnoverdetailsflag: "",
    turnoverString: "",
  });
  const [updatedFields, setUpdatedFields] = useState({});
  const [cookies] = useCookies(["email"]);
  const email = cookies.email;
  const [ifscError, setIfscError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [secondaryNumberError, setSecondaryNumberError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [panNumberError, setPanNumberError] = useState("");
  const [aadhaarNumberError, setAadhaarNumberError] = useState("");
  const [msmeNumberError, setMsmeNumberError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [bankAccountNumberError, setBankAccountNumberError] = useState("");
  const [tanNumberError, setTanNumberError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1"); // State for active tab
  const [paymentModeOpt, setPaymentModeOpt] = useState("");
  // Retrieve data from local storage
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginDetailsData = JSON.parse(loginDataFromStorage);

  useEffect(() => {
    // Fetch product data when component mounts
    const fetchCompanyTypes = async () => {
      try {
        setLoading(true);
        const complist = await getCompanyTypes(); // Call the API function to get product data
        setCompanyTypeList(complist); // Set the fetched product data in state
      } catch (error) {
        window.alert("Error fetching company types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyTypes(); // Invoke the fetchProductData function
  }, []);
  useEffect(() => {
    document.title = "Registration Page"; // Set the document title

    // Retrieve data from local storage
    const loginDataFromStorage = localStorage.getItem("loginData");
    const loginDataStatusFromStorage = localStorage.getItem("loginDatastatus");

    if (loginDataFromStorage && loginDataStatusFromStorage) {
      try {
        const parsedLoginData = JSON.parse(loginDataFromStorage);
        setFormData((prevFormData) => ({
          ...prevFormData,

          ownerfullname: parsedLoginData.owner_full_name || "",
          owneremailid: parsedLoginData.owner_email || "",
          secondcontactname: parsedLoginData.secondary_contact_name || "",
          secondcontactemailid: parsedLoginData.secondary_contact_email || "",
          accountcontactname: parsedLoginData.account_contact_name || "",
          accountcontactemailid: parsedLoginData.account_contact_email || "",
          udyogadharflag: parsedLoginData.udyog_adhaar || "",
          udyogadhaarnumber: parsedLoginData.udyog_adhaar_number || "",
          msmeflag: parsedLoginData.msmeflag || "",
          msmeregnumber: parsedLoginData.msmeregnumber || "",
          pannumber: parsedLoginData.pan_number || "",
          gstnumber: parsedLoginData.gst_number || "",
          tannumber: parsedLoginData.tan_number || "",
          pr_address: parsedLoginData.address || "",
          pr_city: parsedLoginData.city || "",
          pr_state: parsedLoginData.state || "",
          pr_pincode: parsedLoginData.pincode || "",
          contactnumber: parsedLoginData.contact_number || "",
          bankname: parsedLoginData.bank_name || "",
          branchname: parsedLoginData.branch_name || "",
          accountnumber: parsedLoginData.acc_number || "",
          ifsccode: parsedLoginData.ifsc_code || "",
          userRegID: parsedLoginData.registration_id || "",
          pancardURL: parsedLoginData.documents[0]?.panCard || "",
          gstcertificateURL: parsedLoginData.documents[1]?.gstcertificate || "",
          cancelChequeURL: parsedLoginData.documents[2]?.cancelCheque || "",
          secondarycontactnumber:
            parsedLoginData.secondary_contact_number || "",
          accountcontactnumber: parsedLoginData.account_contact_number || "",
          region: parsedLoginData.region || "",
          accountholdername: parsedLoginData.account_holder_name || "",
          typeofcompany: parsedLoginData.type_of_company || "",
          paymentmode: parsedLoginData.paymentmode,
          companycategory: parsedLoginData.company_category,
          pr_officeaddress: parsedLoginData.office_address,
          pr_officecity: parsedLoginData.office_city,
          pr_officestate: parsedLoginData.office_state,
          pr_officepincode: parsedLoginData.office_pincode,
          isOfficeSameAsRegistered: parsedLoginData.is_officeaddflag,
          turnoverdetailsflag: parsedLoginData.turnoverdetailsflag,
          turnoverString: parsedLoginData.turnoverdetails,
        }));
        // Set bankDetails based on bank_name and branch_name
        setBankDetails({
          bankname: parsedLoginData.bank_name || "", // Use bank_name from parsedLoginData if available
          branchname: parsedLoginData.branch_name || "", // Use branch_name from parsedLoginData if available
        });
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error);
      }
    }
  }, []);
  const validateEmail = (email) => {
    // Email regex pattern for basic validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateContactNumber = (contactNumber) => {
    // Contact number regex pattern for basic validation
    const contactNumberPattern = /^[0-9]{10}$/;
    return contactNumberPattern.test(contactNumber);
  };

  const validatePANNumber = (panNumber) => {
    // PAN card number regex pattern for basic validation
    const panNumberPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    return panNumberPattern.test(panNumber);
  };

  const validateAadhaarNumber = (aadhaarNumber) => {
    // Aadhaar card number regex pattern for UUID format validation
    const aadhaarNumberPattern = /^[0-9]{12}$/;
    return aadhaarNumberPattern.test(aadhaarNumber);
  };
  const validateMsmeNumber = (msmeNumber) => {
    // Regex pattern for MSME number format validation
    const msmeNumberPattern = /^UDYAM-[A-Z]{2}-[0-9]{2}-[0-9]{7}$/;
    return msmeNumberPattern.test(msmeNumber);
  };

  const validateGSTNumber = (gstNumber) => {
    // GST number regex pattern for basic validation
    const gstNumberPattern =
      /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/;
    return gstNumberPattern.test(gstNumber);
  };

  const validateBankAccountNumber = (bankAccountNumber) => {
    // Bank account number regex pattern for basic validation
    const bankAccountNumberPattern = /^[0-9]{9,18}$/;
    return bankAccountNumberPattern.test(bankAccountNumber);
  };

  const validateTANNumber = (tanNumber) => {
    // TAN number regex pattern for basic validation
    const tanNumberPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    return tanNumberPattern.test(tanNumber);
  };

  const validateIFSCCode = (ifscCode) => {
    const ifscRegex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
    return ifscRegex.test(ifscCode.toUpperCase());
  };
  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    return pincodeRegex.test(pincode);
  };

  const handleownerEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      owneremailid: emailValue,
    }));
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };
  const handlesecondaryEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      secondcontactemailid: emailValue,
    }));
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };
  const handleaccountEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      accountcontactemailid: emailValue,
    }));
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };

  const handleContactNumberChange = (e) => {
    const contactNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      contactnumber: contactNumberValue,
    }));
    setContactNumberError(
      validateContactNumber(contactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };

  const handleSecondaryContactNumberChange = (e) => {
    const secondaryContactNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      secondarycontactnumber: secondaryContactNumberValue,
    }));
    setSecondaryNumberError(
      validateContactNumber(secondaryContactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };

  const handleAccountContactNumberChange = (e) => {
    const accountContactNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      accountcontactnumber: accountContactNumberValue,
    }));
    setAccountNumberError(
      validateContactNumber(accountContactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };

  const handlePANNumberChange = (e) => {
    const panNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      pannumber: panNumberValue.toUpperCase(),
    }));
    setPanNumberError(
      validatePANNumber(panNumberValue) ? "" : "Invalid PAN card number format"
    );
  };

  const handleAadhaarNumberChange = (e) => {
    const aadhaarNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      udyogadhaarnumber: aadhaarNumberValue,
    }));
    setAadhaarNumberError(
      validateAadhaarNumber(aadhaarNumberValue)
        ? ""
        : "Invalid Aadhaar card number format"
    );
  };
  const handleMsmeNumberChange = (e) => {
    const msmeNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      msmeregnumber: msmeNumberValue,
    }));
    setMsmeNumberError(
      validateMsmeNumber(msmeNumberValue)
        ? ""
        : "Invalid MSME Registration number format"
    );
  };

  const handleGSTNumberChange = (e) => {
    const gstNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstnumber: gstNumberValue.toUpperCase(),
    }));
    setGstNumberError(
      validateGSTNumber(gstNumberValue) ? "" : "Invalid GST number format"
    );
  };

  const handleBankAccountNumberChange = (e) => {
    const bankAccountNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      accountnumber: bankAccountNumberValue,
    }));
    setBankAccountNumberError(
      validateBankAccountNumber(bankAccountNumberValue)
        ? ""
        : "Invalid bank account number format"
    );
  };

  const handleTANNumberChange = (e) => {
    const tanNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      tannumber: tanNumberValue.toUpperCase(),
    }));
    setTanNumberError(
      validateTANNumber(tanNumberValue) ? "" : "Invalid TAN number format"
    );
  };
  const handleIFSCCodeChange = (e) => {
    const ifscCodeValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ifsccode: ifscCodeValue,
    }));

    // IFSC code validation
    const isValidIFSC = validateIFSCCode(ifscCodeValue);
    setIfscError(isValidIFSC ? "" : "Invalid IFSC code format");
  };
  const [bankDetails, setBankDetails] = useState({
    bankname: "",
    branchname: "",
  });
  const handlePaymentModeChange = (e) => {
    const paymentmodevalue = e.target.value;

    setPaymentModeOpt(paymentmodevalue);
    // Make sure formData gets updated correctly
    setFormData((prevFormData) => ({
      ...prevFormData,
      paymentmode: paymentmodevalue,
    }));
  };

  const handlePincodeChange = (e) => {
    const pincodeValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      pr_pincode: pincodeValue,
    }));

    // Pincode validation
    const isValidPincode = validatePincode(pincodeValue);
    setPincodeError(isValidPincode ? "" : "Invalid Pincode format");
  };

  const handleOfficePincodeChange = (e) => {
    const officepincodeValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      pr_officepincode: officepincodeValue,
    }));

    // Pincode validation
    const isValidPincode = validatePincode(officepincodeValue);
    setPincodeError(isValidPincode ? "" : "Invalid Pincode format");
  };

  const verifyIFSCCode = async () => {
    try {
      setLoading(true);
      // If bank_name and branch_name are not present, make the API call
      const responseData = await get_bank_details(formData.ifsccode);
      setBankDetails({
        bankname: responseData.BANK,
        branchname: responseData.BRANCH,
      });
      setIfscError(""); // Clear IFSC error if successful
    } catch (error) {
      console.error("Error verifying IFSC code:", error);
      setIfscError("IFSC code not found or invalid."); // Set IFSC error message
    } finally {
      setLoading(false);
    }
  };
  const handleFileUpload = (e) => {
    const { name, files } = e.target;
    const file = files[0] || null; // Assign null if no file is uploaded

    // Update formData state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
      [`${name}status`]: file ? "" : null, // Set status to empty if file exists, otherwise null
    }));

    // Update updatedFields state
    const updatedFieldName = name.replace("Card", "");
    setUpdatedFields((prevUpdatedFields) => ({
      ...prevUpdatedFields,
      [updatedFieldName]: file,
    }));
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      // setLoading(true);
      const loginDataFromStorage = localStorage.getItem("loginData");
      const parsedLoginData = JSON.parse(loginDataFromStorage);
      const formDataToSend = new FormData(event.target);
      // Check if all required fields are filled
      for (let [key, value] of formDataToSend.entries()) {
        if (key === "turnoverdetailsflag" && value === "no") {
          formDataToSend.set("turnoverString", null); // Set turnoverString to null
        }
        // Apply additional condition for "Non-credit Reseller"
        if (parsedLoginData.credit_option === "Non-credit Reseller") {
          formDataToSend.set("turnoverString", null);
          formDataToSend.set("turnoverdetailsflag", "no");
          formDataToSend.set("cancelCheque", null);
        }
        // You can add more fields here if needed (replace 'fieldname' with actual field names)
        if (!value || value === "") {
          if (key === "turnoverString") {
            continue;
          } else {
            window.alert(`Please fill the required field: ${key}`);
            return;
          }
        }
      }
      formDataToSend.append("reseller_email", email);
      formDataToSend.append("registration_id", parsedLoginData.registration_id);
      formDataToSend.append(
        "credit_option",
        parsedLoginDetailsData.credit_option
      );
      // formDataToSend.append("reseller_email", "amey.gothankar@shivaami.com");
      // formDataToSend.append("registration_id", "12345");
      console.log(formDataToSend);
      const response = await submitFormData(formDataToSend);

      if (response.status === 200) {
        window.alert(
          "User Registration Completed Successfully. It is under review."
        );
        window.close();
        setLoading(false);
        window.location.href = "/userlogin";
      } else {
        setLoading(false);
        const data = await response.json();
        window.alert(data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const openFilePopup = (fileUrl) => {
    // Open a new window to display the file
    window.open(fileUrl, "_blank", "width=800,height=600");
  };
  // Function to handle the "Next" button click
  const handleNextClick = () => {
    if (activeTab === "tab1") {
      setActiveTab("tab2");
    } else if (activeTab === "tab2") {
      setActiveTab("tab3");
    } else if (activeTab === "tab3") {
      setActiveTab("tab4");
    }
    // Add more conditions if you have more tabs
  };
  const handleBackClick = () => {
    if (activeTab === "tab4") {
      setActiveTab("tab3");
    } else if (activeTab === "tab3") {
      setActiveTab("tab2");
    } else if (activeTab === "tab2") {
      setActiveTab("tab1");
    }
    // Add more conditions if you have more tabs
  };
  const handleAddTurnoverRow = () => {
    const turnoverString = formData.turnoverString || "";
    const turnoverRows = turnoverString.split(",").filter(Boolean);

    // Check if any existing rows have incomplete values
    for (const row of turnoverRows) {
      const [startYear, endYear, amount] = row.split("-");
      if (!startYear || !endYear || !amount) {
        alert(
          "Please complete all fields in the existing rows before adding a new one."
        );
        return;
      }
    }

    // Check if the turnover string already has 5 rows
    if (turnoverRows.length >= 5) {
      alert("You can only add up to 5 turnover rows.");
      return;
    }

    // Add a new turnover row to the turnover string
    const newTurnoverRow = "startYear-endYear-amount"; // Placeholder for the new row
    const updatedTurnoverString = formData.turnoverString
      ? `${formData.turnoverString},${newTurnoverRow}`
      : newTurnoverRow;

    // Update formData with the new turnover string
    setFormData((prevFormData) => ({
      ...prevFormData,
      turnoverString: updatedTurnoverString,
    }));
  };

  // Handle updating a turnover value (amount)
  const handleTurnoverChange = (e, index) => {
    const turnoverRows = formData.turnoverString.split(",").filter(Boolean);

    // Update the amount in the specific row
    const updatedRow = turnoverRows[index].split("-");
    updatedRow[2] = e.target.value; // Update the amount (which is at position 2)

    // Rebuild the turnover string with the updated row
    turnoverRows[index] = updatedRow.join("-");
    const updatedTurnoverString = turnoverRows.join(",");

    // Update formData with the new turnover string
    setFormData((prevFormData) => ({
      ...prevFormData,
      turnoverString: updatedTurnoverString,
    }));
  };

  // Handle updating a year (either startYear or endYear) with validation
  const handleTurnoverYearChange = (e, index, field) => {
    const turnoverRows = formData.turnoverString.split(",").filter(Boolean);

    // Update the year in the specific row
    const updatedRow = turnoverRows[index].split("-");
    if (field === "startYear") {
      updatedRow[0] = e.target.value; // Update startYear
    } else if (field === "endYear") {
      updatedRow[1] = e.target.value; // Update endYear
    }

    // Rebuild the turnover string with the updated row
    turnoverRows[index] = updatedRow.join("-");
    const updatedTurnoverString = turnoverRows.join(",");

    // Update formData with the new turnover string
    setFormData((prevFormData) => ({
      ...prevFormData,
      turnoverString: updatedTurnoverString,
    }));
  };

  // Handle deleting a turnover row
  const handleDeleteTurnover = (index) => {
    let turnoverRows = formData.turnoverString.split(",").filter(Boolean);

    // Remove the row at the specified index
    turnoverRows = turnoverRows.filter((_, i) => i !== index);

    // Rebuild the turnover string after deletion
    const updatedTurnoverString = turnoverRows.join(",");

    // Update formData with the new turnover string
    setFormData((prevFormData) => ({
      ...prevFormData,
      turnoverString: updatedTurnoverString,
    }));
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Reseller KYC Registration</h4>
            <br></br>
            <form className="form-sample" onSubmit={handleSubmit}>
              <Tabs
                id="registration-tabs"
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key)}
                transition={false}
                className="mb-3"
              >
                <Tab eventKey="tab1" title="Basic Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Company Category<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <select
                            value={formData.companycategory}
                            onChange={(e) => {
                              const selectedCategory = e.target.value;
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                companycategory: selectedCategory,
                                // Reset contact fields if "Proprietary/Owner" is selected
                                ...(selectedCategory ===
                                  "Proprietary/Owner" && {
                                  secondcontactname: "",
                                  accountcontactname: "",
                                  secondcontactemailid: "",
                                  accountcontactemailid: "",
                                  secondarycontactnumber: "",
                                  accountcontactnumber: "",
                                }),
                              }));
                            }}
                            name="companycategory"
                            className="form-control"
                          >
                            <option value="">Select Category</option>
                            <option value="Proprietary/Owner">
                              Proprietary/Owner
                            </option>
                            <option value="Partnership">Partnership</option>
                            <option value="Pvt. Ltd.">Pvt. Ltd.</option>
                            <option value="Public Ltd.">Public Ltd.</option>
                            <option value="LLP">LLP</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Only render the following if a category is selected */}
                  {formData.companycategory && (
                    <>
                      {/* Owner Details for Proprietary/Owner or any category */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Owner Full Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="ownerfullname"
                                className="form-control"
                                placeholder="Owner Full Name"
                                value={formData.ownerfullname}
                                onChange={(e) =>
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    ownerfullname: e.target.value,
                                  }))
                                }
                              />
                              <small
                                className="text-danger d-block mt-1"
                                style={{ textAlign: "left" }}
                              >
                                Note:- Enter the name as per PAN card document
                              </small>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Owner Email<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className={`form-control ${
                                  formData.owneremailid
                                    ? emailError
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                placeholder="Owner Email"
                                name="owneremailid"
                                value={formData.owneremailid}
                                onChange={handleownerEmailChange}
                              />
                              {emailError && (
                                <small className="text-danger">
                                  {emailError}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Contact Details for non-Proprietary/Owner categories */}
                      {formData.companycategory !== "Proprietary/Owner" && (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-3 col-form-label text-left">
                                  Secondary Contact Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    name="secondcontactname"
                                    className="form-control"
                                    placeholder="Secondary Contact Name"
                                    value={formData.secondcontactname}
                                    onChange={(e) =>
                                      setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        secondcontactname: e.target.value,
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-3 col-form-label text-left">
                                  Account Contact Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    name="accountcontactname"
                                    className="form-control"
                                    placeholder="Account Contact Name"
                                    value={formData.accountcontactname}
                                    onChange={(e) =>
                                      setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        accountcontactname: e.target.value,
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-3 col-form-label text-left">
                                  Secondary Contact Email{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    name="secondcontactemailid"
                                    className={`form-control ${
                                      formData.secondcontactemailid
                                        ? emailError
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                                    placeholder="Secondary Contact Email"
                                    value={formData.secondcontactemailid}
                                    onChange={handlesecondaryEmailChange}
                                  />
                                  {emailError && (
                                    <small className="text-danger">
                                      {emailError}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-3 col-form-label text-left">
                                  Account Contact Email{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    name="accountcontactemailid"
                                    className={`form-control ${
                                      formData.accountcontactemailid
                                        ? emailError
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                                    placeholder="Account Contact Email"
                                    value={formData.accountcontactemailid}
                                    onChange={handleaccountEmailChange}
                                  />
                                  {emailError && (
                                    <small className="text-danger">
                                      {emailError}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Contact Numbers for non-Proprietary/Owner categories */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-3 col-form-label text-left">
                                  Secondary Contact Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    name="secondarycontactnumber"
                                    className={`form-control ${
                                      formData.secondarycontactnumber
                                        ? secondaryNumberError
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                                    placeholder="Secondary Contact Number"
                                    value={formData.secondarycontactnumber}
                                    onChange={
                                      handleSecondaryContactNumberChange
                                    }
                                  />
                                  {secondaryNumberError && (
                                    <small className="text-danger">
                                      {secondaryNumberError}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-sm-3 col-form-label text-left">
                                  Account Contact Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    name="accountcontactnumber"
                                    className={`form-control ${
                                      formData.accountcontactnumber
                                        ? accountNumberError
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                                    placeholder="Account Contact Number"
                                    value={formData.accountcontactnumber}
                                    onChange={handleAccountContactNumberChange}
                                  />
                                  {accountNumberError && (
                                    <small className="text-danger">
                                      {accountNumberError}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Tab>

                <Tab eventKey="tab2" title="Business Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Udyog Adhaar
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            name="udyogadharflag"
                            value="Yes"
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                udyogadharflag: e.target.value,
                              }))
                            }
                          />{" "}
                          Yes{" "}
                          <input
                            type="radio"
                            name="udyogadharflag"
                            value="No"
                            onChange={(e) => {
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                udyogadharflag: e.target.value,
                                udyogadhaarnumber: null, // Set udyogadhaarnumber to null when "No" is selected
                              }));
                            }}
                          />{" "}
                          No
                        </div>
                      </div>
                    </div>

                    {formData.udyogadharflag === "Yes" && (
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Udyog Adhaar Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="udyogadhaarnumber"
                              className={`form-control ${
                                formData.udyogadhaarnumber
                                  ? aadhaarNumberError
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              placeholder="Udyog Adhaar Number"
                              value={formData.udyogadhaarnumber}
                              onChange={handleAadhaarNumberChange}
                              required={formData.udyogadharflag === "Yes"}
                            />
                            {aadhaarNumberError && (
                              <small className="text-danger">
                                {aadhaarNumberError}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {parsedLoginDetailsData.credit_option ===
                    "Credit Reseller" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            MSME
                          </label>
                          <div className="col-sm-2">
                            <input
                              type="radio"
                              name="msmeflag"
                              value="Yes"
                              onChange={(e) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  msmeflag: e.target.value,
                                }))
                              }
                            />{" "}
                            Yes{" "}
                            <input
                              type="radio"
                              name="msmeflag"
                              value="No"
                              onChange={(e) => {
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  msmeflag: e.target.value,
                                  msmeregnumber: null, // Set udyogadhaarnumber to null when "No" is selected
                                }));
                              }}
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>

                      {formData.msmeflag === "Yes" && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              MSME Registration Number
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="msmeregnumber"
                                className={`form-control ${
                                  formData.msmeregnumber
                                    ? msmeNumberError
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                placeholder="MSME Registration Number"
                                value={formData.msmeregnumber}
                                onChange={handleMsmeNumberChange}
                                required={formData.msmeflag === "Yes"}
                              />
                              {msmeNumberError && (
                                <small className="text-danger">
                                  {msmeNumberError}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Pancard Number<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pannumber"
                            className={`form-control ${
                              formData.pannumber
                                ? panNumberError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Pancard Number"
                            value={formData.pannumber}
                            onChange={handlePANNumberChange}
                          />
                          {panNumberError && (
                            <small className="text-danger">
                              {panNumberError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          GST Number
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="gstnumber"
                            className={`form-control ${
                              formData.gstnumber
                                ? gstNumberError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            // style={getBorderStyle(formData.accountContactEmailstatus)}
                            placeholder="GST Number"
                            value={formData.gstnumber}
                            onChange={handleGSTNumberChange}
                          />
                          {gstNumberError && (
                            <small className="text-danger">
                              {gstNumberError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Upload Pan Card<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          {formData.pancardURL ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => openFilePopup(formData.pancardURL)}
                              title="View Document"
                            >
                              View
                            </button>
                          ) : (
                            <input
                              type="file"
                              className="form-control"
                              name="panCard"
                              onChange={handleFileUpload}
                              accept=".pdf,.jpg,.jpeg,.png"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Upload GST Certificate
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          {formData.gstcertificateURL ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                openFilePopup(formData.gstcertificateURL)
                              }
                              title="View Document"
                            >
                              View
                            </button>
                          ) : (
                            <input
                              type="file"
                              className="form-control"
                              name="gstcertificate"
                              onChange={handleFileUpload}
                              accept=".pdf,.jpg,.jpeg,.png"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          TAN Number<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="tannumber"
                            className={`form-control ${
                              formData.tannumber
                                ? tanNumberError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="TAN Number"
                            value={formData.tannumber}
                            onChange={handleTANNumberChange}
                          />
                          {tanNumberError && (
                            <small className="text-danger">
                              {tanNumberError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    {parsedLoginDetailsData.credit_option ===
                      "Credit Reseller" && (
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Do you want to add Company Turnover Details?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <input
                                type="radio"
                                id="turnoverYes"
                                name="turnoverdetailsflag"
                                value="yes"
                                checked={formData.turnoverdetailsflag === "yes"}
                                onChange={(e) =>
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    turnoverdetailsflag: e.target.value,
                                  }))
                                }
                                className="form-check-input"
                              />{" "}
                              <label
                                className="form-check-label"
                                htmlFor="turnoverYes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                type="radio"
                                id="turnoverNo"
                                name="turnoverdetailsflag"
                                value="no"
                                checked={formData.turnoverdetailsflag === "no"}
                                onChange={(e) =>
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    turnoverdetailsflag: e.target.value,
                                  }))
                                }
                                className="form-check-input"
                              />{" "}
                              <label
                                className="form-check-label"
                                htmlFor="turnoverNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {formData.turnoverdetailsflag === "yes" && (
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          <strong>Company Turnover (INR)</strong>
                        </label>
                        <div className="col-sm-9">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleAddTurnoverRow}
                            disabled={
                              formData.turnoverString
                                ?.split(",")
                                .filter(Boolean).length >= 5
                            } // Check rows
                          >
                            Add Turnover Row
                          </button>
                          {formData.turnoverString?.split(",").filter(Boolean)
                            .length >= 5 && (
                            <small className="text-danger">
                              You can only add up to 5 turnover rows.
                            </small>
                          )}
                        </div>
                      </div>

                      {/* Turnover Table */}
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Sr.No</th>
                            <th>Start Year</th>
                            <th>End Year</th>
                            <th>Turnover Amount (INR)</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.turnoverString
                            ?.split(",")
                            .filter(Boolean)
                            .map((turnover, index) => {
                              const [startYear, endYear, amount] =
                                turnover.split("-");
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={startYear || ""}
                                      onChange={(e) =>
                                        handleTurnoverYearChange(
                                          e,
                                          index,
                                          "startYear"
                                        )
                                      }
                                      placeholder="Start Year"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={endYear || ""}
                                      onChange={(e) =>
                                        handleTurnoverYearChange(
                                          e,
                                          index,
                                          "endYear"
                                        )
                                      }
                                      placeholder="End Year"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={amount || ""}
                                      onChange={(e) =>
                                        handleTurnoverChange(e, index)
                                      }
                                      placeholder="Turnover Amount"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleDeleteTurnover(index)
                                      }
                                    >
                                      ✘
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <input
                    type="hidden"
                    name="turnoverString"
                    value={formData.turnoverString}
                  />
                  <br></br>
                </Tab>
                <Tab eventKey="tab3" title="Address Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          className="col-sm-3 col-form-label text-left"
                          style={{
                            fontWeight: "bold", // Makes the text bold
                            fontSize: "18px", // Increases the font size
                            textDecoration: "underline", // Underlines the text
                          }}
                        >
                          Registered Address{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Address <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            className="form-control"
                            rows="2"
                            name="pr_address"
                            value={formData.pr_address}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                pr_address: e.target.value,
                              }))
                            }
                            style={{ resize: "vertical" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Pincode<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pr_pincode"
                            className={`form-control ${
                              formData.pr_pincode
                                ? pincodeError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Pincode"
                            value={formData.pincode}
                            onChange={handlePincodeChange}
                          />
                          {pincodeError && (
                            <small className="text-danger">
                              {pincodeError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          City<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pr_city"
                            className="form-control"
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="City"
                            value={formData.pr_city}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                pr_city: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          State<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pr_state"
                            className="form-control"
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="State"
                            value={formData.pr_state}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                pr_state: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Contact Number<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="contactnumber"
                            className={`form-control ${
                              formData.contactnumber
                                ? contactNumberError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Contact Number"
                            value={formData.contactnumber}
                            onChange={handleContactNumberChange}
                          />
                          {contactNumberError && (
                            <small className="text-danger">
                              {contactNumberError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Company Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <select
                            value={formData.typeofcompany}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                typeofcompany: e.target.value,
                              }))
                            }
                            style={{ width: "100%" }}
                            name="typeofcompany"
                            className="form-control"
                          >
                            <option value="">Select Company Type</option>
                            {companyTypeList.map((company) => (
                              <option key={company} value={company}>
                                {company}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Region<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <select
                            value={formData.region}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                region: e.target.value,
                              }))
                            }
                            name="region"
                            className="form-control"
                          >
                            <option value="">Select Region</option>
                            <option value="East">East</option>
                            <option value="West">West</option>
                            <option value="North">North</option>
                            <option value="South">South</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Is Office/Delivery Address same as Registered Address?{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-3">
                          <div className="form-check">
                            <input
                              type="radio"
                              id="officeYes"
                              name="isOfficeSameAsRegistered"
                              value="yes"
                              checked={
                                formData.isOfficeSameAsRegistered === "yes"
                              }
                              onChange={(e) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  isOfficeSameAsRegistered: e.target.value,
                                }))
                              }
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="officeYes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              id="officeNo"
                              name="isOfficeSameAsRegistered"
                              value="no"
                              checked={
                                formData.isOfficeSameAsRegistered === "no"
                              }
                              onChange={(e) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  isOfficeSameAsRegistered: e.target.value,
                                }))
                              }
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="officeNo"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          className="col-sm-3 col-form-label text-left"
                          style={{
                            fontWeight: "bold", // Makes the text bold
                            fontSize: "18px", // Increases the font size
                            textDecoration: "underline", // Underlines the text
                          }}
                        >
                          Office/Delivery Address{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Office Address Fields (Conditional Rendering) */}
                  {formData.isOfficeSameAsRegistered === "no" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office Address
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              className="form-control"
                              rows="2"
                              name="pr_officeaddress"
                              value={formData.pr_officeaddress}
                              onChange={(e) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  pr_officeaddress: e.target.value,
                                }))
                              }
                              style={{ resize: "vertical" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office Pincode<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="pr_officepincode"
                              className={`form-control ${
                                formData.pr_officepincode
                                  ? pincodeError
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              placeholder="Office Pincode"
                              value={formData.pr_officepincode}
                              onChange={handleOfficePincodeChange}
                            />
                            {pincodeError && (
                              <small className="text-danger">
                                {pincodeError}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office City<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="pr_officecity"
                              className="form-control"
                              placeholder="City"
                              value={formData.pr_officecity}
                              onChange={(e) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  pr_officecity: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office State<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="pr_officestate"
                              className="form-control"
                              placeholder="State"
                              value={formData.pr_officestate}
                              onChange={(e) =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  pr_officestate: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="tab4" title="Bank Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label text-left">
                          IFSC Code<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6 d-flex align-items-center">
                          <input
                            type="text"
                            name="ifsccode"
                            className={`form-control ${
                              formData.ifsccode
                                ? ifscError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            placeholder="IFSC Code"
                            value={formData.ifsccode}
                            onChange={handleIFSCCodeChange}
                          />
                          <button
                            className="btn btn-primary ml-2"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default form submission
                              verifyIFSCCode(); // Call the verifyIFSCCode function
                            }}
                          >
                            Verify
                          </button>
                        </div>
                        {ifscError && (
                          <div className="col-sm-2">
                            <small className="text-danger">{ifscError}</small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Bank Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="bankname"
                            className="form-control"
                            placeholder="Bank Name"
                            value={bankDetails.bankname}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Branch Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="branchname"
                            className="form-control"
                            placeholder="Branch Name"
                            value={bankDetails.branchname}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Bank Account Number
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="accountnumber"
                            className={`form-control ${
                              formData.accountnumber
                                ? bankAccountNumberError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Bank Account Number"
                            value={formData.accountnumber}
                            onChange={handleBankAccountNumberChange}
                          />
                          {bankAccountNumberError && (
                            <small className="text-danger">
                              {bankAccountNumberError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Account Holder Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="accountholdername"
                            className="form-control"
                            placeholder="Account Holder Name"
                            value={formData.accountholdername}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                accountholdername: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Payment Mode<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 d-flex align-items-center">
                          <label className="mr-3">
                            <input
                              type="radio"
                              value="Cheque"
                              name="paymentmode"
                              checked={formData.paymentmode === "Cheque"}
                              onChange={handlePaymentModeChange}
                            />{" "}
                            Cheque
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="RTGS/NEFT/IMPS/OtherOnlineMode"
                              name="paymentmode"
                              checked={
                                formData.paymentmode ===
                                "RTGS/NEFT/IMPS/OtherOnlineMode"
                              }
                              onChange={handlePaymentModeChange}
                            />{" "}
                            RTGS/NEFT/IMPS/Other Online Mode
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {paymentModeOpt === "Cheque" &&
                    parsedLoginDetailsData.credit_option ===
                      "Credit Reseller" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Upload Canceled Cheque
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              {formData.cancelChequeURL ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() =>
                                    openFilePopup(formData.cancelChequeURL)
                                  }
                                  title="View Document"
                                >
                                  View
                                </button>
                              ) : (
                                <input
                                  type="file"
                                  className="form-control"
                                  name="cancelCheque"
                                  onChange={handleFileUpload}
                                  accept=".pdf,.jpg,.jpeg,.png"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </Tab>
              </Tabs>
              {activeTab !== "tab1" && activeTab !== "tab4" && (
                <Button
                  variant="secondary"
                  onClick={handleBackClick}
                  className="mr-2"
                >
                  Back
                </Button>
              )}
              {activeTab !== "tab4" && (
                <Button variant="primary" onClick={handleNextClick}>
                  Next
                </Button>
              )}

              {/* Submit Button (on the last tab) */}
              {activeTab === "tab4" && (
                <div className="form-group d-flex justify-content-center">
                  <button
                    className="btn btn-secondary mr-3" // Add margin to the right of the "Back" button
                    type="button"
                    onClick={handleBackClick}
                  >
                    Back
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UserRegistrationForm;
