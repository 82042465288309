import React, { Fragment, useState, useEffect } from "react";
// import { verifyKYCDetails } from "../../utils/api";
import { Tab, Tabs, Button } from "react-bootstrap";

const UserRegistrationkysviewdetailsmodal = () => {
  const [formData, setFormData] = useState({
    ownerfullname: "",
    owneremailid: "",
    secondcontactname: "",
    secondcontactemailid: "",
    accountcontactname: "",
    accountcontactemailid: "",
    udyogadharflag: "",
    udyogadhaarnumber: "",
    pannumber: "",
    gstnumber: "",
    tannumber: "",
    pr_address: "",
    pr_city: "",
    pr_state: "",
    pr_pincode: "",
    contactnumber: "",
    bankname: "",
    branchname: "",
    accountnumber: "",
    ifsccode: "",
    reseller_email: "",
    gstcertificate: null,
    panCard: null,
    cancelCheque: null,
    gstcertificateURL: "",
    pancardURL: "",
    cancelChequeURL: "",
    userRegID: "",
    secondarycontactnumber: "",
    accountcontactnumber: "",
    region: "",
    accountholdername: "",
    typeofcompany: "",
    companycategory: "",
    pr_officeaddress: "",
    pr_officecity: "",
    pr_officestate: "",
    pr_officepincode: "",
    isOfficeSameAsRegistered: "",
    turnoverdetailsflag: "",
    turnoverString: "",
    paymentmode: "",
    msmeflag: "",
    msmeregnumber: "",
  });
  const [fieldStatus, setFieldStatus] = useState({
    pannumber: null,
    panCard: null,
    gstnumber: null,
    gstcertificate: null,
    tannumber: null,
  });
  const [updatedFields, setUpdatedFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1"); // State for active tab
  const kycDataStorage = localStorage.getItem("kycdetails");
  const parsedLoginData = JSON.parse(kycDataStorage);
  useEffect(() => {
    // document.title = "Registration Page"; // Set the document title

    // Retrieve data from local storage
    // const loginDataFromStorage = localStorage.getItem("loginData");
    // const loginDataStatusFromStorage = localStorage.getItem("loginDatastatus");
    const kycDataStorage = localStorage.getItem("kycdetails");

    if (kycDataStorage) {
      try {
        const parsedLoginData = JSON.parse(kycDataStorage);
        console.log("yedi por", parsedLoginData);
        // console.log("pancard", parsedLoginData.documents);
        setFormData((prevFormData) => ({
          ...prevFormData,

          ownerfullname: parsedLoginData.owner_full_name || "",
          owneremailid: parsedLoginData.owner_email || "",
          secondcontactname: parsedLoginData.secondary_contact_name || "",
          secondcontactemailid: parsedLoginData.secondary_contact_email || "",
          accountcontactname: parsedLoginData.account_contact_name || "",
          accountcontactemailid: parsedLoginData.account_contact_email || "",
          udyogadharflag: parsedLoginData.udyog_adhaar || "",
          udyogadhaarnumber: parsedLoginData.udyog_adhaar_number || "",
          pannumber: parsedLoginData.pan_number || "",
          gstnumber: parsedLoginData.gst_number || "",
          tannumber: parsedLoginData.tan_number || "",
          pr_address: parsedLoginData.address || "",
          pr_city: parsedLoginData.city || "",
          pr_state: parsedLoginData.state || "",
          pr_pincode: parsedLoginData.pincode || "",
          contactnumber: parsedLoginData.contact_number || "",
          bankname: parsedLoginData.bank_name || "",
          branchname: parsedLoginData.branch_name || "",
          accountnumber: parsedLoginData.acc_number || "",
          ifsccode: parsedLoginData.ifsc_code || "",
          userRegID: parsedLoginData.registration_id || "",
          pancardURL: parsedLoginData.documents[0].panCard || "",
          gstcertificateURL: parsedLoginData.documents[1].gstcertificate || "",
          cancelChequeURL: parsedLoginData.documents[2]?.cancelCheque || null,
          secondarycontactnumber:
            parsedLoginData.secondary_contact_number || "",
          accountcontactnumber: parsedLoginData.account_contact_number || "",
          region: parsedLoginData.region || "",
          accountholdername: parsedLoginData.account_holder_name || "",
          typeofcompany: parsedLoginData.type_of_company || "",
          paymentmode: parsedLoginData.paymentmode || "",
          companycategory: parsedLoginData.companycategory || "",
          pr_officeaddress: parsedLoginData.office_address || "",
          pr_officecity: parsedLoginData.office_city || "",
          pr_officestate: parsedLoginData.office_state || "",
          pr_officepincode: parsedLoginData.office_pincode || "",
          isOfficeSameAsRegistered:
            parsedLoginData.isOfficeSameAsRegistered || "",
          turnoverdetailsflag: parsedLoginData.turonoverdetailsflag || "",
          turnoverString: parsedLoginData.turnoverString || "",
          msmeflag: parsedLoginData.msmeflag || "",
          msmeregnumber: parsedLoginData.msmeregnumber || "",
        }));
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error);
      }
    }
  }, []);

  const handleFileUpload = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
      [`${name}status`]: "", // Set the corresponding status to empty when file is uploaded
    }));

    // Update updatedFields with the new file data
    const updatedFieldName = name.replace("Card", "");
    setUpdatedFields((prevUpdatedFields) => ({
      ...prevUpdatedFields,
      [updatedFieldName]: file,
    }));
  };
  const handleFieldAction = (field, action) => {
    setFieldStatus((prevStatus) => ({
      ...prevStatus,
      [field]: action,
    }));
  };

  const openFilePopup = (fileUrl) => {
    console.log(fileUrl);
    // Open a new window to display the file
    window.open(fileUrl, "_blank", "width=800,height=600");
  };
  // Function to handle the "Next" button click
  const handleNextClick = () => {
    if (activeTab === "tab1") {
      setActiveTab("tab2");
    } else if (activeTab === "tab2") {
      setActiveTab("tab3");
    } else if (activeTab === "tab3") {
      setActiveTab("tab4");
    }
    // Add more conditions if you have more tabs
  };
  const handleBackClick = () => {
    if (activeTab === "tab4") {
      setActiveTab("tab3");
    } else if (activeTab === "tab3") {
      setActiveTab("tab2");
    } else if (activeTab === "tab2") {
      setActiveTab("tab1");
    }
    // Add more conditions if you have more tabs
  };
  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            {/* <h4 className="mb-1">Reseller KYC Regiatration</h4> */}
            <br></br>
            <form className="form-sample">
              <Tabs
                id="registration-tabs"
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key)}
                transition={false}
                className="mb-3"
              >
                <Tab eventKey="tab1" title="Basic Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Company Category{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="company_category"
                            className="form-control"
                            placeholder="Company Category"
                            value={formData.companycategory}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Owner Full Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="ownerfullname"
                            className="form-control"
                            // style={getBorderStyle(formData.ownerNamestatus)}
                            placeholder="Owner Full Name"
                            value={formData.ownerfullname}
                            readOnly
                          />
                          {/* <small className="text-danger">
                        Note:-Enter the name as per PAN card document
                      </small> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Owner Email<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            // style={getBorderStyle(formData.ownerEmailstatus)}
                            placeholder="Owner Email"
                            name="owneremailid"
                            value={formData.owneremailid}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {formData.companycategory !== "Proprietary/Owner" && (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Secondary Contact Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="secondcontactname"
                                // style={getBorderStyle(formData.contactNamestatus)}
                                placeholder="Secondary Contact Name"
                                value={formData.secondcontactname}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Account Contact Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="accountcontactname"
                                className="form-control"
                                // style={getBorderStyle(formData.accountContactNamestatus)}
                                placeholder="Account Contact Name"
                                value={formData.accountcontactname}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Secondary Contact Email
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="secondcontactemailid"
                                className="form-control"
                                // style={getBorderStyle(formData.contactEmailstatus)}
                                placeholder="Secondary Contact Email"
                                value={formData.secondcontactemailid}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Account Contact Email
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="accountcontactemailid"
                                className="form-control"
                                // style={getBorderStyle(formData.accountContactEmailstatus)}
                                placeholder="Account Contact Email"
                                value={formData.accountcontactemailid}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Secondary Contact Number
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="secondarycontactnumber"
                                className="form-control"
                                // style={getBorderStyle(formData.accountContactNamestatus)}
                                placeholder="Secondary Contact Number"
                                value={formData.secondarycontactnumber}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              Account Contact Number
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="accountcontactnumber"
                                className="form-control"
                                // style={getBorderStyle(formData.accountContactEmailstatus)}
                                placeholder="Account Contact Number"
                                value={formData.accountcontactnumber}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab>
                <Tab eventKey="tab2" title="Business Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Udyog Adhaar
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            name="udyogadharflag"
                            value="Yes"
                            checked={formData.udyogadharflag === "Yes"}
                          />{" "}
                          Yes{" "}
                          <input
                            type="radio"
                            name="udyogadharflag"
                            value="No"
                            checked={formData.udyogadharflag === "No"}
                          />{" "}
                          No
                        </div>
                      </div>
                    </div>

                    {formData.udyogadharflag === "Yes" && (
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Udyog Adhaar Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="udyogadhaarnumber"
                              className="form-control "
                              placeholder="Udyog Adhaar Number"
                              value={formData.udyogadhaarnumber}
                              required={formData.udyogadharflag === "Yes"}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {parsedLoginData.credit_option !== "Non-Credit Reseller" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            MSME
                          </label>
                          <div className="col-sm-2">
                            <input
                              type="radio"
                              name="msmeflag"
                              value="Yes"
                              checked={formData.msmeflag === "Yes"}
                            />{" "}
                            Yes{" "}
                            <input
                              type="radio"
                              name="msmeflag"
                              value="No"
                              checked={formData.msmeflag === "No"}
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>

                      {formData.msmeflag === "Yes" && (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-left">
                              MSME Registration Number
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                name="msmeregnumber"
                                placeholder="MSME Registration Number"
                                className="form-control"
                                value={formData.msmeregnumber}
                                required={formData.msmeflag === "Yes"}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Pancard Number<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pannumber"
                            className="form-control"
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Pancard Number"
                            value={formData.pannumber}
                            readOnly
                          />
                          <br></br>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          GST Number
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="gstnumber"
                            className="form-control "
                            // style={getBorderStyle(formData.accountContactEmailstatus)}
                            placeholder="GST Number"
                            value={formData.gstnumber}
                            readOnly
                          />
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Uploaded Pan Card
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          {formData.pancardURL ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => openFilePopup(formData.pancardURL)}
                              title="View Document"
                            >
                              View
                            </button>
                          ) : (
                            <input
                              type="file"
                              className="form-control"
                              name="panCard"
                              onChange={handleFileUpload}
                              accept=".pdf,.jpg,.jpeg,.png"
                              required
                            />
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Uploaded GST Certificate
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          {formData.gstcertificateURL ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() =>
                                openFilePopup(formData.gstcertificateURL)
                              }
                              title="View Document"
                            >
                              View
                            </button>
                          ) : (
                            <input
                              type="file"
                              className="form-control"
                              name="gstcertificate"
                              onChange={handleFileUpload}
                              accept=".pdf,.jpg,.jpeg,.png"
                              required
                            />
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          TAN Number<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="tannumber"
                            className="form-control"
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="TAN Number"
                            value={formData.tannumber}
                            readOnly
                          />
                          <br></br>
                        </div>
                      </div>
                    </div>
                    {parsedLoginData.credit_option !==
                      "Non-Credit Reseller" && (
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Do you want to add Company Turnover Details?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-3">
                            <div className="form-check">
                              <input
                                type="radio"
                                name="turnoverdetailsflag"
                                value="yes"
                                checked={formData.turnoverdetailsflag === "yes"}
                              />{" "}
                              Yes
                            </div>
                            <div className="form-check">
                              <input
                                type="radio"
                                name="turnoverdetailsflag"
                                value="no"
                                checked={formData.turnoverdetailsflag === "no"}
                              />{" "}
                              No
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {formData.turnoverdetailsflag === "yes" && (
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          <strong>Company Turnover (INR)</strong>
                        </label>
                        <div className="col-sm-9">
                          {formData.turnoverString.split(",").filter(Boolean)
                            .length >= 5 && (
                            <small className="text-danger">
                              You can only add up to 5 turnover rows.
                            </small>
                          )}
                        </div>
                      </div>

                      {/* Turnover Table */}
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Sr.No</th>
                            <th>Start Year</th>
                            <th>End Year</th>
                            <th>Turnover Amount (INR)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.turnoverString
                            .split(",")
                            .filter(Boolean)
                            .map((turnover, index) => {
                              const [startYear, endYear, amount] =
                                turnover.split("-");
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={startYear || ""}
                                      placeholder="Start Year"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={endYear || ""}
                                      placeholder="End Year"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={amount || ""}
                                      placeholder="Turnover Amount"
                                      required
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <input
                    type="hidden"
                    name="turnoverString"
                    value={formData.turnoverString}
                  />

                  <br></br>
                </Tab>
                <Tab eventKey="tab3" title="Address Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          className="col-sm-4 col-form-label text-left"
                          style={{
                            fontWeight: "bold", // Makes the text bold
                            fontSize: "18px", // Increases the font size
                            textDecoration: "underline", // Underlines the text
                          }}
                        >
                          Registered Address{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Address <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            className="form-control"
                            rows="2"
                            name="pr_address"
                            value={formData.pr_address}
                            readOnly
                            style={{ resize: "vertical" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Pincode<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pr_pincode"
                            className="form-control "
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Pincode"
                            value={formData.pr_pincode}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          City<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pr_city"
                            className="form-control"
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="City"
                            value={formData.pr_city}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          State<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="pr_state"
                            className="form-control"
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="State"
                            value={formData.pr_state}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Contact Number<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="contactnumber"
                            className="form-control "
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Contact Number"
                            value={formData.contactnumber}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Company Type<span className="text-danger">*</span>
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="typeofcompany"
                            className="form-control "
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Company Type"
                            value={formData.typeofcompany}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Region<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="region"
                            className="form-control "
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Rgion"
                            value={formData.region}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Is Office/Delivery Address same as Registered Address?{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-3">
                          <div className="form-check">
                            <input
                              type="radio"
                              name="isOfficeSameAsRegistered"
                              value="yes"
                              checked={
                                formData.isOfficeSameAsRegistered === "yes"
                              }
                            />{" "}
                            Yes
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              name="isOfficeSameAsRegistered"
                              value="no"
                              checked={
                                formData.isOfficeSameAsRegistered === "no"
                              }
                            />{" "}
                            No
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          className="col-sm-3 col-form-label text-left"
                          style={{
                            fontWeight: "bold", // Makes the text bold
                            fontSize: "18px", // Increases the font size
                            textDecoration: "underline", // Underlines the text
                          }}
                        >
                          Office/Delivery Address{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* Office Address Fields (Conditional Rendering) */}
                  {formData.isOfficeSameAsRegistered === "no" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office Address
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              className="form-control"
                              rows="2"
                              name="pr_officeaddress"
                              value={formData.pr_officeaddress}
                              style={{ resize: "vertical" }}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office Pincode<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="pr_officepincode"
                              className="form-control"
                              placeholder="Office Pincode"
                              value={formData.pr_officepincode}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office City<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="pr_officecity"
                              className="form-control"
                              placeholder="City"
                              value={formData.pr_officecity}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Office State<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="pr_officestate"
                              className="form-control"
                              placeholder="State"
                              value={formData.pr_officestate}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="tab4" title="Bank Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label text-left">
                          IFSC Code<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6 d-flex align-items-center">
                          <input
                            type="text"
                            name="ifsccode"
                            className="form-control "
                            placeholder="IFSC Code"
                            value={formData.ifsccode}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Bank Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="bankname"
                            className="form-control"
                            placeholder="Bank Name"
                            value={formData.bankname}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Branch Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="branchname"
                            className="form-control"
                            placeholder="Branch Name"
                            value={formData.branchname}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Bank Account Number
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="accountnumber"
                            className="form-control "
                            // style={getBorderStyle(formData.accountContactNamestatus)}
                            placeholder="Bank Account Number"
                            value={formData.accountnumber}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Account Holder Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="accountholdername"
                            className="form-control"
                            placeholder="Account Holder Name"
                            value={formData.accountholdername}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    {parsedLoginData.credit_option !==
                      "Non-Credit Reseller" && (
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label text-left">
                            Canceled Cheque
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            {formData.cancelChequeURL ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  openFilePopup(formData.cancelChequeURL)
                                }
                                title="View Document"
                              >
                                View
                              </button>
                            ) : (
                              <input
                                type="file"
                                className="form-control"
                                name="cancelCheque"
                                onChange={handleFileUpload}
                                accept=".pdf,.jpg,.jpeg,.png"
                                required
                              />
                            )}{" "}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-left">
                          Payment Mode<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-9 d-flex align-items-center">
                          <label className="mr-3">
                            <input
                              type="radio"
                              value="Cheque"
                              name="paymentmode"
                              checked={formData.paymentmode === "Cheque"}
                            />{" "}
                            Cheque
                          </label>
                          <label>
                            <input
                              type="radio"
                              value="RTGS/NEFT/IMPS/OtherOnlineMode"
                              name="paymentmode"
                              checked={
                                formData.paymentmode ===
                                "RTGS/NEFT/IMPS/OtherOnlineMode"
                              }
                            />{" "}
                            RTGS/NEFT/IMPS/Other Online Mode
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
              {activeTab !== "tab1" && activeTab !== "tab4" && (
                <Button
                  variant="secondary"
                  onClick={handleBackClick}
                  className="mr-2"
                >
                  Back
                </Button>
              )}
              {activeTab !== "tab4" && (
                <Button variant="primary" onClick={handleNextClick}>
                  Next
                </Button>
              )}

              {/* Submit Button (on the last tab) */}
              {activeTab === "tab4" && (
                <div className="form-group d-flex justify-content-center">
                  <button
                    className="btn btn-secondary mr-3" // Add margin to the right of the "Back" button
                    type="button"
                    onClick={handleBackClick}
                  >
                    Back
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserRegistrationkysviewdetailsmodal;
