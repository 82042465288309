import React, { Fragment, useState, useEffect, useRef } from "react";
import { getKYCApplicantList, getresellerKYCDetails } from "../../utils/api";
import UserRegistrationFormModalverify from "../component/UserRegistrationFormModalverify";
import UserRegistrationFormModalViewDetails from "../component/UserRegistrationFormModalviewdetails";
import { FaSortUp, FaSortDown, FaSort, FaFilter } from "react-icons/fa";
import { Modal, Button, Dropdown, Form } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";

const ApplicantUsers = () => {
  const [registrations, setRegistrations] = useState([]);
  const [error, setError] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showViewRegistrationModal, setShowViewRegistrationModal] =
    useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null); // State to store selected user details

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [statusFilter, setStatusFilter] = useState(""); // For filtering by status
  const [showStatusFilterOptions, setShowStatusFilterOptions] = useState(false); // Toggle for status filter dropdown
  const statusFilterRef = useRef(null); // Ref for the status filter dropdown
  const [loading, setLoading] = useState(false);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const role = JSON.parse(admindetailsmodules).role;
  const admin_email = JSON.parse(admindetailsmodules).email;

  // Fetch initial user list on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getKYCApplicantList(admin_email);
        setRegistrations(data);
        setInitialUserList(data); // Store initial user list for reset functionality
      } catch (error) {
        window.alert(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to handle search input change
  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());

    const filteredList = initialUserList.filter((user) => {
      if (user) {
        return (
          (user.company_name &&
            user.company_name.toLowerCase().includes(value)) ||
          (user.reseller_name &&
            user.reseller_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setRegistrations(filteredList); // Update displayed registrations based on search input
  };
  // Function to handle clicks outside of the status filter dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        statusFilterRef.current &&
        !statusFilterRef.current.contains(event.target)
      ) {
        setShowStatusFilterOptions(false); // Close the dropdown if clicked outside
      }
    };

    // Add event listener when the dropdown is open
    if (showStatusFilterOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showStatusFilterOptions]);
  // Function to reset search and show all registrations
  const handleReset = () => {
    setSearchInput("");
    setStatusFilter(""); // Clear status filter
    setRegistrations(initialUserList); // Reset registrations to initial list
  };

  const handleSort = (column) => {
    let sortedList = [...registrations];

    if (sortColumn === column) {
      // Toggle sorting order if the same column is clicked
      sortedList.reverse();
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Sort by the selected column
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return sortOrder === "asc" ? -1 : 1;
        if (a[column] > b[column]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }

    setRegistrations(sortedList); // Update the displayed list
  };

  // Function to handle button click to view user details
  const handleButtonClick = async (userRegId) => {
    try {
      setLoading(true);
      const response = await getresellerKYCDetails(userRegId);
      const jsonDataString = JSON.stringify(response);
      localStorage.removeItem("kycdetails");
      localStorage.setItem("kycdetails", jsonDataString);

      setSelectedUserDetails(response); // Set the selected user details
      setShowRegistrationModal(true); // Show the registration modal
    } catch (error) {
      window.alert("Error fetching user details:", error);
      setError("Failed to fetch user details. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // Function to handle button click to view user details
  const handleViewButtonClick = async (userRegId) => {
    try {
      setLoading(true);
      const response = await getresellerKYCDetails(userRegId);
      const jsonDataString = JSON.stringify(response);
      localStorage.removeItem("kycdetails");
      localStorage.setItem("kycdetails", jsonDataString);

      setSelectedUserDetails(response); // Set the selected user details
      setShowViewRegistrationModal(true); // Show the registration modal
    } catch (error) {
      window.alert("Error fetching user details:", error);
      setError("Failed to fetch user details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Approved":
        return { color: "green", fontWeight: "bold" };
      case "Pending":
        return { color: "orange", fontWeight: "bold" };
      case "Rejected":
      case "Permanently Reject":
        return { color: "red", fontWeight: "bold" };
      default:
        return {};
    }
  };

  // Handle the filter by status
  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    const filteredList = initialUserList.filter(
      (user) => user.kyc_status === status
    );
    setRegistrations(filteredList);
    setShowStatusFilterOptions(false); // Hide dropdown after selection
  };

  // // Render action buttons for each user
  // const renderActionButtons = (user) => {
  //   return (
  //     <button
  //       className="btn btn-info"
  //       onClick={() => handleButtonClick(user.registration_id)}
  //       title="View the KYC Details"
  //     >
  //       Verify
  //     </button>
  //   );
  // };
  // Render action buttons for each user
  const renderActionButtons = (user) => {
    const buttonLabel =
      user.kyc_status === "Approved" ||
      user.kyc_status === "Rejected" ||
      user.kyc_status === "Permanently Reject"
        ? "Re-Verify"
        : "Verify";

    return (
      <div className="d-flex justify-content-center">
        <Dropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            <BsThreeDotsVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* Conditionally render based on reseller type */}
            {(role === "Admin" || role === "SuperAdmin") && (
              <>
                <Dropdown.Item
                  onClick={() => handleButtonClick(user.registration_id)}
                >
                  {buttonLabel}
                </Dropdown.Item>
              </>
            )}

            <>
              <Dropdown.Item
                onClick={() => handleViewButtonClick(user.registration_id)}
              >
                View KYC Details
              </Dropdown.Item>
            </>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  // Styles for sticky table header
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }

          .status-filter-dropdown {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            padding: 10px;
            z-index: 150;
          }
          .status-filter-dropdown p {
            color: #000; /* Default text color */
            transition: color 0.2s ease-in-out;
          }

          .status-filter-dropdown p:hover {
            color: #007bff; /* Change this to your preferred hover color */
            cursor: pointer; /* Add pointer cursor on hover */
          }
          .status-filter-item {
            padding: 5px;
            cursor: pointer;
            transition: background-color 0.2s ease, color 0.2s ease;
          }


          .filter-icon {
            cursor: pointer;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <UserRegistrationFormModalverify
          show={showRegistrationModal} // Pass the show state to the modal
          onHide={() => setShowRegistrationModal(false)} // Set onHide to close the modal
        />
        <UserRegistrationFormModalViewDetails
          show={showViewRegistrationModal} // Pass the show state to the modal
          onHide={() => setShowViewRegistrationModal(false)} // Set onHide to close the modal
        />
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Reseller KYC Applicant List</h4>
            <br></br>
            {/* Search container */}
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left", width: "250px" }} // Specify width here
                className="form-control"
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <br></br>
            </div>
            {/* Table to display user registrations */}
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("reseller_name")}
                    >
                      Name
                      {sortColumn === "reseller_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles} className="sortable-header">
                      Status
                      <FaFilter
                        className="filter-icon"
                        onClick={() =>
                          setShowStatusFilterOptions(!showStatusFilterOptions)
                        }
                      />
                      {/* Status Filter Dropdown */}
                      {showStatusFilterOptions && (
                        <div
                          ref={statusFilterRef}
                          className="status-filter-dropdown"
                        >
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("Approved")}
                          >
                            Approved
                          </p>
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("Pending")}
                          >
                            Pending
                          </p>
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("Rejected")}
                          >
                            Rejected
                          </p>
                          <p
                            className="status-filter-item"
                            onClick={() =>
                              handleStatusFilter("Permanently Reject")
                            }
                          >
                            Permanently Rejected
                          </p>
                        </div>
                      )}
                    </th>
                    <th style={thStyles}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {registrations.map((user) => (
                    <tr key={user.reseller_id}>
                      <td>{user.reseller_name}</td>
                      <td>{user.company_name}</td>
                      <td>
                        <span style={getStatusStyle(user.kyc_status)}>
                          {user.kyc_status}
                        </span>
                      </td>
                      <td>{renderActionButtons(user)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Show error message if fetching details fails */}
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApplicantUsers;
