import React, { Fragment, useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
// import UserRegistrationForm from "./userregistrationform"; // Assuming correct import path
import RegisteredUsers from "./resgisteredUsers";
import ApplicantUsers from "./applicantUsers";
import AssignProductlist from "./assignproductslist";
import Navbar from "../../userPanel/components/navbar";
import Footer from "../../userPanel/components/footer";
import AdminDashBoard from "./admindashboard";
import ProductMaster from "./productmaster";
import CaseLocking from "./admincaselists";
import LogininvitationManagement from "./logininvitationmanagement";
import DepartmentModuleManagement from "./departmentmodulemanagement";
// import OrderList from "../../userPanel/pages/orderlists";
import AdminInvoiceDetails from "./admininvoicedetails";
import QuotationDetails from "./adminquotationdetails";
import OrderLists from "./adminorderslists";
import AdminLists from "./adminlist";
import ResellersCustomers from "./resellerscustomersdetails";
import DetailModal from "../component/DetailModal";
// import AdminResellersCommisionDetails from "./adminresellerscommisiondetails";
import AddedLicencesDetails from "./addedlicencedetails";
import AdminReportsMaster from "./adminreportsmaster";
import RenewalDetails from "./adminrenewaldetails";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sidebarOptionArrangement } from "./reportModulesjson";
import CallMadeIcon from "@mui/icons-material/CallMade";
import {
  get_search_records_values,
  get_complete_record,
} from "../../utils/api";

const AdminMainComponent = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);

  const [sidebarOptions, setSidebarOptions] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null); // State to track hovered item
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailData, setDetailData] = useState(null);
  // // Disable right-click
  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   document.addEventListener("contextmenu", handleContextMenu);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  // // Disable certain keyboard shortcuts like Print Screen
  // useEffect(() => {
  //   const handleKeyDown = (e) => {
  //     if (e.key === "PrintScreen") {
  //       e.preventDefault();
  //       alert("Screenshots are disabled.");
  //     }

  //     // Prevent CTRL+SHIFT+I or F12 (Dev Tools)
  //     if ((e.ctrlKey && e.shiftKey && e.key === "I") || e.key === "F12") {
  //       e.preventDefault();
  //       alert("Developer tools are disabled.");
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  // Assuming you have logic to determine if the user is logged in
  const isLoggedIn = true; // Example: Set to true if the user is logged in
  const openDetailModal = (data) => {
    setDetailData(data); // Set data to display in modal
    setIsDetailModalOpen(true); // Open the modal
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false); // Close the modal
  };
  useEffect(() => {
    // After the component mounts, set the default selected option based on login status
    setSelectedOption(isLoggedIn ? "Dashboard" : "");
  }, [isLoggedIn]);
  useEffect(() => {
    const admindetailsmodules = localStorage.getItem("admindetails");
    if (admindetailsmodules) {
      const modules = JSON.parse(admindetailsmodules).modules;
      // Filter the desiredOrder to include only those that exist in sidebarOptions
      const sortedOptions = sidebarOptionArrangement.filter((option) =>
        modules.includes(option)
      );
      setSidebarOptions(sortedOptions);
    }
  }, []);
  const handleOptionClick = (option) => {
    console.log(option);
    setSelectedOption(option);
    console.log(option);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial window width
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);
  const adminname = localStorage.getItem("admindetails");
  const admindetails = JSON.parse(adminname);
  const toggleSearchModal = () => {
    setIsSearchModalOpen(!isSearchModalOpen);
    if (!isSearchModalOpen) {
      // Clear search query and suggestions when modal is closed
      setSearchQuery("");
      setSuggestedItems([]);
    }
  };
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await get_search_records_values();
        console.log(response);

        if (response.status === 200) {
          const data = await response.json(); // Extract JSON data from response
          console.log(data.data);
          const { pr_resellersnames, pr_resellercompname } = data.data;
          const suggestions = [
            ...pr_resellersnames,
            ...pr_resellercompname,
          ].filter((name) =>
            name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setSuggestedItems(suggestions);
        } else {
          console.error("Failed to fetch suggestions:", response.error);
          setSuggestedItems([]);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestedItems([]);
      }
    };

    if (isSearchModalOpen && searchQuery.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestedItems([]);
    }
  }, [isSearchModalOpen, searchQuery]);

  const handleSuggestionClick = async (item) => {
    setSearchQuery(item);
    setSuggestedItems([]); // Clear suggestions

    try {
      const response = await get_search_records_values(); // Fetch data again if needed
      if (response.status === 200) {
        const data = await response.json(); // Extract JSON data from response
        const { pr_resellersnames, pr_resellercompname } = data.data;

        // Determine where the item was found
        const isInResellersNames = pr_resellersnames.includes(item);
        const isInResellerCompName = pr_resellercompname.includes(item);
        try {
          if (isInResellersNames) {
            console.log(item, "pr_resellersnames");
            const response = await get_complete_record(
              item,
              "pr_resellersnames"
            );
            console.log(response);
            const complete_data = await response.json();
            console.log(complete_data);
            if (
              complete_data.success &&
              Object.keys(complete_data.data).length === 0
            ) {
              window.alert("Data Not Found");
            } else {
              openDetailModal(complete_data);
            }
          } else if (isInResellerCompName) {
            console.log(item, "pr_resellercompname");
            const response = await get_complete_record(
              item,
              "pr_resellercompname"
            );
            const complete_data = await response.json();
            if (
              complete_data.success &&
              Object.keys(complete_data.data).length === 0
            ) {
              window.alert("Data Not Found");
            } else {
              openDetailModal(complete_data);
            }
          } else {
            console.log(`${item} not found in either list`);
            // Handle case where item is not found in either list
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.error("Failed to fetch suggestions:", response.error);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  // const logout = useCallback(() => {
  //   localStorage.removeItem("userDetails");
  //   localStorage.removeItem("loginData");
  //   localStorage.removeItem("errorMessage");
  //   window.location.href = "/session-timeout";
  // }, []);

  // useEffect(() => {
  //   let timeout;
  //   let alertTimeout;
  //   let alertShown = false;

  //   const showStayAlert = () => {
  //     if (alertShown) return;

  //     alertShown = true;
  //     const stayOnPage = window.confirm(
  //       "You have been inactive for a while. Do you want to stay on this page?"
  //     );

  //     if (stayOnPage) {
  //       resetTimeout();
  //     } else {
  //       logout();
  //     }

  //     alertShown = false;
  //   };

  //   const resetTimeout = () => {
  //     clearTimeout(timeout);
  //     clearTimeout(alertTimeout);
  //     timeout = setTimeout(showStayAlert, 100000); // 5 minutes in milliseconds
  //     alertTimeout = setTimeout(logout, 100000); // 10 minutes in milliseconds
  //   };

  //   const events = [
  //     "load",
  //     "mousemove",
  //     "mousedown",
  //     "click",
  //     "scroll",
  //     "keypress",
  //   ];

  //   events.forEach((event) => window.addEventListener(event, resetTimeout));

  //   resetTimeout();

  //   return () => {
  //     clearTimeout(timeout);
  //     clearTimeout(alertTimeout);
  //     events.forEach((event) =>
  //       window.removeEventListener(event, resetTimeout)
  //     );
  //   };
  // }, [logout]);
  return (
    <Fragment>
      <div
        className={`container-scroller ${isSidebarOpen ? "sidebar-open" : ""}`}
      >
        <Navbar data={admindetails} isAdmin={true} />
        <div className="container-fluid page-body-wrapper">
          <nav
            className={`sidebar ${isSidebarOpen ? "sidebar-offcanvas" : ""}`}
            id="sidebar"
          >
            <ul className="nav">
              <li className="nav-item nav-profile">
                <a href="#" className="nav-link">
                  <div className="profile-image">
                    <img
                      className="img-xs rounded-circle"
                      src="assets/images/faces/face8.jpg"
                      alt="profile image"
                    />
                    <div className="dot-indicator bg-success"></div>
                  </div>
                  <div className="text-wrapper">
                    {/* <p className="profile-name">{parsedLoginData.username}</p>
                    <p className="designation">{parsedLoginData.companyname}</p> */}
                    <p className="profile-name">{admindetails.username}</p>
                    <p className="designation">{admindetails.role}</p>
                  </div>
                </a>
              </li>
              <li className="nav-item nav-category">
                <>
                  Main Menu
                  <br />
                  {(admindetails.role === "Admin" ||
                    admindetails.role === "SuperAdmin" ||
                    admindetails.role === "Assistant Admin") && (
                    <div className="search-icon-container">
                      <span
                        className="search-icon-btn"
                        onClick={toggleSearchModal}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </div>
                  )}
                </>
              </li>

              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() =>
                    handleOptionClick("Login Invitation Management")
                  }
                >
                  Login Invitation Management
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() =>
                    handleOptionClick("Department & Module Management")
                  }
                >
                  Department & Module Manage
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Dashboard")}
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Registered Users")}
                >
                  Registered Resellers
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Applicant Users")}
                >
                  KYC Applicant List
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Assign Products")}
                >
                  Assign Products
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Product Master")}
                >
                  Product Master
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Case Locking")}
                >
                  Case Locking
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Order Details")}
                >
                  Order Details
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Invoice Details")}
                >
                  Invoice Details
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Quotation Details")}
                >
                  Quotation Details
                </Link>
              </li>
              Add more sidebar options as needed */}
              {/* <li className="nav-item nav-category">Main Menu</li> */}
              {sidebarOptions.map((option, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    className="nav-link"
                    to="#"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="main-panel">
            {isMobileView && (
              <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
                {isSidebarOpen ? (
                  // Close icon (three horizontal lines)
                  <span>&#x2630;&#x2630;&#x2630;</span>
                ) : (
                  // Open icon (three horizontal lines)
                  <span>&#x2630;&#x2630;&#x2630;</span>
                )}
              </button>
            )}
            <div className="content-wrapper">
              <div className="row page-title-header">
                <div className="col-12">
                  <div className="page-header">
                    <div className="quick-link-wrapper w-100 d-md-flex flex-md-wrap">
                      <ul className="quick-links">
                        {/* <h4 className="page-title">Shiviom Dashboard</h4> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Conditional rendering based on selected option */}
              {selectedOption === "Admin Lists" && (
                <div className="adminlist-form-wrapper">
                  <AdminLists />
                </div>
              )}
              {selectedOption === "Admin Deligation" && (
                <div className="logininvitation-form-wrapper">
                  <LogininvitationManagement />
                </div>
              )}
              {selectedOption === "Department & Module Manage" && (
                <div className="departmentmodule-form-wrapper">
                  <DepartmentModuleManagement />
                </div>
              )}
              {selectedOption === "Dashboard" && (
                <div className="dashboard-form-wrapper">
                  <AdminDashBoard />
                </div>
              )}
              {selectedOption === "Registered Resellers" && (
                <div className="registered-resellers-form-wrapper">
                  <RegisteredUsers />
                </div>
              )}
              {selectedOption === "KYC Applicant List" && (
                <div className="applicant-users-form-wrapper">
                  <ApplicantUsers />
                </div>
              )}
              {selectedOption === "Assign Products" && (
                <div className="assign-products-form-wrapper">
                  <AssignProductlist />
                </div>
              )}
              {selectedOption === "Product Master" && (
                <div className="product-master-form-wrapper">
                  <ProductMaster />
                </div>
              )}
              {selectedOption === "Case Locking" && (
                <div className="case-locking-form-wrapper">
                  <CaseLocking />
                </div>
              )}
              {selectedOption === "Order Details" && (
                <div className="order-details-form-wrapper">
                  <OrderLists />
                </div>
              )}
              {selectedOption === "Invoice Details" && (
                <div className="invoice-details-form-wrapper">
                  <AdminInvoiceDetails />
                </div>
              )}
              {selectedOption === "Quotation Details" && (
                <div className="quotation-details-form-wrapper">
                  <QuotationDetails />
                </div>
              )}
              {selectedOption === "Resellers Customers" && (
                <div className="resellers-customers-form-wrapper">
                  <ResellersCustomers />
                </div>
              )}
              {/* {selectedOption === "Resellers Commission Details" && (
                <div className="resellers-commission_details-form-wrapper">
                  <AdminResellersCommisionDetails />
                </div>
              )} */}
              {selectedOption === "Add Licences Details" && (
                <div className="added-licence-details-form-wrapper">
                  <AddedLicencesDetails />
                </div>
              )}
              {selectedOption === "Report Master" && (
                <div className="report-master-details-form-wrapper">
                  <AdminReportsMaster />
                </div>
              )}
              {selectedOption === "Renewal Details" && (
                <div className="renewal-details-details-form-wrapper">
                  <RenewalDetails />
                </div>
              )}
              {/* Add more conditional rendering for other options */}
            </div>
            <Footer />
          </div>
        </div>
        <Modal show={isSearchModalOpen} onHide={toggleSearchModal}>
          <Modal.Header closeButton>
            <Modal.Title>Search Complete Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="searchKeyword">
              <Form.Control
                type="text"
                placeholder="Enter search keyword..."
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </Form.Group>

            {/* Dropdown-like suggestions */}
            {suggestedItems.length > 0 && (
              <ul
                className="list-group"
                style={{
                  maxHeight: "200px", // Adjust the maximum height as needed
                  overflowY: "auto", // Enable vertical scrollbar when content exceeds max height
                }}
              >
                {suggestedItems.map((item, index) => (
                  <li
                    key={index}
                    className={`list-group-item ${
                      index === hoveredItem ? "active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleSuggestionClick(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleSearchModal}>
              Close
            </Button>
            {/* Additional actions/buttons can be added here */}
          </Modal.Footer>
        </Modal>
      </div>
      <script src="assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="assets/js/shared/off-canvas.js"></script>
      <script src="assets/js/shared/misc.js"></script>
      <script src="assets/js/demo_1/dashboard.js"></script>
      <script
        src="assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
      <DetailModal
        isOpen={isDetailModalOpen}
        onClose={closeDetailModal}
        data={detailData}
      />
    </Fragment>
  );
};

export default AdminMainComponent;
