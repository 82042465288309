import React, { Fragment, useState, useEffect } from "react";
import {
  getCustomerlistwthorders,
  // getCustCasesLists,
  add_licence_customer,
  getSkudetails,
} from "../../utils/api";
import { Modal, Button } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

const AddLicence = () => {
  const [customers, setCustomers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [cases, setCases] = useState([]);
  const [showCases, setShowCases] = useState(false);
  const [casesCountCache, setCasesCountCache] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // New state for error messages
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const [loading, setLoading] = useState(false); // Loading state

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  // Function to fetch customer list
  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const loginDataFromStorage = localStorage.getItem("userDetails");
      if (loginDataFromStorage) {
        const parsedLoginData = JSON.parse(loginDataFromStorage);
        setEmail(parsedLoginData.reseller_email);
        const data = await getCustomerlistwthorders(
          parsedLoginData.reseller_email
        );
        setCustomers(data);
        setInitialUserList(data);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = initialUserList.filter((user) => {
      if (user) {
        return (
          (user.customer_domainname &&
            user.customer_domainname.toLowerCase().includes(value)) ||
          (user.customer_name &&
            user.customer_name.toLowerCase().includes(value)) ||
          (user.customer_emailid &&
            user.customer_emailid.toLowerCase().includes(value)) ||
          (user.customer_contact_number &&
            user.customer_contact_number.toLowerCase().includes(value))
        );
      }

      return false; // Skip this user if domain_name is undefined
    });
    setCustomers(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setCustomers(initialUserList);
  };
  const handleSort = (column) => {
    let sortedList = [...initialUserList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setCustomers(sortedList);
  };

  // const handleRowClick = async (cust_id) => {
  //   setSelectedCustomerId(cust_id);
  //   try {
  //     setLoading(true);
  //     const caseData = await getCustCasesLists(cust_id);
  //     setCases(caseData.data_result);
  //     setShowCases(true);
  //     setShowProductsModal(false); // Hide product details modal if a new customer is clicked
  //   } catch (error) {
  //     console.error("Failed to fetch cases:", error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleCustomerClick = async (cust_id) => {
    setLoading(true);
    setSelectedCustomer(cust_id);
    console.log(cust_id);
    try {
      const sku_details = await getSkudetails(cust_id);
      console.log(sku_details.data_result[0].prod_details);
      setProductDetails(
        sku_details.data_result[0].prod_details.map((detail) => ({
          ...detail,
          initialQuantity: parseInt(Object.values(detail)[0]), // Store the initial quantity for comparison
        }))
      );
      setShowProductsModal(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Failed to fetch details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleIncreaseQuantity = (index) => {
    const updatedProductDetails = [...productDetails];
    const productKey = Object.keys(updatedProductDetails[index])[0];
    updatedProductDetails[index][productKey] =
      parseInt(updatedProductDetails[index][productKey]) + 1;
    setProductDetails(updatedProductDetails);
    setErrorMessage("");
  };

  const handleDecreaseQuantity = (index) => {
    const updatedProductDetails = [...productDetails];
    const productKey = Object.keys(updatedProductDetails[index])[0];
    const currentQuantity = parseInt(updatedProductDetails[index][productKey]);
    const initialQuantity = updatedProductDetails[index].initialQuantity; // Use initialQuantity

    if (currentQuantity > initialQuantity) {
      updatedProductDetails[index][productKey] = currentQuantity - 1;
      setProductDetails(updatedProductDetails);
      setErrorMessage(""); // Clear error message on valid decrease
    } else {
      setErrorMessage("You can't decrease below the existing quantity."); // Set error message
    }
  };

  const handleCloseCases = () => {
    setShowCases(false);
    setCases([]);
  };

  // useEffect(() => {
  //   const fetchCasesCounts = async () => {
  //     const counts = {};
  //     for (const customer of customers) {
  //       try {
  //         setLoading(true);
  //         const caseData = await getCustCasesLists(customer.cust_id);
  //         counts[customer.cust_id] = caseData.data_result.length;
  //       } catch (error) {
  //         console.error(
  //           `Failed to fetch cases count for customer ${customer.cust_id}:`,
  //           error.message
  //         );
  //         counts[customer.cust_id] = null;
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //     setCasesCountCache(counts);
  //   };

  //   if (customers.length > 0) {
  //     fetchCasesCounts();
  //   }
  // }, [customers]);

  const handleCloseProductsModal = () => {
    setShowProductsModal(false);
    setProductDetails([]);
    // setSelectedCase(null);
  };

  const handleAddLicence = async () => {
    setLoading(true);
    const customerId = selectedCustomer;
    // Format the productData as strings
    const productData = productDetails
      .map((productDetail) => {
        const productName = Object.keys(productDetail)[0]; // Trim the product name
        const newQuantity = parseInt(productDetail[productName]);
        const initialQuantity = productDetail.initialQuantity;
        return `newQuantity:${newQuantity},initialQuantity:${initialQuantity},productName:${productName.trim()}`;
      })
      .join("-"); // Join each product string with a hyphen

    const quantitiesChanged = productDetails.some((productDetail) => {
      const productName = Object.keys(productDetail)[0];
      const newQuantity = parseInt(productDetail[productName]);
      const initialQuantity = productDetail.initialQuantity;
      return newQuantity !== initialQuantity;
    });

    if (!quantitiesChanged) {
      setErrorMessage("Please add at least one product licence.");
      return;
    }
    try {
      const response = await add_licence_customer(
        customerId,
        productData,
        email,
        parsedLoginData.reseller_type
      );
      window.alert(response.message);
      // Refresh customer list after successful addition
      fetchCustomers();
      handleCloseProductsModal();
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="row">
        <div
          className={`col-lg-${
            showCases ? "6" : "12"
          } grid-margin stretch-card`}
        >
          <div className="card">
            <div className="card-body">
              <h4 className="mb-1">Add Licence</h4>
              <br />
              <div className="search-container mb-3">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => handleSearchInputChange(e.target.value)}
                  style={{ float: "left", width: "250px" }} // Specify width here
                  className="form-control"
                />
                <button
                  className="btn btn-primary ml-2"
                  onClick={handleReset}
                  style={{ float: "left" }}
                >
                  Reset
                </button>
              </div>
              <br />
              <div
                className="table-responsive"
                style={{ maxHeight: "450px", overflowY: "auto" }}
              >
                <table className="table table-hover sticky-header">
                  <thead>
                    <tr>
                      <th
                        style={thStyles}
                        className="sortable-header"
                        onClick={() => handleSort("customer_name")}
                      >
                        Customer Name
                        {sortColumn === "customer_name" ? (
                          sortOrder === "asc" ? (
                            <FaSortUp className="sort-icon" />
                          ) : (
                            <FaSortDown className="sort-icon" />
                          )
                        ) : (
                          <FaSort className="sort-icon" /> // Default sort icon
                        )}
                      </th>
                      <th
                        style={thStyles}
                        className="sortable-header"
                        onClick={() => handleSort("customer_domainname")}
                      >
                        Domain Name
                        {sortColumn === "customer_domainname" ? (
                          sortOrder === "asc" ? (
                            <FaSortUp className="sort-icon" />
                          ) : (
                            <FaSortDown className="sort-icon" />
                          )
                        ) : (
                          <FaSort className="sort-icon" /> // Default sort icon
                        )}
                      </th>
                      <th style={thStyles}>Customer Email ID</th>
                      <th style={thStyles}>Phone No</th>
                      <th style={thStyles}>Created On</th>
                      {/* <th style={thStyles}>Cases Count</th> */}
                      <th style={thStyles}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((user) => (
                      <tr
                        key={user.cust_id}
                        // onClick={() => handleRowClick(user.cust_id)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{user.customer_name}</td>
                        <td>{user.customer_domainname}</td>
                        <td>{user.customer_emailid}</td>
                        <td>{user.customer_contact_number}</td>
                        <td>
                          {new Date(user.created_on).toLocaleDateString()}
                        </td>

                        <td>
                          <Button
                            variant="primary"
                            onClick={() => handleCustomerClick(user.cust_id)}
                          >
                            Show Products Details
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* {showCases && (
          <div className="col-lg-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="mb-1">Cases Lists</h4>
                <Button
                  variant="secondary"
                  onClick={handleCloseCases}
                  style={{ float: "right" }}
                >
                  Close
                </Button>
                <br />
                <br />
                {cases.length === 0 ? (
                  <p>No cases available for the selected customer.</p>
                ) : (
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "450px", overflowY: "auto" }}
                  >
                    <table className="table table-hover sticky-header">
                      <thead>
                        <tr>
                          <th style={thStyles}>Case ID</th>
                          <th style={thStyles}>Case Name</th>
                          <th style={thStyles}>Status</th>
                          <th style={thStyles}>Created On</th>
                          <th style={thStyles}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cases.map((caseItem) => (
                          <tr
                            key={caseItem.case_id}
                            // onClick={() => handleCaseClick(caseItem)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{caseItem.case_id}</td>
                            <td>{caseItem.case_name}</td>
                            <td>{caseItem.status}</td>
                            <td>
                              {new Date(
                                caseItem.created_date
                              ).toLocaleDateString()}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={() => handleCaseClick(caseItem)}
                              >
                                Add Licence
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )} */}
        {showProductsModal && (
          <Modal show={showProductsModal} onHide={handleCloseProductsModal}>
            <Modal.Header>
              <Modal.Title>Product Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedCustomer && (
                <>
                  <h5>Case Name: {selectedCustomer.case_name}</h5>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productDetails.map((productDetail, index) => {
                          const productKey = Object.keys(productDetail)[0];
                          const quantity = productDetail[productKey];

                          // Split the productKey into productName and invoiceId
                          const [productName, invoiceId] =
                            productKey.split(",");

                          return (
                            <tr key={index}>
                              <td>{productName}</td>
                              <td>{quantity}</td>
                              <td>
                                <Button
                                  variant="primary"
                                  onClick={() => handleIncreaseQuantity(index)}
                                >
                                  +
                                </Button>{" "}
                                <Button
                                  variant="secondary"
                                  onClick={() => handleDecreaseQuantity(index)}
                                  style={{ marginLeft: "5px" }}
                                >
                                  -
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {errorMessage && (
                <div className="alert alert-danger mt-3">{errorMessage}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseProductsModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddLicence}>
                Add Licence
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </Fragment>
  );
};

export default AddLicence;
