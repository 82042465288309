import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import {
  getRenewalDetails,
  placeOrderRenewalByAdmin,
  getRenewalNotificationsDetails,
  licenceApproval,
} from "../../utils/api";
import { FaSortUp, FaSortDown, FaSort, FaBell } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import { common } from "@mui/material/colors";

const RenewalDetails = () => {
  const [renewalDetails, setRenewalDetails] = useState([]);
  const [renewalNotificationsDetails, setRenewalNotificationsDetails] =
    useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [initialRenewalList, setInitialRenewalList] = useState([]);
  const [showRenewalDetailsModal, setShowRenewalDetailsModal] = useState(false);
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  // State to manage license count and plan duration
  const [licenseCount, setLicenseCount] = useState(null);
  const [planDuration, setPlanDuration] = useState(1); // Default to 1 month if plan is monthly
  const [expiryStatus, setExpiryStatus] = useState("");
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showReductionDetailsModal, setShowReductionDetailsModal] =
    useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  useEffect(() => {
    async function fetchRenewalDetails() {
      try {
        setLoading(true);
        const data = await getRenewalDetails(admin_email);
        setRenewalDetails(data);
        setInitialRenewalList(data);
      } catch (error) {
        console.error("Failed to fetch renewal details:", error.message);
      } finally {
        setLoading(false);
      }
    }
    fetchRenewalDetails();
  }, []);
  useEffect(() => {
    async function fetchRenewalNotificationDetails() {
      try {
        setLoading(true);
        const renewal_notiDetails = await getRenewalNotificationsDetails();
        console.log(renewal_notiDetails);
        setRenewalNotificationsDetails(renewal_notiDetails);
        // Filter data where approval_status is "Pending"
        const pendingNotifications = renewal_notiDetails.filter(
          (item) => item.approval_status === "Pending"
        );

        // Update the pending count
        setPendingCount(pendingNotifications.length);
      } catch (error) {
        console.error(
          "Failed to fetch renewal notifications details:",
          error.message
        );
      } finally {
        setLoading(false);
      }
    }
    fetchRenewalNotificationDetails();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = initialRenewalList.filter((renewal) => {
      if (renewal) {
        return (
          (renewal.customer_name &&
            renewal.customer_name.toLowerCase().includes(value)) ||
          (renewal.customer_domainname &&
            renewal.customer_domainname.toLowerCase().includes(value)) ||
          (renewal.reseller_name &&
            renewal.reseller_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setRenewalDetails(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setRenewalDetails(initialRenewalList);
  };

  const handleSort = (column) => {
    let sortedList = [...filteredCustomerList];
    if (sortColumn === column) {
      sortedList.reverse();
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredCustomerList(sortedList);
  };

  const openRenewalModal = (customer, text) => {
    const currentDate = new Date();
    const expiryDate = new Date(customer.renewal_date);
    const startDate =
      expiryDate > currentDate
        ? new Date(expiryDate.setDate(expiryDate.getDate() + 1))
        : currentDate;

    setSelectedCustomer({
      ...customer,
      start_date: startDate.toLocaleDateString(),
    });
    setLicenseCount(customer.usercount);
    setPlanDuration(customer.plan_duration);
    setExpiryStatus(text);
    setShowRenewalModal(true);
  };

  const closeRenewalModal = () => {
    setShowRenewalModal(false);
    setExpiryStatus("");
    setPlanDuration("");
  };

  // Subtotal calculation logic
  const calculateSubtotal = () => {
    if (!selectedCustomer) return 0;
    const shiviomPrice = selectedCustomer.shiviom_price;
    if (selectedCustomer.plan_type === "monthly") {
      return licenseCount * shiviomPrice * planDuration;
    } else if (selectedCustomer.plan_type === "yearly") {
      return licenseCount * shiviomPrice * planDuration * 12;
    }
    return 0;
  };

  const calculatetotalwthgst = () => {
    if (!selectedCustomer) return 0;
    const totalwthgst = parseInt(
      calculateSubtotal() + (calculateSubtotal() * 18) / 100
    );
    return totalwthgst;
  };

  const handleLicenseCountChange = (e) => {
    setLicenseCount(e.target.value);
  };

  const handlePlanDurationChange = (e) => {
    setPlanDuration(e.target.value);
    const startDate = new Date(selectedCustomer.start_date);
    let updatedExpiryDate;
    if (selectedCustomer.plan_type === "monthly") {
      updatedExpiryDate = new Date(startDate);
      updatedExpiryDate.setMonth(startDate.getMonth() + planDuration);
    } else if (selectedCustomer.plan_type === "yearly") {
      updatedExpiryDate = new Date(startDate);
      updatedExpiryDate.setFullYear(startDate.getFullYear() + planDuration);
    }

    setSelectedCustomer({
      ...selectedCustomer,
      new_expiry_date: updatedExpiryDate.toLocaleDateString(),
    });
  };
  const renderActionButtons = (customer, text) => (
    <Fragment>
      <button
        className="btn btn-primary"
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click from triggering
          openRenewalModal(customer, text);
        }}
      >
        View Details
      </button>
    </Fragment>
  );

  const calculateExpiryStatus = (renewalDate) => {
    const currentDate = new Date();
    const daysRemaining = Math.floor(
      (new Date(renewalDate) - currentDate) / (1000 * 60 * 60 * 24)
    );

    if (daysRemaining < 0) {
      return { text: "Expired", color: "red", fontWeight: "bold" };
    } else if (daysRemaining === 1) {
      return { text: "Expiring Tomorrow", color: "orange", fontWeight: "bold" };
    } else if (daysRemaining > 1 && daysRemaining < 7) {
      return {
        text: `Expiring in ${daysRemaining} days`,
        color: "orange",
        fontWeight: "bold",
      };
    } else if (daysRemaining >= 7 && daysRemaining <= 90) {
      return {
        text: `Expiring in ${daysRemaining} days`,
        color: "orange",
        fontWeight: "bold",
      };
    } else {
      return { text: "Active", color: "green", fontWeight: "bold" };
    }
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  const handleRenew = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("licid", selectedCustomer.lic_id);
      formData.append("plantype", selectedCustomer.plan_type);
      formData.append("planduration", planDuration);
      formData.append("custid", selectedCustomer.cust_id);
      formData.append("skuname", selectedCustomer.sku_name);
      formData.append("licencecount", licenseCount);
      formData.append("listprice", selectedCustomer.price);
      formData.append("shiviomprice", selectedCustomer.shiviom_price);
      formData.append("subtotal", calculateSubtotal());
      formData.append("totalwthgst", calculatetotalwthgst());
      const response = await placeOrderRenewalByAdmin(formData);
      console.log(response);
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        setShowRenewalModal(false);
        const renewaldata = await getRenewalDetails(admin_email);
        setRenewalDetails(renewaldata);
        setInitialRenewalList(renewaldata);
      } else {
        throw new Error("Failed to place order for renewal");
      }
    } catch (error) {
      console.error("Error placing order for renewal:", error.message);
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  const toggleNotificationModal = () => {
    setShowNotificationModal(!showNotificationModal);
  };
  const toggleRedcutionDetailsModal = () => {
    setShowReductionDetailsModal(!showReductionDetailsModal);
    setShowNotificationModal(false);
    setRejectionReason(false);
    setShowRejectionReason(false);
  };
  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setRejectionReason(""); // Clear any previous rejection reason
    setShowReductionDetailsModal(true);
  };
  const handleAccept = async () => {
    try {
      setLoading(true);
      // Update status to 'Accept' and log aproved id
      console.log("Status: Accept");
      console.log("Approval ID:", selectedNotification.aprov_id);
      const formData = new FormData();
      formData.append("approval_status", "Accepted");
      formData.append("aprov_id", selectedNotification.aprov_id);
      formData.append("reject_reason", null);
      const response = await licenceApproval(formData);
      if (response.status === 200) {
        window.alert("Status Updated Successfully");
        const renewal_notiDetails = await getRenewalNotificationsDetails();
        console.log(renewal_notiDetails);
        setRenewalNotificationsDetails(renewal_notiDetails);
        // Filter data where approval_status is "Pending"
        const pendingNotifications = renewal_notiDetails.filter(
          (item) => item.approval_status === "Pending"
        );

        // Update the pending count
        setPendingCount(pendingNotifications.length);
        const renewaldata = await getRenewalDetails(admin_email);
        setRenewalDetails(renewaldata);
        setInitialRenewalList(renewaldata);
      } else {
        window.alert("Error for Updating the Status");
      }

      toggleRedcutionDetailsModal();
    } catch (error) {
      console.error("Error for approval:", error.message);
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  const handleReject = async () => {
    // If the rejection reason hasn't been entered, show the textarea
    if (!showRejectionReason) {
      setShowRejectionReason(true);
    } else {
      // If the textarea is visible, validate and submit the rejection reason
      if (rejectionReason.trim() === "") {
        alert("Please provide a reason for rejection.");
        return; // Prevent rejection if no reason is provided
      }

      // Update status to 'Reject', and log rejection reason and approval ID
      console.log("Status: Reject");
      console.log("Approval ID:", selectedNotification.aprov_id);
      console.log("Rejection Reason:", rejectionReason);
      try {
        setLoading(true);
        // Update status to 'Accept' and log aproved id
        console.log("Status: Accept");
        console.log("Approval ID:", selectedNotification.aprov_id);
        const formData = new FormData();
        formData.append("approval_status", "Rejected");
        formData.append("aprov_id", selectedNotification.aprov_id);
        formData.append("reject_reason", rejectionReason);
        const response = await licenceApproval(formData);
        if (response.status === 200) {
          window.alert("Status Updated Successfully");
          const renewal_notiDetails = await getRenewalNotificationsDetails();
          console.log(renewal_notiDetails);
          setRenewalNotificationsDetails(renewal_notiDetails);
          // Filter data where approval_status is "Pending"
          const pendingNotifications = renewal_notiDetails.filter(
            (item) => item.approval_status === "Pending"
          );

          // Update the pending count
          setPendingCount(pendingNotifications.length);
          const renewaldata = await getRenewalDetails(admin_email);
          setRenewalDetails(renewaldata);
          setInitialRenewalList(renewaldata);
        } else {
          window.alert("Error for Updating the Status");
        }

        toggleRedcutionDetailsModal();
      } catch (error) {
        console.error("Error for approval:", error.message);
        // Handle error state or show error message to the user
      } finally {
        setLoading(false); // Set loading state to false after login attempt
      }
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d;
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff;
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Renewal Details</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left", width: "250px" }} // Specify width here
                className="form-control"
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>

              <div
                style={{
                  float: "left",
                  marginLeft: "10px",
                  position: "relative",
                }}
              >
                <FaBell
                  onClick={toggleNotificationModal}
                  size={24}
                  style={{
                    cursor: "pointer",
                    color: "#007bff",
                  }}
                  title="Notifications"
                />
                {parseInt(pendingCount) > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-5px",
                      right: "-5px",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "2px 6px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {parseInt(pendingCount)}
                  </span>
                )}
              </div>
            </div>
            <br />
            <div className="table-responsive">
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                      style={thStyles}
                    >
                      Customer Domain Name
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                      style={thStyles}
                    >
                      Reseller Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>SKU Name</th>
                    <th style={thStyles}>Expiry Date</th>
                    <th style={thStyles}>Licence Count</th>
                    <th style={thStyles}>Plan Type</th>
                    <th style={thStyles}>Plan Duration</th>
                    {/* <th style={thStyles}>List Price</th> */}
                    {/* <th style={thStyles}>Shiviom Price</th> */}
                    <th style={thStyles}>Expiry Status</th>
                    <th style={thStyles}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {renewalDetails.map((renewal, index) => {
                    const { text, color, fontWeight } = calculateExpiryStatus(
                      renewal.renewal_date
                    );
                    const uniqueKey = `${renewal.cust_id}-${renewal.customer_domainname}-${index}`; // Add index to make the key unique
                    return (
                      <tr
                        key={uniqueKey}
                        // onClick={() => handleViewDetails(renewal)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{renewal.customer_domainname}</td>
                        <td>{renewal.reseller_name}</td>
                        <td>{renewal.sku_name}</td>
                        <td>
                          {new Date(renewal.renewal_date).toLocaleDateString()}
                        </td>
                        <td>{renewal.usercount}</td>
                        <td>{renewal.plan_type}</td>
                        <td>{renewal.plan_duration}</td>
                        <td style={{ color: color, fontWeight: fontWeight }}>
                          {text}
                        </td>
                        <td>{renderActionButtons(renewal, text)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Renewal Modal */}
      <Modal
        show={showRenewalModal}
        onHide={closeRenewalModal}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Domain Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add renewal form content here */}
          <form className="form-sample">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="customername"
                      value={selectedCustomer?.customer_name || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Customer Domain Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      className="form-control"
                      id="customeremail"
                      value={selectedCustomer?.customer_domainname || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Type</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="plantype"
                      value={selectedCustomer?.plan_type || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Plan Duration</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      className="form-control"
                      id="planduration"
                      value={planDuration}
                      onChange={handlePlanDurationChange}
                      {...(expiryStatus !== "Expired" && { readOnly: true })} // Conditionally add readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Reseller Name</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="resellername"
                      value={selectedCustomer?.reseller_name || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Partner Discount</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="partnerdiscount"
                      value={`${selectedCustomer?.partner_discount}%`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Expiry Date</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="resellername"
                      value={new Date(
                        selectedCustomer?.renewal_date || ""
                      ).toLocaleDateString()}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <h5>
              <strong>Product Details</strong>
            </h5>
            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                border: "2px solid #000",
              }}
            >
              <table className="table">
                <thead>
                  <tr
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 100,
                      backgroundColor: "#dee2e6",
                    }}
                  >
                    <th>SKU Name</th>
                    <th>Licence Count</th>
                    <th>List Price</th>
                    <th>Shiviom Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody style={{ paddingTop: "30px" }}>
                  <tr>
                    <td>{selectedCustomer?.sku_name}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm text-center"
                        value={licenseCount || ""}
                        onChange={handleLicenseCountChange}
                        style={{ width: "80px" }} // Adjusted width
                        {...(expiryStatus !== "Expired" && { readOnly: true })} // Conditionally add readOnly
                      />
                    </td>
                    <td>{Number(selectedCustomer?.price).toLocaleString()}</td>
                    <td>
                      {Number(selectedCustomer?.shiviom_price).toLocaleString()}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={calculateSubtotal()}
                        readOnly
                        style={{ width: "80px" }} // Adjusted width
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Total</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="total"
                      value={calculateSubtotal()}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Total with GST(18%)</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="total"
                      value={calculatetotalwthgst()}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeRenewalModal}>
            Close
          </Button>
          {expiryStatus === "Expired" && (
            <Button variant="primary" onClick={handleRenew}>
              Renew
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showNotificationModal} onHide={toggleNotificationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : renewalNotificationsDetails.length === 0 ? (
            <p>You have no new notifications.</p>
          ) : (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {renewalNotificationsDetails.map((notification) => (
                  <li
                    key={notification.lic_id}
                    style={{
                      cursor: "pointer",
                      color:
                        notification.approval_status === "Pending"
                          ? "#007bff"
                          : "black",
                      textDecoration: "underline",
                    }}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <strong>{notification.reseller_name}</strong> wants to
                    reduce the license for{" "}
                    <strong>{notification.customerdomain_name}</strong> from{" "}
                    <strong>{notification.initial_licence_count}</strong> to{" "}
                    <strong>{notification.new_requested_count}</strong>.
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleNotificationModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showReductionDetailsModal}
        onHide={toggleRedcutionDetailsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Licence Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedNotification ? (
            <>
              <p>
                <strong>Reseller:</strong> {selectedNotification.reseller_name}
              </p>
              <p>
                <strong>Domain:</strong>{" "}
                {selectedNotification.customerdomain_name}
              </p>
              <p>
                <strong>Initial Licence Count:</strong>{" "}
                {selectedNotification.initial_licence_count}
              </p>
              <p>
                <strong>New Requested Count:</strong>{" "}
                {selectedNotification.new_requested_count}
              </p>
              <p>
                <strong>Status:</strong> {selectedNotification.approval_status}
              </p>
              {selectedNotification.approval_status === "Accepted" && (
                <p>
                  <strong>Approval Date:</strong>{" "}
                  {selectedNotification.approval_date}
                </p>
              )}
              {selectedNotification.approval_status === "Rejected" && (
                <>
                  <p>
                    <strong>Approval Date:</strong>{" "}
                    {selectedNotification.approval_date}
                  </p>
                  <p>
                    <strong>Rejection Reason:</strong>{" "}
                    {selectedNotification.rejection_reason}
                  </p>
                </>
              )}

              {selectedNotification.approval_status === "Pending" && (
                <>
                  <div>
                    <Button variant="success" onClick={handleAccept}>
                      Accept
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleReject}
                      style={{ marginLeft: "10px" }}
                    >
                      Reject
                    </Button>
                  </div>

                  {/* Show the rejection textarea only after clicking Reject */}
                  {showRejectionReason && (
                    <div style={{ marginTop: "10px" }}>
                      <label htmlFor="rejectionReason">Rejection Reason:</label>
                      <textarea
                        id="rejectionReason"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        placeholder="Provide a reason for rejection"
                        rows="4"
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                </>
              )}

              {/* For Accepted status, clicking Reject will show the rejection reason textarea */}
              {selectedNotification.approval_status === "Accepted" && (
                <>
                  <Button
                    variant="danger"
                    onClick={handleReject}
                    style={{ marginLeft: "10px" }}
                  >
                    Reject
                  </Button>

                  {/* Show the rejection textarea if user clicked reject */}
                  {showRejectionReason && (
                    <div style={{ marginTop: "10px" }}>
                      <label htmlFor="rejectionReason">Rejection Reason:</label>
                      <textarea
                        id="rejectionReason"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        placeholder="Provide a reason for rejection"
                        rows="4"
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                </>
              )}

              {selectedNotification.approval_status === "Rejected" && (
                <Button
                  variant="success"
                  onClick={handleAccept}
                  style={{ marginLeft: "10px" }}
                >
                  Accept
                </Button>
              )}
            </>
          ) : (
            <p>No details available.</p>
          )}
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary" onClick={toggleRedcutionDetailsModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Fragment>
  );
};

export default RenewalDetails;
