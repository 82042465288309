import React, { Fragment, useState, useEffect } from "react";
import {
  getCustomerQuotationlistAdminside,
  getRegisteredList,
  placeOrderByAdmin,
  getCustomerlist,
  get_products_data,
  getDiscount,
  getCustomerTermsconditions,
  getResellerTermsconditions,
  generateQuotationByAdmin,
  getSKUPrice,
} from "../../utils/api"; // Assuming you have an API function for getting customer cases list
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import { FaEdit } from "react-icons/fa";
import { Collapse, Checkbox } from "antd"; // using antd for UI, can also be custom
import { quotationdetails_modules } from "./reportModulesjson";
import { exportReports } from "../../utils/api";
const { Panel } = Collapse;
const QuotationDetails = () => {
  const [quotationList, setQuotationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [selectedQuotationDetails, setSelectedQuotationDetails] =
    useState(null);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQuotationModalVisible, setIsQuotationModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;
  const [customerList, setCustomerList] = useState([]);
  const [resellerList, setResellerList] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState(""); // To hold selected reseller email
  const [selectedCustomer, setSelectedCustomer] = useState(""); // To hold selected customer
  const [domainName, setDomainName] = useState("");
  const [planType, setPlanType] = useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [productData, setProductData] = useState({});
  const [selectedOEM, setSelectedOEM] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedSubproduct, setSelectedSubproduct] = useState("");
  const [subproductList, setSubproductList] = useState([]);
  const [userCount, setUserCount] = useState("");
  const [products, setProducts] = useState([]);
  const [partnerDiscount, setPartnerDiscount] = useState("");
  const [selectedQuotationFor, setSelectedQuotationFor] = useState("");
  const [customerTerms, setCustomerTerms] = useState("");
  const [resellerTerms, setResellerTerms] = useState("");
  const [finalTotal, setFinalTotal] = useState(0);
  const [finalTotalWithGst, setFinalTotalWithGst] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setLoading(true);
        const data = await get_products_data();
        console.log(data);
        setProductData(data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, []);
  useEffect(() => {
    const fetchResellerList = async () => {
      try {
        setLoading(true);
        const response = await getRegisteredList(admin_email);
        setResellerList(response || []);
      } catch (error) {
        console.error("Error fetching user list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResellerList();
  }, []);
  // Fetch customer list based on selected reseller email
  useEffect(() => {
    if (selectedReseller) {
      const fetchCustomerListData = async () => {
        try {
          setLoading(true);
          const data = await getCustomerlist(selectedReseller); // Pass selected reseller email
          fetchDataBasedOnResellerType();
          setCustomerList(data || []);
        } catch (error) {
          console.error("Error fetching customer list:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchCustomerListData();
    }
  }, [selectedReseller]);

  const fetchQuotationList = async () => {
    setLoading(true);
    try {
      const data = await getCustomerQuotationlistAdminside(admin_email);
      setQuotationList(data);
      setInitialUserList(data); // Save initial list for reset functionality
      setLoading(false);
      // }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuotationList();
  }, []);
  const fetchDataBasedOnResellerType = async () => {
    try {
      setLoading(true);
      let response;
      response = await getDiscount(selectedReseller);
      if (response) {
        const data = await response.json();
        if (data.success) {
          const { discount_in } = data.data;
          setPartnerDiscount(parseInt(discount_in));
        } else {
          window.alert(
            "Failed to fetch partner discount. Please try again later."
          );
        }
      }
    } catch (error) {
      window.alert("Failed to fetch partner discount. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  const calculateTotals = (products) => {
    const total = products.reduce((sum, product) => sum + product.subtotal, 0);
    const totalWithGst = total + total * 0.18; // Adding 18% GST

    setFinalTotal(total);
    setFinalTotalWithGst(totalWithGst);
  };
  const getSkuPrice = async (
    selectedSubproduct,
    partnerDiscount,
    planType,
    planDuration,
    userCount
  ) => {
    try {
      setLoading(true);

      let response = await getSKUPrice(
        selectedSubproduct,
        partnerDiscount,
        planType,
        planDuration,
        userCount
      );
      if (response) {
        const data = await response.json();
        if (data.success) {
          const price = data.data.pr_skuprice;
          const shivaamiPrice = data.data.pr_shivaamiprice;
          const subtotal = data.data.shivaamisubtotal;
          return { price, shivaamiPrice, subtotal }; // Return an object with both prices
        } else {
          window.alert("Failed to fetch skuprice. Please try again later.");
          return { price: 0, shivaamiPrice: 0, subtotal: 0 }; // Default values
        }
      }
    } catch (error) {
      window.alert("Failed to fetch skuprice. Please try again later.");
      return { price: 0, shivaamiPrice: 0, subtotal: 0 }; // Default values
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCustomerTerms = async () => {
      try {
        setLoading(true);
        const data = await getCustomerTermsconditions();
        setCustomerTerms(
          data
            .map((item, index) => `${index + 1}. ${item.conditions}`)
            .join("\n")
        );
      } catch (error) {
        console.error("Error fetching customer terms and conditions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerTerms();
  }, []);
  useEffect(() => {
    const fetchResellersTerms = async () => {
      try {
        setLoading(true);
        const data = await getResellerTermsconditions();
        setResellerTerms(
          data
            .map((item, index) => `${index + 1}. ${item.resellers_conditions}`)
            .join("\n")
        );
      } catch (error) {
        console.error("Error fetching reseller terms and conditions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResellersTerms();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = initialUserList.filter((quotation) => {
      if (quotation) {
        return (
          (quotation.domain_name &&
            quotation.domain_name.toLowerCase().includes(value)) ||
          (quotation.quotation_id &&
            quotation.quotation_id.toLowerCase().includes(value)) ||
          (quotation.company_name &&
            quotation.company_name.toLowerCase().includes(value)) ||
          (quotation.customer_name &&
            quotation.customer_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setQuotationList(filteredList);
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDateWithTime = new Date(date);
      endDateWithTime.setHours(23, 59, 59, 999); // Set time to 23:59:59
      setEndDate(endDateWithTime);
    } else {
      setEndDate(null);
    }
  };
  const filterByDateRange = () => {
    console.log(startDate, endDate);
    if (startDate && endDate) {
      // Check if endDate is before startDate
      if (endDate < startDate) {
        setDateError("End date cannot be less than the start date."); // Set error message
        return;
      }

      setDateError(""); // Clear error message if dates are valid

      const filteredList = initialUserList.filter((quot) => {
        const quotationDate = new Date(quot.created_date);
        return quotationDate >= startDate && quotationDate <= endDate;
      });
      setQuotationList(filteredList);
    } else {
      setDateError(""); // Clear error message if no dates are selected
      setQuotationList(initialUserList);
    }
  };

  const handleReset = () => {
    setSearchInput("");
    setStartDate("");
    setEndDate("");
    setQuotationList(initialUserList);
  };
  const handleViewQuotationDetails = (quotation) => {
    setSelectedQuotationDetails(quotation);
    setShowQuotationModal(true);
  };

  const handleSort = (column) => {
    let sortedList = [...initialUserList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setInitialUserList(sortedList);
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const getStatusStyle = (status) => {
    let color = "";
    let fontWeight = "normal";
    if (status === null) {
      return { color, fontWeight };
    }
    switch (status.toLowerCase()) {
      case "pending":
        color = "orange";
        fontWeight = "bold";
        break;
      case "accepted":
        color = "green";
        fontWeight = "bold";
        break;
      case "rejected":
        color = "red";
        fontWeight = "bold";
        break;
      case "delivered":
        color = "green";
        fontWeight = "bold";
        break;
      default:
        break;
    }

    return { color, fontWeight };
  };
  const handleCloseExportModal = () => {
    setIsModalVisible(false);
    // Reset all fields to their initial state
    setSelectedModule("");
    setSelectedOption("");
    setSelectedSubOption("");
  };
  const toggleModal = () => {
    setIsModalVisible(true);
  };
  const toggleGenerateQuotationModal = () => {
    setIsQuotationModalVisible(true);
  };
  const handleCloseQuotationModal = () => {
    setIsQuotationModalVisible(false);
    // Reset all fields to their initial state
    setSelectedModule("");
    setSelectedOption("");
    setSelectedSubOption("");
    setResellerTerms("");
    setSelectedReseller("");
    setSelectedCustomer("");
    setPlanDuration("");
    setPlanType("");
    setPartnerDiscount("");
    setSelectedQuotationFor("");
    setSelectedOEM("");
    setSelectedProduct("");
    setSelectedSubproduct("");
    setProducts([]);
  };

  const handleModuleChange = (moduleName) => {
    setSelectedModule(moduleName);
    setSelectedOption(null); // Reset selected option when module changes
    setSelectedSubOption(null); // Reset selected sub-option
  };

  const handleOptionChange = (optionName) => {
    setSelectedOption(optionName);
    setSelectedSubOption(null); // Reset selected sub-option when option changes
  };

  const handleSubOptionChange = (subOptionName) => {
    setSelectedSubOption((prev) =>
      prev === subOptionName ? null : subOptionName
    );
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      alert("Please select at least one report type.");
      return;
    }
    try {
      setLoading(true);
      const response = await exportReports(
        selectedModule,
        selectedOption ? selectedOption : null,
        selectedSubOption ? selectedSubOption : null,
        null,
        null,
        admin_email
      );
      if (response.status === 200) {
        const responsedata = await response.json();
        if (responsedata.flag_value === 1) {
          window.alert(responsedata.message);
          setSelectedModule(null);
          setSelectedOption(null);
          setSelectedSubOption(null);
          // setSelectedMonthYear(null);
          // setSelectedYear(null);
          setIsModalVisible(false);
        } else if (responsedata.flag_value === 2) {
          window.alert(responsedata.message);
        }
      } else {
        console.error("Failed to generate report:", response.statusText);
      }
    } catch (error) {
      console.error("Error generate report", error);
    } finally {
      setLoading(false);
    }
  };
  const handleGenerateQuotation = async () => {
    try {
      setLoading(true);
      if (
        !selectedReseller ||
        !selectedCustomer ||
        !domainName ||
        !planDuration ||
        !planType ||
        !partnerDiscount ||
        !selectedQuotationFor
      ) {
        window.alert("All Fields are Required");
        return;
      }

      // // Check if any product fields are empty
      if (products.length === 0) {
        window.alert("Please add at least one product");
      }
      // Construct data to send
      const selectedOEMs = products.map((product) => product.oemName).join(",");
      const selectedProducts = products
        .map((product) => product.productName)
        .join(",");
      const selectedSubproducts = products
        .map((product) => product.subproductName)
        .join(",");
      const userCounts = products.map((product) => product.userCount).join(",");

      const formDataToSend = new FormData();
      formDataToSend.append("reseller_email", selectedReseller);
      formDataToSend.append("cust_id", selectedCustomer);
      formDataToSend.append("domain_name", domainName);
      formDataToSend.append("plantype", planType);
      formDataToSend.append("planduration", planDuration);
      formDataToSend.append("selectedOEMs", selectedOEMs);
      formDataToSend.append("selectedProducts", selectedProducts);
      formDataToSend.append("selectedSubproducts", selectedSubproducts);
      formDataToSend.append("userCounts", userCounts);
      formDataToSend.append("quotationfor", selectedQuotationFor);
      formDataToSend.append("products", JSON.stringify(products));
      formDataToSend.append("customerterms", customerTerms);
      formDataToSend.append("resellerterms", resellerTerms);
      formDataToSend.append("partnerdiscount", partnerDiscount);

      const response = await generateQuotationByAdmin(formDataToSend);
      if (response.success === true) {
        window.alert(response.message);
        handleCloseQuotationModal();
      } else {
        window.alert(response.message);
      }
    } catch (error) {
      console.error("Error for generating quotation:", error.message);
      // Handle error state or show error message to the user
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  // Handle the reseller dropdown change
  const handleResellerChange = (e) => {
    setSelectedReseller(e.target.value);
    setSelectedCustomer(""); // Reset customer when reseller changes
    setDomainName(""); // Reset domain name when reseller changes
  };

  // Handle the customer dropdown change
  const handleCustomerChange = (e) => {
    const customerId = e.target.value;
    setSelectedCustomer(customerId);

    // Find the selected customer from customerList to get their domain name
    const selectedCustomerDetails = customerList.find(
      (customer) => customer.cust_id === customerId
    );
    if (selectedCustomerDetails) {
      setDomainName(selectedCustomerDetails.customer_domainname); // Set the domain name from the selected customer
    }
  };
  // Handle the plan type dropdown change
  const handlePlanTypeChange = (e) => {
    setPlanType(e.target.value);
  };
  const handleAddProduct = async () => {
    if (!selectedOEM || !selectedProduct || !selectedSubproduct || !userCount) {
      window.alert("Please fill all the fields");
      return;
    }

    try {
      const { price, shivaamiPrice, subtotal } = await getSkuPrice(
        selectedSubproduct,
        partnerDiscount,
        planType,
        planDuration,
        userCount
      ); // Fetch SKU price
      console.log(price, shivaamiPrice, subtotal);
      const product = {
        oemName: selectedOEM,
        productName: selectedProduct,
        subproductName: selectedSubproduct,
        userCount: userCount,
        skuPrice: price, // Add SKU price to the product object
        shivaamiprice: shivaamiPrice,
        subtotal: subtotal,
      };
      const updatedProducts = [...products, product];
      setProducts(updatedProducts);

      // Recalculate totals after adding a product
      calculateTotals(updatedProducts);
      setProducts([...products, product]);
      setSelectedOEM("");
      setSelectedProduct("");
      setSelectedSubproduct("");
      setUserCount("");
    } catch (error) {
      window.alert("Failed to add product");
    }
  };
  const handleDeleteProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);

    // Recalculate totals after deleting a product
    calculateTotals(updatedProducts);
  };

  const handleEditProduct = (index, product) => {
    setSelectedOEM(product.oemName);
    setSelectedProduct(product.productName);
    setSelectedSubproduct(product.subproductName);
    setUserCount(product.userCount);
    handleDeleteProduct(index);
  };
  const handleQuotationForChange = (event) => {
    setSelectedQuotationFor(event.target.value);
  };
  const handleSkuPriceChange = (index, newPrice) => {
    const updatedProducts = products.map((product, i) =>
      i === index ? { ...product, skuPrice: newPrice } : product
    );
    setProducts(updatedProducts);
  };
  const handlePlaceOrder = async () => {
    try {
      setLoading(true);
      if (selectedQuotationDetails) {
        const {
          quotation_id,
          plan_type,
          plan_duration,
          reseller_email,
          case_name,
        } = selectedQuotationDetails;

        // if(!voucherCode){
        //   setVoucherCode("None")
        // }
        // Example of sending data to an API
        // await placeOrder({ quotation_id, plan_type, plan_duration, reseller_email });
        const response = await placeOrderByAdmin(
          quotation_id,
          plan_type,
          plan_duration,
          voucherCode
        );
        if (response.status === 200) {
          const data = await response.json();
          window.alert(data.message);
          // window.alert("Order Placed Successfully.");
          // window.location.href = "/usermaincomponent";
          setShowQuotationModal(false);
          setQuotationList(initialUserList);
        } else {
          const data = await response.json();
          window.alert(data.message);
        }
        fetchQuotationList();
        setShowQuotationModal(false);
      }
    } catch (error) {
      window.alert("Error for place order:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
          .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
            .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Quotation Lists</h4>
            <br />
            <div className="search-container mb-3 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => handleSearchInputChange(e.target.value)}
                  className="form-control mr-2"
                  style={{ width: "200px" }}
                />
                <button className="btn btn-primary mr-2" onClick={handleReset}>
                  Reset
                </button>
                <button
                  className="btn btn-primary"
                  onClick={toggleGenerateQuotationModal}
                >
                  Generate Quotation
                </button>
              </div>

              <div className="d-flex align-items-center">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control mr-2"
                />{" "}
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control mr-2"
                />{" "}
                <button
                  className="btn btn-primary mr-2"
                  onClick={filterByDateRange}
                >
                  Filter
                </button>
                <button
                  className="btn btn-primary"
                  onClick={toggleModal}
                  title="Export Reseller Data"
                >
                  <CiExport size={15} />
                </button>
              </div>
            </div>
            {dateError && <div className="text-danger mt-2">{dateError}</div>}

            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Quotation ID</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("domain_name")}
                    >
                      Customer Name
                      {sortColumn === "domain_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles}>Created Date</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Reseller Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles}>Quotation Expiry</th>
                    <th style={thStyles}>Order Status</th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {quotationList.map((quotation) => (
                    <tr
                      key={quotation.quotation_id}
                      onClick={() => handleViewQuotationDetails(quotation)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{quotation.quotation_id}</td>
                      <td>{quotation.customer_name}</td>
                      <td>{quotation.domain_name}</td>
                      <td>
                        {new Date(quotation.created_date).toLocaleDateString()}
                      </td>
                      <td>{quotation.company_name}</td>
                      <td>
                        {new Date(
                          quotation.quotation_expiry
                        ).toLocaleDateString()}
                      </td>
                      <td style={getStatusStyle(quotation.order_status)}>
                        {quotation.order_status}
                      </td>

                      {/* <td>{renderActionButtons(quotation)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedQuotationDetails && (
        <Modal
          show={showQuotationModal}
          onHide={() => setShowQuotationModal(false)}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Quotation Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="customerName"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Customer Name</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="customerName"
                        value={selectedQuotationDetails.customer_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_customerdomainname"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Customer Domain Name</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_customerdomainname"
                        value={selectedQuotationDetails.domain_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedQuotationDetails.case_name !== null && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="caseName"
                        className="col-sm-3 col-form-label"
                      >
                        <strong>Case Name</strong>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="case_name"
                          value={selectedQuotationDetails.case_name}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_plantype"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Plan Type</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_plantype"
                        value={selectedQuotationDetails.plan_type}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_planduration"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Plan Duration</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_planduration"
                        value={selectedQuotationDetails.plan_duration}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="in_reseller_email"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Reseller Email</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="in_reseller_email"
                        value={selectedQuotationDetails.reseller_email}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="in_reseller_name"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Reseller Name</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="in_reseller_name"
                        value={selectedQuotationDetails.reseller_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {/* Add other fields similarly */}
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <h5>Product Details</h5>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 100,
                            backgroundColor: "#dee2e6",
                          }}
                        >
                          <th>SKU Name</th>
                          <th>User Count</th>
                          <th>List Price</th>
                          <th>Shiviom Price</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody style={{ paddingTop: "30px" }}>
                        {selectedQuotationDetails.concatenated_products.map(
                          (product, index) => {
                            const productName = product[0];
                            const userCount = product[1];
                            const actualPrice = product[2];
                            const shivaamiPrice = product[3];
                            const finalPrice = product[5];
                            return (
                              <tr key={index}>
                                <td>{productName}</td>
                                <td>{userCount}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={actualPrice}
                                    // onChange={(e) =>
                                    //   handlePriceChange(e, index, "actual")
                                    // }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={shivaamiPrice}
                                    // onChange={(e) =>
                                    //   handlePriceChange(e, index, "final")
                                    // }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={finalPrice}
                                    // onChange={(e) =>
                                    //   handlePriceChange(e, index, "final")
                                    // }
                                    readOnly
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Total</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.final_price_wto_gst}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Partner Discount</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.discount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      Discount Amount
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.discount_amt}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      Discounted Price
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.discounted_price}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* {selectedQuotationDetails.case_name !== "" && (
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label
                        for="pr_pricewtgst"
                        class="col-sm-3 col-form-label"
                      >
                        Additional Deal discount
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          id="additional_deal_discount"
                          value={
                            selectedQuotationDetails.additional_deal_discount
                              ? selectedQuotationDetails.additional_deal_discount +
                                "%"
                              : "0%"
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label
                        for="pr_pricewtgst"
                        class="col-sm-3 col-form-label"
                      >
                        Final Discounted Price
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          id="final_discounted_price"
                          value={
                            selectedQuotationDetails.final_discounted_price
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtgst"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>GST Amount(18%)</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtgst"
                        value={selectedQuotationDetails.GST_ammount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtgst"
                      className="col-sm-3 col-form-label"
                    >
                      <strong>Price with GST</strong>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtgst"
                        value={selectedQuotationDetails.final_price_wt_gst}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowQuotationModal(false)}
            >
              Close
            </Button>
            {selectedQuotationDetails &&
              selectedQuotationDetails.order_status !== "Delivered" &&
              new Date(selectedQuotationDetails.quotation_expiry) >
                new Date() && (
                <Button
                  variant="primary"
                  onClick={() => handlePlaceOrder(selectedQuotationDetails)}
                >
                  Place Order
                </Button>
              )}
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        title="Export Data"
        onHide={handleCloseExportModal}
        show={isModalVisible}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Collapse>
            {quotationdetails_modules.map((module, moduleIndex) => (
              <Panel header={module.name} key={moduleIndex}>
                <Checkbox
                  checked={selectedModule === module.name}
                  onChange={() => handleModuleChange(module.name)}
                >
                  Select Report Type
                </Checkbox>
                {selectedModule === module.name && (
                  <div style={{ paddingLeft: "20px" }}>
                    {module.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <Checkbox
                          onChange={() => handleOptionChange(option.name)}
                          checked={selectedOption === option.name}
                        >
                          {option.name}
                        </Checkbox>
                        {selectedOption === option.name &&
                          option.subOptions && (
                            <div style={{ paddingLeft: "20px" }}>
                              {option.subOptions.map((subOption, subIndex) => (
                                <div key={subIndex}>
                                  <Checkbox
                                    onChange={() =>
                                      handleSubOptionChange(subOption)
                                    }
                                    checked={selectedSubOption === subOption}
                                  >
                                    {subOption}
                                  </Checkbox>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </Panel>
            ))}
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Get Report
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        title="Generate Quotation"
        onHide={handleCloseQuotationModal}
        show={isQuotationModalVisible}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Reseller Dropdown */}
          <form className="form-sample">
            {" "}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Select Reseller</strong>
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="form-control"
                      value={selectedReseller}
                      onChange={handleResellerChange}
                    >
                      <option value="">Select Reseller</option>
                      {resellerList.map((reseller) => (
                        <option
                          key={reseller.reseller_id}
                          value={reseller.reseller_email}
                        >
                          {reseller.reseller_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  {/* Customer Dropdown */}
                  <label className="col-sm-3 col-form-label">
                    <strong>Select Customer</strong>
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="form-control"
                      value={selectedCustomer}
                      onChange={handleCustomerChange}
                      disabled={!selectedReseller || loading} // Disable if no reseller selected or loading
                    >
                      <option value="">Select Customer</option>
                      {customerList.map((customer) => (
                        <option key={customer.cust_id} value={customer.cust_id}>
                          {customer.customer_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Domain Name</strong>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      value={domainName}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Select Plan Type</strong>
                  </label>
                  <div className="col-sm-9">
                    <select
                      className="form-control"
                      value={planType}
                      onChange={handlePlanTypeChange}
                    >
                      <option value="">Plan Type</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Plan Duration</strong>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      min="1"
                      placeholder="Enter duration in months/years"
                      value={planDuration}
                      onChange={(e) => setPlanDuration(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Partner Discount</strong>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      value={partnerDiscount}
                      onChange={(e) => setPartnerDiscount(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                <strong>Add Product Details</strong>
              </label>
              <div className="d-flex">
                <select
                  className="form-control mr-2"
                  value={selectedOEM}
                  onChange={(e) => {
                    setSelectedOEM(e.target.value);
                    setSelectedProduct("");
                    setSelectedSubproduct("");
                  }}
                >
                  <option value="">Select OEM</option>
                  {Object.keys(productData).map((oem) => (
                    <option key={oem} value={oem}>
                      {oem}
                    </option>
                  ))}
                </select>
                <select
                  className="form-control mr-2"
                  value={selectedProduct}
                  onChange={(e) => {
                    setSelectedProduct(e.target.value);
                    setSubproductList(
                      productData[selectedOEM][e.target.value] || []
                    );
                    setSelectedSubproduct("");
                  }}
                  required
                >
                  <option value="">Select Product</option>
                  {selectedOEM &&
                    Object.keys(productData[selectedOEM]).map((product) => (
                      <option key={product} value={product}>
                        {product}
                      </option>
                    ))}
                </select>
                <select
                  className="form-control mr-2"
                  value={selectedSubproduct}
                  onChange={(e) => setSelectedSubproduct(e.target.value)}
                  required
                >
                  <option value="">Select Skuname</option>
                  {subproductList.map((subproduct) => (
                    <option key={subproduct} value={subproduct}>
                      {subproduct}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  className="form-control mr-2"
                  placeholder="User Count"
                  value={userCount}
                  onChange={(e) => setUserCount(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddProduct}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="form-group">
              <label>
                <strong>Added Products</strong>
              </label>
              <div className="row mt-4">
                <div className="col-12">
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={thStyles}>OEM Name</th>
                          <th style={thStyles}>Product Name</th>
                          <th style={thStyles}>SkuName</th>
                          <th style={thStyles}>User Count</th>
                          <th style={thStyles}>List Price</th>
                          <th style={thStyles}>Shiviom Price</th>{" "}
                          <th style={thStyles}>Subtotal</th>{" "}
                          <th style={thStyles}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, index) => (
                          <tr key={index}>
                            <td>{product.oemName}</td>
                            <td>{product.productName}</td>
                            <td>{product.subproductName}</td>
                            <td>{product.userCount}</td>
                            <td>
                              <input
                                type="number"
                                value={product.skuPrice} // Editable SKU price
                                // onChange={(e) =>
                                //   handleSkuPriceChange(index, e.target.value)
                                // } // Handle changes
                                className="form-control"
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={product.shivaamiprice} // Editable SKU price
                                // onChange={(e) =>
                                //   handleSkuPriceChange(index, e.target.value)
                                // } // Handle changes
                                className="form-control"
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={product.subtotal} // Editable SKU price
                                // onChange={(e) =>
                                //   handleSkuPriceChange(index, e.target.value)
                                // } // Handle changes
                                className="form-control"
                                readOnly
                              />
                            </td>
                            <td>
                              <span
                                variant="warning"
                                size="sm"
                                style={{
                                  marginLeft: "10px",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleEditProduct(index, product)
                                }
                              >
                                <FaEdit />
                              </span>
                              {"   "}
                              <span
                                variant="danger"
                                size="sm"
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDeleteProduct(index)}
                              >
                                ✘
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-6 col-form-label">
                    <strong>Final Total:</strong>
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      value={parseInt(finalTotal)}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-6 col-form-label">
                    <strong>Total with 18% GST:</strong>
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      value={parseInt(finalTotalWithGst)}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Reseller Terms & Conditions</strong>
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      value={resellerTerms}
                      onChange={(e) => setResellerTerms(e.target.value)}
                      rows="3"
                      className="form-control"
                      placeholder="Enter terms and conditions for reseller here..."
                    />
                  </div>
                </div>
              </div>

              {/* Customer Terms & Conditions */}
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Customer Terms & Conditions</strong>
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      value={customerTerms}
                      onChange={(e) => setCustomerTerms(e.target.value)}
                      rows="3"
                      className="form-control"
                      placeholder="Enter your terms and conditions for customers here..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    <strong>Send Quotation For</strong>
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-control"
                      value={selectedQuotationFor}
                      onChange={handleQuotationForChange}
                    >
                      <option value="">Select</option>
                      <option value="Both">Both</option>
                      <option value="Reseller">Own</option>
                      <option value="Customer">Customer</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            key="submit"
            type="primary"
            onClick={() => handleGenerateQuotation(selectedCustomer)}
          >
            Send Quotation
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default QuotationDetails;
