import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import {
  // sendDataToApi,
  get_products_datdetails,
  // getCustomerlist,
  // getCustomerCaseslist,
  // sendEstimation,
  getDiscount,
  getCustomerlist,
  // placeOrder,
  sendQuotationNew,
  getCustomerTermsconditions,
  getCommision,
  getResellerTermsconditions,
} from "../../utils/api.js";
import { useCookies } from "react-cookie";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import UserQuotationDetails from "./quotationdetails";

const ProductPriceCalculator = () => {
  const [jsonData, setJsonData] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedoem, setSelectedoem] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [cookies] = useCookies(["email"]);
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const email = cookies.email;
  // const email = "khetalsarode@gmail.com";
  // Use state to track if the tooltip is hovered
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProductsModal, setSelectedProductsModal] = useState([]);
  const [userCounts, setUserCounts] = useState({});
  const [planType, setPlanType] = useState("");
  const [planDuration, setPlanDuration] = useState({});
  const [comment, setComment] = useState(""); // State for storing the comment
  const [discount, setDiscount] = useState(0); // State to store the discount value
  const [commision, setCommision] = useState(0); // State to store the commision value
  const [customerTerms, setCustomerTerms] = useState("");
  const [resellerTerms, setResellerTerms] = useState("");
  const [showResellerTermsModal, setShowResellerTermsModal] = useState(false);
  const [currentDollarPrice, setCurrentDollarPrice] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedQuotationFor, setSelectedQuotationFor] = useState(""); // State for selecting who the quotation should be sent to
  const [selectedOption, setSelectedOption] = useState("");

  // Toggle tooltip hover state
  const handleTooltipHover = (hovered) => {
    setIsTooltipHovered(hovered);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setLoading(true);
        const response = await get_products_datdetails();
        setCurrentDollarPrice(response.current_dollar_price);
        setJsonData(response);
      } catch (error) {
        window.alert("Failed to fetch JSON data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, []);
  useEffect(() => {
    const fetchDataBasedOnResellerType = async () => {
      try {
        setLoading(true);
        let response;
        if (parsedLoginData.reseller_type === "Direct") {
          response = await getDiscount(email);
        } else if (parsedLoginData.reseller_type === "Indirect") {
          response = await getCommision(email);
        }

        if (response) {
          const data = await response.json();
          if (data.success) {
            if (parsedLoginData.reseller_type === "Direct") {
              const { discount_in } = data.data;
              setDiscount(parseInt(discount_in));
            } else if (parsedLoginData.reseller_type === "Indirect") {
              const { commision_percentage } = data.data;
              setCommision(parseInt(commision_percentage));
            }
          } else {
            window.alert(
              "Failed to fetch discount or commission. Please try again later."
            );
          }
        }
      } catch (error) {
        window.alert("Failed to fetch discount. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchDataBasedOnResellerType();
  }, []);
  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        setLoading(true);
        const customers = await getCustomerlist(email);
        setCustomerList(customers);
      } catch (error) {
        console.error("Error fetching customer list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerList();
  }, []);
  useEffect(() => {
    const fetchCustomerTerms = async () => {
      try {
        setLoading(true);
        const data = await getCustomerTermsconditions();
        setCustomerTerms(
          data
            .map((item, index) => `${index + 1}. ${item.conditions}`)
            .join("\n")
        );
      } catch (error) {
        console.error("Error fetching customer terms and conditions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerTerms();
  }, []);
  useEffect(() => {
    const fetchResellersTerms = async () => {
      try {
        setLoading(true);
        const data = await getResellerTermsconditions();
        setResellerTerms(
          data
            .map((item, index) => `${index + 1}. ${item.resellers_conditions}`)
            .join("\n")
        );
      } catch (error) {
        console.error("Error fetching reseller terms and conditions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResellersTerms();
  }, []);
  // Reset all dropdowns when the modal closes
  useEffect(() => {
    if (!showModal) {
      setSelectedoem("");
      setSelectedProduct("");
      setPlanType("");
    }
  }, [showModal]);
  const handleAddProduct = (oem, product, price) => {
    const productObj = { oem, product, price };
    const isSelected = selectedProducts.some(
      (p) => p.oem === oem && p.product === product
    );

    if (isSelected) {
      const updatedProducts = selectedProducts.filter(
        (p) => !(p.oem === oem && p.product === product)
      );
      setSelectedProducts(updatedProducts);
    } else {
      setSelectedProducts([...selectedProducts, productObj]);
    }
  };

  const handleGetDetails = async () => {
    if (
      selectedProducts.length === 0 ||
      !email ||
      // !selectedCustomerId ||
      !jsonData ||
      !selectedoem ||
      !selectedProduct ||
      !planType
    ) {
      // window.alert(
      //   "Please select a customer, OEM, product, and ensure email is valid."
      // );
      window.alert("Please select a OEM, product and plantype.");
      return;
    }

    const selectedProductDetails = selectedProducts.map(
      ({ oem, product, price }) => ({
        oem,
        product,
        price,
      })
    );

    if (selectedProductDetails.length === 0) {
      window.alert("Please select at least one product.");
      return;
    }

    setShowModal(true);
    setSelectedProductsModal(selectedProductDetails);
  };

  const handleoemSelect = (event) => {
    const selectedoem = event.target.value;
    setSelectedoem(selectedoem);
    setSelectedProduct(""); // Reset product selection when company changes
  };

  const handleProductSelect = (event) => {
    const selectedProduct = event.target.value;
    setSelectedProduct(selectedProduct);
  };
  const handleQuotationForChange = (event) => {
    setSelectedQuotationFor(event.target.value);
  };

  const handleUserCountChange = (event, oem, product) => {
    const { value } = event.target;
    setUserCounts({
      ...userCounts,
      [`${oem}-${product}`]: value, // Update the user count for the specific product
    });
  };

  const handlePlanTypeChange = (event) => {
    setPlanType(event.target.value);
    setPlanDuration({}); // Reset plan duration when plan type changes
  };

  const handlePlanDurationChange = (event, oem, product) => {
    const { value } = event.target;
    setPlanDuration({
      ...planDuration,
      [`${oem}-${product}`]: value,
    });
  };

  const handleGetEstimation = async () => {
    if (!email || !planType || !selectedProductsModal.length) {
      window.alert("Please fill in all required fields.");
      return;
    }

    // Check if planDuration and userCount are entered for all selected products
    const missingDataProducts = selectedProductsModal.filter(
      ({ oem, product }) => {
        return !(
          planDuration[`${oem}-${product}`] && userCounts[`${oem}-${product}`]
        );
      }
    );

    if (missingDataProducts.length > 0) {
      window.alert(
        "Please enter plan duration and user count for all selected products."
      );
      return;
    }
    // Validate plan duration consistency
    if (!arePlanDurationsConsistent()) {
      window.alert(
        "Plan durations must be the same for all selected products."
      );
      return;
    }
    const loginDataFromStorage = localStorage.getItem("userDetails");
    if (loginDataFromStorage) {
      const parsedLoginData = JSON.parse(loginDataFromStorage);
      const email = parsedLoginData.reseller_email;
    }
    // Calculate subtotal and total for each product
    const productsWithSubtotal = selectedProductsModal.map(
      ({ oem, product, price }) => {
        const usercount = userCounts[`${oem}-${product}`] || 0;
        const duration = planDuration[`${oem}-${product}`] || 0;
        const subtotal =
          price * usercount * duration * (planType === "yearly" ? 12 : 1);
        return {
          oem,
          product,
          price,
          usercount,
          duration,
          subtotal: subtotal.toFixed(2),
        };
      }
    );
    // Calculate subtotal and total for each product
    const productsWithShivaamiSubtotal = selectedProductsModal.map(
      ({ oem, product, price }) => {
        const usercount = userCounts[`${oem}-${product}`] || 0;
        const duration = planDuration[`${oem}-${product}`] || 0;
        const shivaamiprice = (price - (price * discount) / 100).toFixed(2);
        const shivaamisubtotal =
          shivaamiprice *
          usercount *
          duration *
          (planType === "yearly" ? 12 : 1);
        const subtotal =
          price * usercount * duration * (planType === "yearly" ? 12 : 1);
        return {
          oem,
          product,
          price,
          shivaamiprice,
          usercount,
          duration,
          shivaamisubtotal: shivaamisubtotal.toFixed(2),
          subtotal: subtotal.toFixed(2),
        };
      }
    );

    // Calculate total excluding GST
    const totalExclGST = productsWithSubtotal.reduce(
      (total, { subtotal }) => total + parseFloat(subtotal),
      0
    );
    const custTotalInclGST = totalExclGST * 1.18;
    // // Calculate discount amount
    // const discountAmount = (totalExclGST * discount) / 100;
    // // Calculate discounted price
    // const discountedPrice = totalExclGST - discountAmount;

    // // Calculate total including GST (at 18%)
    // Calculate total excluding GST
    const totalShivaamiExclGST = productsWithShivaamiSubtotal.reduce(
      (total, { shivaamisubtotal }) => total + parseFloat(shivaamisubtotal),
      0
    );
    const totalInclGST = totalShivaamiExclGST * 1.18;
    const selectedCustomer = document.getElementById("customerEmail").value;

    // Include selected customer email and domain in estimationData
    const [selectedCustomerEmail, selectedCustomerDomain] =
      selectedCustomer.split("-");

    const estimationData = {
      Plantype: planType,
      Total: totalShivaamiExclGST.toFixed(2),
      TotalcustinclGST: custTotalInclGST.toFixed(2),
      "Total (incl. GST 18%)": totalInclGST.toFixed(2),
      Discount: `${discount}%`,
      // "Discount Amount": discountAmount.toFixed(2),
      // "Discounted Price": discountedPrice.toFixed(2),
      "GST Amount": (totalInclGST - totalShivaamiExclGST).toFixed(2),
      products: productsWithShivaamiSubtotal,
      reseller_email: email,
      customerterms: customerTerms,
      customer_email: selectedCustomerEmail,
      customer_domain: selectedCustomerDomain,
      quot_for: selectedQuotationFor,
      reseller_type: parsedLoginData.reseller_type,
    };
    setLoading(true);
    try {
      const response = await sendQuotationNew(estimationData);
      if (response.status === 200) {
        // window.alert("Products details is missmatched with case details");
        const data = await response.json();
        window.alert(data.message);
        setShowModal(false);
        setSelectedProductsModal([]);
        setUserCounts({});
        setPlanDuration({});
        setPlanType("");
        setComment("");
        resetCalculator();
        setShowResellerTermsModal(false);
        window.location.href = "/usermaincomponent";

        // window.alert("Products details is missmatched with case details");
      } else {
        // window.alert("Products details is missmatched with case details");
        const data = await response.json();
        window.alert(data.message);
      }
    } catch (error) {
      window.alert("Failed to request quotation. Please try again later.");
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  const resetCalculator = () => {
    setSelectedProducts([]);
    setCustomerList([]);
    setSelectedCustomerId("");
    setSelectedoem("");
    setSelectedProduct("");
    setPlanType("");
    setPlanDuration({});
    setComment("");
    setCustomerTerms(""); // Reset customer terms
  };
  const arePlanDurationsConsistent = () => {
    const durations = Object.values(planDuration);
    if (durations.length === 0) return true; // No durations entered yet

    // Check if all durations are equal to the first duration entered
    return durations.every((duration) => duration === durations[0]);
  };
  const handleOKButtonClick = async () => {
    if (
      !email ||
      !planType ||
      !selectedProductsModal.length ||
      !selectedQuotationFor
    ) {
      window.alert("Please fill in all required fields.");
      return;
    }
    if (!selectedCustomerId) {
      window.alert("Please Select Customer");
      return;
    }
    // Check if planDuration and userCount are entered for all selected products
    const missingDataProducts = selectedProductsModal.filter(
      ({ oem, product }) => {
        return !(
          planDuration[`${oem}-${product}`] && userCounts[`${oem}-${product}`]
        );
      }
    );

    if (missingDataProducts.length > 0) {
      window.alert(
        "Please enter plan duration and user count for all selected products."
      );
      return;
    }

    // Validate plan duration consistency
    if (!arePlanDurationsConsistent()) {
      window.alert(
        "Plan durations must be the same for all selected products."
      );
      return;
    }
    if (!customerTerms) {
      window.alert("Please accept terms before proceeding.");
      return;
    }
    setShowResellerTermsModal(true);
  };
  const modalHighlightStyle = {
    animation: showResellerTermsModal ? "modalHighlight 2s infinite" : "none",
    boxShadow: showResellerTermsModal
      ? "0 0 20px 10px rgba(255, 255, 0, 0.7)"
      : "none",
  };
  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body" style={{ height: "600px" }}>
            <h4 className="mb-1">Products Price Calculator</h4>
            <br></br>
            {/* <p style={{ color: "red" }}>
              Note:- If you want to buy product directly dont need to choose
              below values directly click on "Buy Products"
            </p> */}
            {/* <br /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center", // Center the dropdowns horizontally
                gap: "20px", // Custom margin spacing between dropdowns
              }}
            >
              <select
                id="vendorName"
                value={selectedoem}
                onChange={handleoemSelect}
                className="form-control"
                style={{
                  maxHeight: "150px", // Limit dropdown height
                  overflowY: "auto", // Enable vertical scrolling
                  width: "300px", // Customize the width as per your requirement
                }}
              >
                <option value="">OEM Name</option>
                {jsonData &&
                  Object.keys(jsonData)
                    .filter((company) => company !== "current_dollar_price")
                    .map((company) => (
                      <option key={company} value={company}>
                        {company}
                      </option>
                    ))}
              </select>
              <select
                id="productName"
                className="form-control"
                value={selectedProduct}
                onChange={handleProductSelect}
                style={{
                  maxHeight: "150px", // Limit dropdown height
                  overflowY: "auto", // Enable vertical scrolling
                  width: "300px", // Customize the width as per your requirement
                }}
              >
                <option value="">Products Name</option>
                {selectedoem &&
                  jsonData[selectedoem] &&
                  Object.keys(jsonData[selectedoem]).map((product) => (
                    <option key={product} value={product}>
                      {product}
                    </option>
                  ))}
              </select>
              <select
                id="planType"
                className="form-control"
                value={planType}
                onChange={handlePlanTypeChange}
                style={{
                  maxHeight: "150px",
                  overflowY: "auto",
                  width: "300px", // Customize the width as per your requirement
                }}
              >
                <option value="">Plan Type</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <br></br>
            <div
              className="scroll-container"
              style={{
                overflowY: "auto",
                height: "350px",
                width: "100%",
                border: "1px solid #000",
                padding: "10px",
              }}
            >
              <div
                className="skus"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "20px",
                  width: "80%",
                  margin: "auto", // Center the .skus container
                }}
              >
                {jsonData &&
                  selectedoem &&
                  selectedProduct &&
                  jsonData[selectedoem] &&
                  jsonData[selectedoem][selectedProduct] &&
                  Object.keys(jsonData[selectedoem][selectedProduct]).map(
                    (subproductKey, index) => {
                      const subproductData =
                        jsonData[selectedoem][selectedProduct][subproductKey];
                      const isSelected = selectedProducts.some(
                        (p) =>
                          p.oem === selectedoem && p.product === subproductKey
                      );

                      // Ensure subproductData[1] exists and is the correct way to access price
                      const price = subproductData ? subproductData[1] : "N/A";
                      // Define custom styles for the tooltip
                      const customTooltipStyle = {
                        maxWidth: "1000px",
                        maxHeight: "100px",
                        overflow: "auto",
                      };

                      const tooltipContent = (
                        <Tooltip
                          id={`tooltip-${index}`}
                          style={customTooltipStyle}
                          show={isTooltipHovered}
                        >
                          <strong>Price:</strong> {price}
                        </Tooltip>
                      );

                      return (
                        <OverlayTrigger
                          key={index}
                          placement="bottom"
                          overlay={tooltipContent}
                          delay={{ show: 250, hide: 400 }}
                          trigger={["hover", "focus"]}
                        >
                          <div
                            className="sku"
                            style={{
                              border: `3px solid ${
                                isSelected ? "green" : "#000"
                              }`,
                              padding: "10px",
                              textAlign: "center",
                              cursor: "pointer",
                              minWidth: "100px",
                              gap: "5px",
                              width: `${Math.max(
                                100,
                                50 + 10 * subproductKey.length
                              )}px`,
                              color: isSelected ? "green" : "inherit",
                            }}
                            onClick={() =>
                              handleAddProduct(
                                selectedoem,
                                subproductKey,
                                price
                              )
                            } // Pass price to handleAddProduct
                            onMouseEnter={() => handleTooltipHover(true)}
                            onMouseLeave={() => handleTooltipHover(false)}
                          >
                            {subproductKey}
                          </div>
                        </OverlayTrigger>
                      );
                    }
                  )}
              </div>
            </div>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn btn-primary submit-btn btn-block"
                type="submit"
                style={{ width: "100px", margin: "0 5px" }}
                onClick={handleGetDetails}
              >
                Get Price
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          resetCalculator(); // Reset selectedProductsModal when modal is closed
        }}
        size="lg"
        centered
        style={{
          maxWidth: "90%", // Responsive width
          margin: "100px", // Center horizontally
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "-34px",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Selected Plan Type:{" "}
            <span style={{ color: "green" }}>{planType}</span>
            {/* <span style={{ color: "red", marginLeft: "30px" }}>
              Note: Current Dollar price is "$1" = "₹
              {currentDollarPrice}"
            </span> */}
            {"      "} Partner Discount:{" "}
            <span style={{ color: "green" }}>{discount}%</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: "350px", overflowY: "scroll" }}>
          <table className="table">
            <thead>
              <tr>
                <th>OEM Name</th>
                <th>SKU's Name</th>
                <th>Price(INR)</th>
                <th>Shiviom Price(INR)</th>
                <th>User Count</th>
                <th>Plan Duration</th>
                {/* <th>Subtotal</th> */}
                <th>Subtotal</th>
                {parsedLoginData.reseller_type === "Indirect" && (
                  <th>Commission Price</th>
                )}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {selectedProductsModal.map(({ oem, product, price }) => {
                const userCount = userCounts[`${oem}-${product}`] || "";
                const duration = planDuration[`${oem}-${product}`] || "";
                const isYearly = planType === "yearly";
                const months = isYearly ? 12 : 1;
                // const actualprice = price - (price * discount) / 100;
                const subTotal = price * userCount * duration * months;
                const shivaamiprice = (
                  price -
                  (price * discount) / 100
                ).toFixed(2);
                const shivaamisubTotal =
                  shivaamiprice * userCount * duration * months;

                // Commission calculation (adjust as needed)
                // const commissionRate = 0.1; // Example commission rate
                const commissionPrice =
                  (shivaamiprice * userCount * discount) / 100;

                const handleRemoveProduct = (oem, product) => {
                  const updatedProducts = selectedProducts.filter(
                    (p) => !(p.oem === oem && p.product === product)
                  );
                  setSelectedProducts(updatedProducts);

                  // Deselect the product in the scroll container
                  const updatedSelectedProducts = selectedProductsModal.filter(
                    (p) => !(p.oem === oem && p.product === product)
                  );
                  setSelectedProductsModal(updatedSelectedProducts);

                  toast.error(
                    `Removed ${oem} - ${product} from selected products`
                  );
                };

                return (
                  <tr key={`${oem}-${product}`}>
                    <td>{oem}</td>
                    <td>{product}</td>
                    <td>₹{price.toLocaleString()}</td>
                    <td>₹{shivaamiprice.toLocaleString()}</td>
                    <td>
                      <input
                        type="number"
                        value={userCount}
                        min="0"
                        onChange={(e) => handleUserCountChange(e, oem, product)}
                        className="form-control"
                        placeholder="Enter User Count"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={duration}
                        min="1"
                        onChange={(e) =>
                          handlePlanDurationChange(e, oem, product)
                        }
                        className="form-control"
                        placeholder="Enter Plan Duration"
                      />
                    </td>
                    {/* <td>{subTotal.toFixed(2)}</td> */}
                    <td>{shivaamisubTotal.toFixed(2)}</td>
                    {parsedLoginData.reseller_type === "Indirect" && (
                      <td>{commissionPrice.toFixed(2)}</td>
                    )}
                    <td>
                      <span
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleRemoveProduct(oem, product)}
                        title="Remove Product"
                      >
                        ✘
                      </span>
                    </td>
                  </tr>
                );
              })}
              {(() => {
                const total = selectedProductsModal.reduce(
                  (total, { price, oem, product }) =>
                    total +
                    (price - (price * discount) / 100) *
                      (userCounts[`${oem}-${product}`] || 0) *
                      (planDuration[`${oem}-${product}`] || 0) *
                      (planType === "yearly" ? 12 : 1),
                  0
                );

                // const discountAmount = (total * discount) / 100;
                // const discountedPrice = total - discountAmount;
                const gstTotal = total * 1.18;

                const totalCommission = selectedProductsModal.reduce(
                  (total, { oem, product, price }) => {
                    const userCount = userCounts[`${oem}-${product}`] || 0;
                    const duration = planDuration[`${oem}-${product}`] || 0;
                    const isYearly = planType === "yearly";
                    const months = isYearly ? 12 : 1;
                    // const subTotal = price * userCount * duration * months;
                    const shivaamiprice = price - (price * discount) / 100;
                    const subTotal =
                      shivaamiprice * userCount * duration * months;
                    const commissionPrice = (subTotal * discount) / 100;
                    return commissionPrice;
                  },
                  0
                );

                return (
                  <>
                    <tr>
                      <td
                        colSpan={
                          parsedLoginData.reseller_type === "Indirect"
                            ? "6"
                            : "6"
                        }
                        style={{ textAlign: "right" }}
                      >
                        Total
                      </td>
                      <td>{total.toFixed(2)}</td>
                    </tr>
                    {/* {parsedLoginData.reseller_type === "Direct" && (
                      <>
                        <tr>
                          <td colSpan="5" style={{ textAlign: "right" }}>
                            Your Discount
                          </td>
                          <td>{discount}%</td>
                        </tr>
                        <tr>
                          <td colSpan="5" style={{ textAlign: "right" }}>
                            Discount Amount
                          </td>
                          <td>{discountAmount.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td colSpan="5" style={{ textAlign: "right" }}>
                            Discounted Price
                          </td>
                          <td>{discountedPrice.toFixed(2)}</td>
                        </tr>
                      </>
                    )} */}
                    <tr>
                      <td
                        colSpan={
                          parsedLoginData.reseller_type === "Direct" ? "6" : "6"
                        }
                        style={{ textAlign: "right" }}
                      >
                        Total (incl. GST 18%)
                      </td>
                      <td>{gstTotal.toFixed(2)}</td>
                    </tr>
                    {parsedLoginData.reseller_type === "Indirect" && (
                      <>
                        <tr>
                          <td colSpan="7" style={{ textAlign: "right" }}>
                            Total Commission
                          </td>
                          <td>{totalCommission.toFixed(2)}</td>
                        </tr>
                      </>
                    )}
                  </>
                );
              })()}
            </tbody>
          </table>
        </Modal.Body>
        <p style={{ color: "red" }}>
          Note:- Here "Shiviom Price" means partner discounted price.
        </p>
        <Modal.Footer style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "20px" }}>
            <label style={{ marginRight: "10px" }}>
              <strong>Select Customer Name</strong>
            </label>
            <span className="text-danger">*</span>
            <br></br>
            <select
              className="form-control"
              id="customerEmail"
              onChange={(e) => setSelectedCustomerId(e.target.value)}
              style={{
                width: "250px", // Adjust width as needed
              }}
            >
              <option value="">Select Customer</option>
              {customerList.map((customer) => (
                <option
                  key={customer.cust_id}
                  value={`${customer.customer_emailid}-${customer.customer_domainname}`}
                >
                  {customer.customer_name}-{customer.customer_domainname}
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginRight: "20px" }}>
            <label style={{ marginRight: "10px" }}>
              <strong>Send Quotation For:</strong>
            </label>
            <span className="text-danger">*</span>
            <br></br>
            <select
              className="form-control"
              value={selectedQuotationFor}
              onChange={handleQuotationForChange}
              style={{
                width: "150px", // Adjust width as needed
              }}
            >
              <option value="">Select</option>
              <option value="Both">Both</option>
              <option value="Reseller">Own</option>
              <option value="Customer">Customer</option>
            </select>
          </div>
          <div style={{ marginRight: "40px" }}>
            <label style={{ marginRight: "40px" }}>
              <strong>Customer Terms & Conditions:</strong>
            </label>
            <br></br>
            <textarea
              className="form-control"
              value={customerTerms}
              onChange={(e) => setCustomerTerms(e.target.value)}
              rows="3"
              cols="50"
              placeholder="Enter your terms and conditions for customers here..."
              style={{
                minWidth: "250px", // Adjust width as needed
              }}
            />
          </div>
          <Button
            variant="primary"
            onClick={handleOKButtonClick}
            style={{ marginLeft: "auto" }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showResellerTermsModal}
        onHide={() => setShowResellerTermsModal(false)}
        size="m"
        centered
        style={modalHighlightStyle}
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Reseller Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          {/* Display fetched reseller terms */}
          {loading ? (
            <p>Loading...</p>
          ) : (
            resellerTerms
              .split("\n")
              .map((term, index) => <p key={index}>{term}</p>)
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleGetEstimation}>
            Send Quotation
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ProductPriceCalculator;
