import React, { Fragment, useState, useEffect } from "react";
import {
  getCustomerlist,
  updateCustomerDetails,
  deleteCustomerDetails,
  importCustomerData,
  exportCustomerData,
} from "../../utils/api"; // Assuming you have an API function for deleting customers
import { Modal, Button, Spinner } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedDomain, setUpdatedDomain] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedPhone, setUpdatedPhone] = useState("");
  const [updatedcompany, setUpdatedCompany] = useState("");
  const [updatedAddredd, setUpdatedAddress] = useState("");
  const [updatedindustryType, setUpdatedIndustryType] = useState("");
  const [updatedPancardNo, setUpdatedPancardNo] = useState("");
  const [updatedGSTNo, setUpdatedGSTNo] = useState("");
  const [updatedCustomerDesignation, setUpdatedCustomerDesignation] =
    useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [panNumberError, setPanNumberError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [pancardFile, setPancardFile] = useState(null);
  const [gstFile, setGstFile] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [customerPriority, setCustomerPriority] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [updatedindustryType, setIndustryType] = useState("");
  const [custId, setCustID] = useState("");

  // List of columns to export
  const columns = [
    { value: "customer_name", label: "Customer Name" },
    { value: "customer_company_name", label: "Customer Company Name" },
    { value: "customer_contact_number", label: "Customer Contact Number" },
    { value: "customer_emailid", label: "Customer EmailID" },
    { value: "customer_domainname", label: "Customer Domain Name" },
    { value: "customer_address", label: "Customer Address" },
    { value: "customer_pancard_number", label: "Customer Pancard Number" },
    { value: "gst_number", label: "Customer GST Number" },
    { value: "priority", label: "Priority" },
    { value: "industry_type", label: "Industry Type" },
    { value: "customer_designation", label: "Customer Designation" },
    // Add more columns as needed
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const loginDataFromStorage = localStorage.getItem("userDetails");
        if (loginDataFromStorage) {
          const parsedLoginData = JSON.parse(loginDataFromStorage);
          setEmail(parsedLoginData.reseller_email);
          const data = await getCustomerlist(parsedLoginData.reseller_email);
          setCustomers(data);
          setInitialUserList(data);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleViewPANCard = () => {
    if (pancardFile) {
      window.open(pancardFile, "_blank");
    } else {
      window.alert("No PAN card file available to view.");
    }
  };

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = initialUserList.filter((user) => {
      if (user) {
        return (
          (user.customer_domainname &&
            user.customer_domainname.toLowerCase().includes(value)) ||
          (user.customer_name &&
            user.customer_name.toLowerCase().includes(value)) ||
          (user.customer_emailid &&
            user.customer_emailid.toLowerCase().includes(value)) ||
          (user.customer_contact_number &&
            user.customer_contact_number.toLowerCase().includes(value))
        );
      }
      return false; // Skip this user if domain_name is undefined
    });
    setCustomers(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setCustomers(initialUserList);
  };

  const handleSort = (column) => {
    let sortedList = [...customers];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setCustomers(sortedList);
  };

  const handlePancardFileChange = (e) => {
    setPancardFile(e.target.files[0]);
  };
  const handleEdit = (custId) => {
    const selected = customers.find((user) => user.cust_id === custId);
    setShowViewModal(false);
    setSelectedCustomer(selected);
    setCustID(selected.cust_id);
    setUpdatedName(selected.customer_name);
    setUpdatedDomain(selected.customer_domainname);
    setUpdatedEmail(selected.customer_emailid);
    setUpdatedPhone(selected.customer_contact_number);
    setUpdatedAddress(selected.customer_address);
    setUpdatedCompany(selected.customer_company_name);
    setUpdatedPancardNo(selected.customer_pancard_number);
    setUpdatedGSTNo(selected.gst_number);
    setCustomerPriority(selected.priority);
    setUpdatedIndustryType(selected.industry_type);
    setPancardFile(selected.documents?.panCard || null);
    setUpdatedCustomerDesignation(selected.customer_designation);
    setShowModal(true);
  };
  const handleView = (custId) => {
    const selected = customers.find((user) => user.cust_id === custId);
    setCustID(selected.cust_id);
    setSelectedCustomer(selected);
    setUpdatedName(selected.customer_name);
    setUpdatedDomain(selected.customer_domainname);
    setUpdatedEmail(selected.customer_emailid);
    setUpdatedPhone(selected.customer_contact_number);
    setUpdatedAddress(selected.customer_address);
    setUpdatedCompany(selected.customer_company_name);
    setUpdatedPancardNo(selected.customer_pancard_number);
    setUpdatedGSTNo(selected.gst_number);
    setCustomerPriority(selected.priority);
    setUpdatedIndustryType(selected.industry_type);
    setPancardFile(selected.documents?.panCard || null);
    setUpdatedCustomerDesignation(selected.customer_designation);
    setShowViewModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setUpdatedName(value);
        break;
      case "domain":
        setUpdatedDomain(value);
        break;
      case "email":
        setUpdatedEmail(value);
        break;
      case "phone":
        setUpdatedPhone(value);
        break;
      case "custcompany":
        setUpdatedCompany(value);
        break;
      case "address":
        setUpdatedAddress(value);
        break;
      case "pancardno":
        setUpdatedPancardNo(value);
        break;
      case "gstnumber":
        setUpdatedGSTNo(value);
        break;
      case "industrytype":
        setUpdatedIndustryType(value);
        break;
      case "customer_designation":
        setUpdatedCustomerDesignation(value);
        break;
      default:
        break;
    }
  };

  const handleUpdateCustomer = async () => {
    try {
      setLoading(true);
      if (
        !updatedName ||
        !updatedDomain ||
        !updatedEmail ||
        !updatedPhone ||
        !updatedAddredd ||
        !updatedPancardNo ||
        !updatedGSTNo ||
        !updatedcompany ||
        !customerPriority ||
        !updatedindustryType ||
        !updatedCustomerDesignation
      ) {
        window.alert("Please fill in all required fields.");
        return;
      }

      const updateResult = await updateCustomerDetails(
        selectedCustomer.cust_id,
        updatedName,
        updatedDomain,
        updatedEmail,
        updatedPhone,
        updatedAddredd,
        updatedPancardNo,
        updatedGSTNo,
        updatedcompany,
        email,
        pancardFile,
        customerPriority,
        updatedindustryType,
        updatedCustomerDesignation
        // gstFile
      );
      if (updateResult.status === 200) {
        window.alert("Customer details updated successfully!");
        setShowModal(false);
        // Call the API again to fetch the updated list of customers
        const updatedList = await getCustomerlist(email); // Assuming email is used to fetch the customer list
        setCustomers(updatedList);
        setInitialUserList(updatedList);
      } else {
        window.alert("Error updating customer details. Please try again.");
      }
    } catch (error) {
      console.error("Error updating customer details:", error.message);
      window.alert("An error occurred while updating customer details.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (custId) => {
    setShowModal(false);
    // Ask for confirmation
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this customer?"
    );
    if (!isConfirmed) {
      return; // Exit the function if the user cancels
    }

    try {
      setLoading(true);
      const deleteResult = await deleteCustomerDetails(custId);

      if (deleteResult.status === 200) {
        window.alert("Customer deleted successfully!");
        setShowViewModal(false);
        const updatedList = customers.filter((user) => user.cust_id !== custId);
        setCustomers(updatedList);
      } else {
        window.alert("Error deleting customer. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting customer:", error.message);
      window.alert("An error occurred while deleting customer.");
    } finally {
      setLoading(false);
    }
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const validateEmail = (email) => {
    // Email regex pattern for basic validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateContactNumber = (contactNumber) => {
    // Contact number regex pattern for basic validation
    const contactNumberPattern = /^[0-9]{10}$/;
    return contactNumberPattern.test(contactNumber);
  };

  const validatePANNumber = (panNumber) => {
    // PAN card number regex pattern for basic validation
    const panNumberPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    return panNumberPattern.test(panNumber);
  };
  const validateGSTNumber = (gstNumber) => {
    // GST number regex pattern for basic validation
    const gstNumberPattern =
      /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/;
    return gstNumberPattern.test(gstNumber);
  };

  // Handle input changes and validation
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setUpdatedEmail(emailValue);
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };
  const handleContactNumberChange = (e) => {
    const contactNumberValue = e.target.value;
    setUpdatedPhone(contactNumberValue);
    setContactNumberError(
      validateContactNumber(contactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };
  const handlePANNumberChange = (e) => {
    const panNumberValue = e.target.value.toUpperCase();
    setUpdatedPancardNo(panNumberValue);
    setPanNumberError(
      validatePANNumber(panNumberValue) ? "" : "Invalid PAN card number format"
    );
  };

  const handleGSTNumberChange = (e) => {
    const gstNumberValue = e.target.value.toUpperCase();
    setUpdatedGSTNo(gstNumberValue);
    setGstNumberError(
      validateGSTNumber(gstNumberValue) ? "" : "Invalid GST number format"
    );
  };
  const handleCloseModal = () => {
    setShowModal(false);
    // Reset all fields to their initial state
    setUpdatedName("");
    setUpdatedDomain("");
    setUpdatedEmail("");
    setUpdatedPhone("");
    setUpdatedCompany("");
    setUpdatedAddress("");
    setUpdatedPancardNo("");
    setUpdatedGSTNo("");
    setEmailError("");
    setPhoneError("");
    setContactNumberError("");
    setPanNumberError("");
    setGstNumberError("");
    setPancardFile(null);
    setUpdatedCustomerDesignation("");
    setGstFile(null);
  };
  const handleCloseViewModal = () => {
    setShowViewModal(false);
    // Reset all fields to their initial state
    setUpdatedName("");
    setUpdatedDomain("");
    setUpdatedEmail("");
    setUpdatedPhone("");
    setUpdatedCompany("");
    setUpdatedAddress("");
    setUpdatedPancardNo("");
    setUpdatedGSTNo("");
    setEmailError("");
    setPhoneError("");
    setContactNumberError("");
    setPanNumberError("");
    setGstNumberError("");
    setPancardFile(null);
    setGstFile(null);
    setUpdatedCustomerDesignation("");
  };
  const handleOpenImportModal = () => {
    setShowImportModal(true);
  };
  const handleOpenExportModal = () => {
    setShowExportModal(true);
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
  };
  const handleCloseExportModal = () => {
    setSelectedColumns([]);
    setShowExportModal(false);
  };

  const handleFileDownload = () => {
    // Create a temporary anchor element
    const downloadLink = document.createElement("a");
    // Set the href attribute to the URL of the file to be downloaded
    downloadLink.href = "/customerdata-template.xlsx"; // Replace with the actual file path
    // Set the download attribute to specify the file name when downloaded
    downloadLink.download = "customerdata-template.xlsx"; // Replace with the desired file name

    // Append the anchor element to the body
    document.body.appendChild(downloadLink);

    // Trigger a click event on the anchor element to initiate the download
    downloadLink.click();

    // Remove the anchor element from the body after the download
    document.body.removeChild(downloadLink);
  };
  const handleFileUpload = async () => {
    const fileInput = document.getElementById("fileInput"); // Get the file input element
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      window.alert("Please upload the file first.");
      return; // Exit function if no file is selected
    }
    setUploading(true); // Show loader when upload starts
    const file = fileInput.files[0]; // Get the uploaded file

    try {
      const response = await importCustomerData(file, email);
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        const newCustomers = await getCustomerlist(email);
        setCustomers(newCustomers);
        setInitialUserList(newCustomers);
        setShowImportModal(false);
      } else {
        const data = await response.json();
        window.alert(data.message);
        setUploading(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      window.alert("An error occurred while uploading the file.");
    } finally {
      setUploading(false); // Hide loader after upload completes (whether success or failure)
    }
  };
  const handleColumnChange = (columnValue) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [columnValue]: !prev[columnValue],
    }));
  };
  const handleSelectAll = (isChecked) => {
    const updatedSelection = {};
    columns.forEach((column) => {
      updatedSelection[column.value] = isChecked;
    });
    setSelectedColumns(updatedSelection);
  };
  const handleExportFile = async () => {
    // Check if any columns are selected (i.e., any value is `true` in selectedColumns)
    const isAnyColumnSelected = Object.values(selectedColumns).some(
      (isSelected) => isSelected
    );

    if (!isAnyColumnSelected) {
      window.alert("Please select at least one column to export.");
      return; // Stop the export process if no columns are selected
    }

    // Start the loading process
    setUploading(true);

    // Log or print the selected columns and dates
    console.log("Selected Columns:", selectedColumns);
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    // Prepare the JSON payload with selected columns, start date, and end date
    // const payload = {
    //   selectedColumns: Object.keys(selectedColumns).filter((col) => selectedColumns[col]), // Only send the selected columns
    //   startDate: startDate,
    //   endDate: endDate,
    // };
    try {
      const response = await exportCustomerData(
        selectedColumns,
        startDate,
        endDate
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.json();
        const convertToCSV = (data) => {
          const headers = Object.keys(data[0]).join(","); // Get the headers
          const rows = data.map((item) => Object.values(item).join(",")); // Get the values
          return [headers, ...rows].join("\n"); // Combine headers and rows
        };

        const downloadCSV = (data) => {
          const csv = convertToCSV(data);
          const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.setAttribute("download", "customer_data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        downloadCSV(data.data);
        // window.alert(data.message);
        const newCustomers = await getCustomerlist(email);
        setCustomers(newCustomers);
        setInitialUserList(newCustomers);
        setShowImportModal(false);
        setSelectedColumns([]);
        setStartDate(null);
        setEndDate(null);
        setUploading(false); // End the loading process
        handleCloseExportModal(); // Close the modal after export
      } else {
        const data = await response.json();
        window.alert(data.message);
        setUploading(false);
      }
    } catch (error) {
      window.alert("An error occurred while exporting the file.");
    } finally {
      setUploading(false); // Hide loader after upload completes (whether success or failure)
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Customers List</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left", width: "250px" }} // Specify width here
              />
              <Button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </Button>
              {/* <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "right" }}
              >
                Register New Customer
              </button> */}
              <Button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleOpenImportModal}
                title="Import Customer Data"
              >
                <CiImport size={15} />
              </Button>
              <Button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleOpenExportModal}
                title="Export Customer Data"
              >
                <CiExport size={15} />
              </Button>
            </div>
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "450px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    {/* <th style={thStyles}>Cust ID</th> */}
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                    >
                      Domain Name
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles}>Custo Email ID</th>
                    <th style={thStyles}>Phnno</th>
                    <th style={thStyles}>Created On</th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {customers.map((user) => (
                    <tr
                      key={user.cust_id}
                      onClick={() => handleView(user.cust_id)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <td>{user.cust_id}</td> */}
                      <td>{user.customer_name}</td>
                      <td>{user.customer_domainname}</td>
                      <td>{user.customer_emailid}</td>
                      <td>{user.customer_contact_number}</td>
                      <td>{new Date(user.created_on).toLocaleDateString()}</td>
                      {/* <td>{renderActionButtons(user)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showViewModal} onHide={handleCloseViewModal}>
        <Modal.Header>
          <Modal.Title>Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <form>
                <div className="form-group">
                  <label htmlFor="name">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={updatedName}
                    onChange={handleInputChange}
                    name="name"
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="domain">Domain Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="domain"
                    value={updatedDomain}
                    onChange={handleInputChange}
                    name="domain"
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    className={`form-control ${
                      updatedEmail
                        ? emailError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="email"
                    value={updatedEmail}
                    onChange={handleEmailChange}
                    name="email"
                    disabled
                  />
                  {emailError && (
                    <small className="text-danger">{emailError}</small>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="text"
                    className={`form-control ${
                      updatedPhone
                        ? phoneError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="phone"
                    value={updatedPhone}
                    onChange={handleContactNumberChange}
                    name="phone"
                    disabled
                  />
                  {phoneError && (
                    <small className="text-danger">{contactNumberError}</small>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="industrytype">Industry Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="industrytype"
                    value={updatedindustryType}
                    onChange={handleInputChange}
                    name="industrytype"
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <textarea
                    type="text"
                    className="form-control"
                    row="2"
                    id="address"
                    value={updatedAddredd}
                    onChange={handleInputChange}
                    name="address"
                    disabled
                  />
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <form>
                {/* <div className="form-group">
                  <label htmlFor="pancardFile">Update PAN Card File</label>
                  <input
                    type="file"
                    className="form-control"
                    id="pancardFile"
                    accept=".pdf,.jpg,.jpeg,.png"
                    onChange={handlePancardFileChange}
                    disabled={!!pancardFile}
                  />
                </div> */}
                <div className="form-group">
                  <label htmlFor="priority">Customer Priority</label>
                  {/* <select
                    value={customerPriority}
                    onChange={(e) => setCustomerPriority(customerPriority)}
                    style={{ width: "100%" }}
                    className="form-control"
                    required
                    disabled
                  >
                    <option value="">Priority</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select> */}
                  <input
                    type="text"
                    className="form-control"
                    id="custpriority"
                    value={customerPriority}
                    onChange={handleInputChange}
                    name="custpriority"
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="customerdesignation">
                    Customer Designation
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="customerdesignation"
                    value={updatedCustomerDesignation}
                    // onChange={handlePANNumberChange}
                    name="customer_designation"
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="custcompany">Customer Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="custcompany"
                    value={updatedcompany}
                    onChange={handleInputChange}
                    name="custcompany"
                    disabled
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="pancardno">Customer PAN Card Number</label>
                  <input
                    type="text"
                    className={`form-control ${
                      updatedPancardNo
                        ? panNumberError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="pancardno"
                    value={updatedPancardNo}
                    onChange={handlePANNumberChange}
                    name="pancardno"
                    disabled
                  />
                  {panNumberError && (
                    <small className="text-danger">{panNumberError}</small>
                  )}
                </div> */}
                {/* <div className="form-group">
                  <label htmlFor="gstnumber">Customer GST Number</label>
                  <input
                    type="text"
                    className={`form-control ${
                      updatedGSTNo
                        ? gstNumberError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="gstnumber"
                    value={updatedGSTNo}
                    onChange={handleGSTNumberChange}
                    name="gstnumber"
                    disabled
                  />
                  {gstNumberError && (
                    <small className="text-danger">{gstNumberError}</small>
                  )}
                </div>
                {pancardFile ? (
                  <Button variant="link" onClick={handleViewPANCard}>
                    View PAN Card
                  </Button>
                ) : (
                  <p>No PAN card file uploaded</p> // Optional: Inform the user that no file has been uploaded
                )} */}
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary ml-2"
            onClick={() => handleDelete(custId)}
          >
            Delete
          </Button>
          <Button
            className="btn btn-primary ml-2"
            onClick={() => handleEdit(custId)}
          >
            Update
          </Button>
          <Button
            className="btn btn-primary ml-2"
            onClick={() => handleCloseViewModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Edit Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <form>
                <div className="form-group">
                  <label htmlFor="name">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={updatedName}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="domain">Domain Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="domain"
                    value={updatedDomain}
                    onChange={handleInputChange}
                    name="domain"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    className={`form-control ${
                      updatedEmail
                        ? emailError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="email"
                    value={updatedEmail}
                    onChange={handleEmailChange}
                    name="email"
                  />
                  {emailError && (
                    <small className="text-danger">{emailError}</small>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="text"
                    className={`form-control ${
                      updatedPhone
                        ? phoneError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="phone"
                    value={updatedPhone}
                    onChange={handleContactNumberChange}
                    name="phone"
                  />
                  {phoneError && (
                    <small className="text-danger">{contactNumberError}</small>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="industrytype">Industry Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="industrytype"
                    value={updatedindustryType}
                    onChange={handleInputChange}
                    name="industrytype"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <textarea
                    type="text"
                    className="form-control"
                    row="2"
                    id="address"
                    value={updatedAddredd}
                    onChange={handleInputChange}
                    name="address"
                  />
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <form>
                {/* <div className="form-group">
                  <label htmlFor="pancardFile">Update PAN Card File</label>
                  <input
                    type="file"
                    className="form-control"
                    id="pancardFile"
                    accept=".pdf,.jpg,.jpeg,.png"
                    onChange={handlePancardFileChange}
                  />
                </div> */}
                <div className="form-group">
                  <label htmlFor="priority">Customer Priority</label>
                  <select
                    value={customerPriority}
                    onChange={(e) => setCustomerPriority(e.target.value)}
                    style={{ width: "100%" }}
                    className="form-control"
                    required
                  >
                    <option value="">Priority</option>
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="custcompany">Customer Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="custcompany"
                    value={updatedcompany}
                    onChange={handleInputChange}
                    name="custcompany"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="custcompany">Customer Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    id="custdesignation"
                    value={updatedCustomerDesignation}
                    onChange={handleInputChange}
                    name="custdesignation"
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="pancardno">Customer PAN Card Number</label>
                  <input
                    type="text"
                    className={`form-control ${
                      updatedPancardNo
                        ? panNumberError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="pancardno"
                    value={updatedPancardNo}
                    onChange={handlePANNumberChange}
                    name="pancardno"
                  />
                  {panNumberError && (
                    <small className="text-danger">{panNumberError}</small>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="gstnumber">Customer GST Number</label>
                  <input
                    type="text"
                    className={`form-control ${
                      updatedGSTNo
                        ? gstNumberError
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                    }`}
                    id="gstnumber"
                    value={updatedGSTNo}
                    onChange={handleGSTNumberChange}
                    name="gstnumber"
                  />
                  {gstNumberError && (
                    <small className="text-danger">{gstNumberError}</small>
                  )}
                </div>
                {pancardFile && (
                  <Button variant="link" onClick={handleViewPANCard}>
                    View PAN Card
                  </Button>
                )} */}
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary ml-2"
            onClick={() => handleCloseModal(false)}
          >
            Close
          </Button>
          <Button
            className="btn btn-primary ml-2"
            onClick={handleUpdateCustomer}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showExportModal} onHide={handleCloseExportModal}>
        <Modal.Header closeButton>
          <Modal.Title>Export Data</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <p
            style={{
              fontSize: "1.1rem",
              marginBottom: "20px",
              color: "#343a40",
            }}
          >
            Select the following Columns for Export Data:
          </p>
          <div style={{ marginBottom: "20px" }}>
            <label style={{ fontSize: "1rem", color: "#495057" }}>
              <input
                type="checkbox"
                onChange={(e) => handleSelectAll(e.target.checked)}
                checked={
                  Object.values(selectedColumns).length === columns.length &&
                  Object.values(selectedColumns).every(Boolean)
                }
                style={{
                  marginRight: "10px",
                  transform: "scale(1.2)",
                  cursor: "pointer",
                }}
              />
              Select All
            </label>
          </div>
          {columns.map((column) => (
            <div
              key={column.value}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <label
                style={{
                  fontSize: "1rem",
                  color: "#495057",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={!!selectedColumns[column.value]}
                  onChange={() => handleColumnChange(column.value)}
                  style={{
                    marginRight: "10px",
                    transform: "scale(1.2)",
                    cursor: "pointer",
                  }}
                />
                {column.label}
              </label>
            </div>
          ))}
          {/* <div className="date-filter-container">
            <label
              style={{ display: "block", fontSize: "1rem", color: "#495057" }}
            >
              Start Date:
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              style={{ width: "100%" }}
            />
            <label
              style={{ display: "block", fontSize: "1rem", color: "#495057" }}
            >
              End Date:
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy-MM-dd"
              style={{ width: "100%" }}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer
          style={{ backgroundColor: "#f8f9fa", borderTop: "2px solid #dee2e6" }}
        >
          <Button
            className="btn btn-secondary"
            onClick={handleCloseExportModal}
          >
            Close
          </Button>
          <Button className="btn btn-primary ml-2" onClick={handleExportFile}>
            Export
          </Button>
          {uploading && (
            <div className="loader" style={{ marginLeft: "15px" }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showImportModal} onHide={handleCloseImportModal}>
        <Modal.Header closeButton>
          <Modal.Title>Import Customer Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "red" }}>
            Note:- If you want to upload bulk data then first you need to
            download the file template fill the data in that file and upload it.
          </p>
          <p>Download the file template:</p>
          <i
            class="fa fa-download"
            aria-hidden="true"
            style={{ fontSize: "24px" }}
            onClick={handleFileDownload}
            title="Download"
          ></i>{" "}
          <hr />
          <p>Upload the filled data file:</p>
          <input
            type="file"
            className="form-control"
            id="fileInput"
            // onChange={handleFileUpload}
            accept=".xlsx,.csv"
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={handleCloseImportModal}
          >
            Close
          </Button>
          <Button className="btn btn-primary ml-2" onClick={handleFileUpload}>
            Import
          </Button>
          {uploading && (
            <div className="loader">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          {/* Conditionally render the loader */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CustomerList;
