import API_HOST from "./config";
const sessiontoken = localStorage.getItem("token");
//##################################################### Admin API Function ##############################################

export const Adminlogin = async (email, password) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const response = await fetch(`${API_HOST}/admin_login/`, {
      method: "POST",
      body: formData,
    });
    // console.log(response.json());
    // const data = await response.json();
    // console.log("asdsa", data);
    return response;
  } catch (error) {
    throw new Error("Login failed: " + error.message);
  }
};

export const adminsendOTP = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const response = await fetch(`${API_HOST}/adminsignupotp/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to send OTP " + error.message);
  }
};

export const adminverifyOTP = async (email, otp) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("otp", otp);
    const response = await fetch(`${API_HOST}/adminverifysignupotp/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("OTP Error " + error.message);
  }
};

export const adminsignup = async (
  name,
  phnnumber,
  email,
  password,
  cnfpassword,
  department
) => {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phnno", phnnumber);
    formData.append("password", password);
    formData.append("cnfpassword", cnfpassword);
    formData.append("department", department);
    const response = await fetch(`${API_HOST}/register_admin_user/`, {
      method: "POST",
      body: formData,
    });

    return response;
  } catch (error) {
    throw new Error("Signup failed: " + error.message);
  }
};
export const adminsendOTPforget = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const response = await fetch(`${API_HOST}/admin_forgotpassword/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to send OTP " + error.message);
  }
};
export const adminResetPass = async (
  userIdParam,
  otp,
  newPassword,
  confirmPassword
) => {
  try {
    const formData = new FormData();
    formData.append("id", userIdParam);
    formData.append("otp", otp);
    formData.append("password", newPassword);
    formData.append("repassword", confirmPassword);
    //console.log(name, company, email, password, confirmPassword);
    const response = await fetch(`${API_HOST}/admin_reset_password/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Reset Password failed: " + error.message);
  }
};

export const update_admin_account_details = async (profileInfo) => {
  try {
    const response = await fetch(`${API_HOST}/update_admin_account_details/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: JSON.stringify(profileInfo),
    });
    // const responseData = await response.json();
    return response; // You can handle the response as needed
  } catch (error) {
    console.error("Error sending data to API:", error);
    throw error; // Throw the error for the calling function to handle
  }
};

export async function getRegisteredList(admin_email) {
  try {
    console.log(admin_email);
    const formData = new FormData();
    formData.append("admin_email", admin_email);
    const response = await fetch(`${API_HOST}/get_registered_resellers/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function updateUserStatus(
  resellerId,
  newStatus,
  reason,
  selectedCreditOption
) {
  try {
    const formData = new FormData();
    formData.append("reseller_id", resellerId);
    formData.append("status", newStatus);
    formData.append("reason", reason);
    formData.append("creditoption", selectedCreditOption);
    const response = await fetch(`${API_HOST}/verify_reseller_account/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData, // Pass the form data in the request body
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function getKYCApplicantList(adminemail) {
  try {
    const formData = new FormData();
    formData.append("admin_email", adminemail);
    const response = await fetch(`${API_HOST}/get_kycapplicant_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function add_department_module(selectedOption, inputValue) {
  const formData = new FormData();
  formData.append("type", selectedOption);
  formData.append("name", inputValue);
  try {
    const response = await fetch(`${API_HOST}/add_department_module_record/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData, // Pass the form data in the request body
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function get_logininvitationparameter() {
  try {
    const response = await fetch(`${API_HOST}/get_parameterlogin_invitation/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
    });
    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function send_logininvotation(
  adminName,
  adminEmailID,
  role,
  department,
  modules,
  region
) {
  try {
    // Create JSON object with parameters
    const requestData = {
      adminname: adminName,
      adminemailID: adminEmailID,
      role: role,
      department: department,
      modules: modules,
      region: region,
    };

    // Send request to API endpoint
    const response = await fetch(`${API_HOST}/send_logininvitation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: JSON.stringify(requestData), // Convert JSON object to string
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export const adminloginsendOTP = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const response = await fetch(`${API_HOST}/sendadminloginotp/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to send OTP " + error.message);
  }
};

export const adminloginverifyOTP = async (email, otp) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("otp", otp);
    const response = await fetch(`${API_HOST}/verifyadminloginotp/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("OTP Error " + error.message);
  }
};

export const adminChangePass = async (
  emailid,
  newPassword,
  confirmPassword
) => {
  try {
    const formData = new FormData();
    formData.append("email", emailid);
    formData.append("password", newPassword);
    formData.append("repassword", confirmPassword);
    const response = await fetch(`${API_HOST}/admin_change_password/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Reset Password failed: " + error.message);
  }
};

export async function getAdminlist() {
  try {
    const response = await fetch(`${API_HOST}/get_admin_list/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export const getresellerKYCDetails = async (registration_id) => {
  try {
    const formData = new FormData();
    formData.append("registrationid", registration_id);
    const response = await fetch(`${API_HOST}/get_reseller_kyc_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData, // Pass the form data in the request body
    });

    const data = await response.json();
    if (data.success) {
      return data.data.data_result[0];
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
};

export const verifyKYCDetails = async (jsonData) => {
  try {
    // // Convert formData to JSON string
    const jsondata = JSON.stringify(jsonData);

    // Make the API call
    const response = await fetch(`${API_HOST}/verify_kyc_details/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: jsondata, // Pass the JSON data in the request body
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const updateAdminsDetails = async (jsonData) => {
  try {
    // // Convert formData to JSON string
    const jsondata = JSON.stringify(jsonData);

    // Make the API call
    const response = await fetch(`${API_HOST}/edit_admin_moduleaccess/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: jsondata, // Pass the JSON data in the request body
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export async function getAdminsideCustomerCaseslist(admin_email) {
  try {
    const formData = new FormData();
    formData.append("adminemail", admin_email);
    const response = await fetch(
      `${API_HOST}/get_customer_cases_list_adminside/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export const updateCasestatus = async (
  caseid,
  casestatus,
  reseller_email,
  domain_name,
  reason
) => {
  try {
    const formData = new FormData();
    formData.append("caseid", caseid);
    formData.append("casestatus", casestatus);
    formData.append("reseller_email", reseller_email);
    formData.append("domainname", domain_name);
    formData.append("reason", reason);
    const response = await fetch(`${API_HOST}/update_casestatus/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData, // Pass the form data in the request body
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
};

export const transferCase = async (oldresellerid, newresellerid, caseid) => {
  try {
    const formData = new FormData();
    formData.append("oldresellerid", oldresellerid);
    formData.append("newresellerid", newresellerid);
    formData.append("caseid", caseid);
    const response = await fetch(`${API_HOST}/transfer_caseto_reseller/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData, // Pass the form data in the request body
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
};
export const generateQuotation = async (
  caseId,
  planType,
  planDuration,
  resellerid
) => {
  try {
    const quotationdata = new FormData();
    quotationdata.append("caseid", caseId);
    quotationdata.append("plantype", planType);
    quotationdata.append("planduration", planDuration);
    quotationdata.append("reseller_id", resellerid);
    // Make the API call
    const response = await fetch(`${API_HOST}/generate_quotation/`, {
      method: "POST",
      body: quotationdata,
    });
    // const jsonData = await response.json(); // Parse response body as JSON
    // console.log(jsonData);
    // return jsonData.data;
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

// export const sendQuotation = async (
//   caseId,
//   planType,
//   planDuration,
//   reseller_email
// ) => {
//   try {
//     const quotationdata = new FormData();
//     quotationdata.append("caseid", caseId);
//     quotationdata.append("plantype", planType);
//     quotationdata.append("planduration", planDuration);
//     quotationdata.append("reseller_email", reseller_email);
//     // Make the API call
//     const response = await fetch(`${API_HOST}/send_quotation/`, {
//       method: "POST",
//       body: quotationdata,
//     });
//     // const jsonData = await response.json(); // Parse response body as JSON
//     // console.log(jsonData);
//     // return jsonData.data;
//     return response;
//   } catch (error) {
//     throw new Error("Error Occured: " + error.message);
//   }
// };
export const calculateOfferedPrice = async (editeddata) => {
  try {
    // Make the API call
    const response = await fetch(`${API_HOST}/calculate_discount_price/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(editeddata),
    });
    // const jsonData = await response.json(); // Parse response body as JSON
    // console.log(jsonData);
    // return jsonData.data;
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const sendQuotation = async (
  caseId,
  planType,
  planDuration,
  reseller_email,
  offeredprices,
  products,
  email
) => {
  try {
    const quotationdata = new FormData();
    quotationdata.append("caseid", caseId);
    quotationdata.append("plantype", planType);
    quotationdata.append("planduration", planDuration);
    quotationdata.append("reseller_email", reseller_email);
    quotationdata.append("offeredprices", offeredprices);
    quotationdata.append("products", products);
    quotationdata.append("admin_email", email);
    // Make the API call
    const response = await fetch(`${API_HOST}/send_quotation/`, {
      method: "POST",
      body: quotationdata,
    });
    // const jsonData = await response.json(); // Parse response body as JSON
    // console.log(jsonData);
    // return jsonData.data;
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export async function addProductsMaster(oemname, productname, skuname, price) {
  try {
    const formData = new FormData();
    formData.append("oem_name", oemname);
    formData.append("product_name", productname);
    formData.append("sku_name", skuname);
    formData.append("price", price);
    const response = await fetch(`${API_HOST}/products_addition_master/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to add data in record: " + error.message);
  }
}

export async function removeProductsMaster(parameter, value) {
  try {
    const formData = new FormData();

    formData.append("parameter", parameter);
    formData.append("value", value);
    const response = await fetch(`${API_HOST}/products_remove_master/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to remove product in record: " + error.message);
  }
}
export async function getCustomerQuotationlistAdminside(admin_email) {
  try {
    const formData = new FormData();
    console.log(admin_email);
    formData.append("adminemail", admin_email);
    const response = await fetch(
      `${API_HOST}/get_customer_quotation_list_by_admin/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function getCustomerorderslistforadmin(admin_email) {
  try {
    const formData = new FormData();

    formData.append("adminemail", admin_email);
    const response = await fetch(`${API_HOST}/adminside_get_orders_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function addResellerDiscount(reseller_id, discount_value) {
  try {
    const formData = new FormData();

    formData.append("reseller_id", reseller_id);
    formData.append("discount_value", discount_value);
    const response = await fetch(`${API_HOST}/add_reseller_discount/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to add discount in record: " + error.message);
  }
}
export async function getResellerDiscounts(reseller_id) {
  try {
    const formData = new FormData();

    formData.append("reseller_id", reseller_id);
    const response = await fetch(`${API_HOST}/get_reseller_discount_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to get discount list from record: " + error.message
    );
  }
}

export async function getAccountMgrLists() {
  try {
    const response = await fetch(`${API_HOST}/get_account_mgr_names/`, {
      method: "GET",
      headers: {
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to get account manager lists from record: " + error.message
    );
  }
}
export async function assignAccountMgr(admin_id, reseller_id) {
  try {
    const formData = new FormData();

    formData.append("reseller_id", reseller_id);
    formData.append("admin_id", admin_id);
    const response = await fetch(`${API_HOST}/assign_account_manager/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to assign account manager: " + error.message);
  }
}

export async function getResellersListAccMgr(admin_id) {
  try {
    const formData = new FormData();
    formData.append("admin_id", admin_id);
    const response = await fetch(`${API_HOST}/get_account_manager_resellers/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    console.log(data);
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to get reseller lists from record of account mgr: " +
        error.message
    );
  }
}

export async function updateProductMaster(
  selectedOemValue,
  selectedProductValue,
  editSKU,
  oldPrice,
  newPrice
) {
  try {
    const formData = new FormData();

    formData.append("oem_name", selectedOemValue);
    formData.append("productname", selectedProductValue);
    formData.append("skuname", editSKU);
    formData.append("oldprice", oldPrice);
    formData.append("newprice", newPrice);
    const response = await fetch(`${API_HOST}/edit_product_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to update product details in record: " + error.message
    );
  }
}
export async function getPriceHistory() {
  try {
    const response = await fetch(`${API_HOST}/get_products_price_history/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to get products history from record: " + error.message
    );
  }
}
export async function getResellersCustomersList(admin_email) {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    const response = await fetch(`${API_HOST}/get_resellers_customers_lists/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function get_search_records_values() {
  try {
    const response = await fetch(`${API_HOST}/get_search_values/`, {
      headers: {
        Authorization: sessiontoken,
      },
      method: "GET",
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function get_complete_record(value, parameter) {
  try {
    const formData = new FormData();
    formData.append("parameter", parameter);
    formData.append("value", value);
    const response = await fetch(`${API_HOST}/get_complete_record_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function add_deal_discount(resellerid, caseid, discount) {
  try {
    const formData = new FormData();
    formData.append("reseller_id", resellerid);
    formData.append("caseid", caseid);
    formData.append("discount", discount);
    const response = await fetch(`${API_HOST}/add_dealwise_discount/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function create_reseller_account(
  reselleremail,
  resellercompname,
  resellerphnnumber,
  resellername,
  resellertype
) {
  try {
    const formData = new FormData();
    formData.append("reseller_email", reselleremail);
    if (resellertype === "Indirect") {
      formData.append("reseller_compname", "None");
    } else {
      formData.append("reseller_compname", resellercompname);
    }
    formData.append("reseller_phnnumber", resellerphnnumber);
    formData.append("reseller_name", resellername);
    formData.append("reseller_type", resellertype);
    const response = await fetch(`${API_HOST}/create_reseller_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function getAdminsInvoiceLists(admin_email) {
  try {
    const formData = new FormData();
    formData.append("adminemail", admin_email);
    const response = await fetch(`${API_HOST}/get_adminsideinvoice_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to map data from API: " + error.message);
  }
}
export const importResellerData = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    // Define the API endpoint and the method to update the case
    const response = await fetch(`${API_HOST}/import_reseller_data/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to import the reseller data: " + error.message);
  }
};

export async function addResellerCommision(reseller_id, commision_value) {
  try {
    const formData = new FormData();

    formData.append("reseller_id", reseller_id);
    formData.append("commision_percent", commision_value);
    const response = await fetch(`${API_HOST}/add_commision_percent/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to add commision in record: " + error.message);
  }
}
export async function getResellerCommisions(reseller_id) {
  try {
    const formData = new FormData();

    formData.append("reseller_id", reseller_id);
    const response = await fetch(`${API_HOST}/get_reseller_commision_list/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to get commision record: " + error.message);
  }
}
export async function addResellerVoucher(
  reseller_email,
  voucher_content,
  voucher_type
) {
  try {
    const formData = new FormData();

    formData.append("reseller_email", reseller_email);
    formData.append("voucher_content", voucher_content);
    formData.append("voucher_type", voucher_type);
    const response = await fetch(`${API_HOST}/add_voucher_code/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to add commision in record: " + error.message);
  }
}
export async function getResellerVouchers(reseller_email) {
  try {
    const formData = new FormData();

    formData.append("reseller_email", reseller_email);
    const response = await fetch(`${API_HOST}/get_reseller_voucher_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to get vouchers record: " + error.message);
  }
}
export async function getAddedLicenceDetails() {
  try {
    const response = await fetch(`${API_HOST}/get_licence_added_history/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to get vouchers record: " + error.message);
  }
}

export async function addCaseInterval(interval_value) {
  try {
    const formData = new FormData();

    formData.append("caseremainder_interval", interval_value);
    const response = await fetch(`${API_HOST}/add_caseremainder_interval/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to add interval in record: " + error.message);
  }
}
export async function getCaseIntervalHist() {
  try {
    const response = await fetch(`${API_HOST}/get_interval_history/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch intervals from record: " + error.message);
  }
}
export async function getRemainderHist(case_id) {
  try {
    const formData = new FormData();

    formData.append("case_id", case_id);
    const response = await fetch(`${API_HOST}/get_remainder_history/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch intervals from record: " + error.message);
  }
}
export async function sendCaseRemainder(caseid) {
  try {
    const formData = new FormData();

    formData.append("case_id", caseid);
    const response = await fetch(`${API_HOST}/send_case_remainder/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to send the case remainder: " + error.message);
  }
}

export const getAdminTotalResellersCount = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/getadmin_total_resellercount_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getAdminYearlyAquiredResellers = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/getadmin_yearly_resellers_aquired_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getAdminTotalCaseCount = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/getadminside_total_casecount_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getAdminTotalSalesByProducts = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/getadminside_totalsales_byproducts_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const getAdminTotalResellerSalesRevenue = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/getadminside_total_salesrevenue_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getAdminMonthYearwiseSalesRevenue = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/getadminside_yearmonthwise_sales_revenue_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getRenewalsDataMonthlyYearlyAdminDash = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_renewal_details_admin_for_dashobarddata/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getAdminTopSalesResellers = async (admin_email) => {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/getadminside_top_highest_sales_resellers/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getAdminResellersAccountCount = async () => {
  try {
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_total_resellersaccstatuscnt_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const fetchDetailsDashboardDataAdmin = async (
  admin_email,
  chart_name,
  chart_value
) => {
  try {
    const formData = new FormData();
    formData.append("chart_name", chart_name);
    formData.append("chart_value", chart_value);
    formData.append("admin_email", admin_email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_details_dashboard_data_admin/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData.data;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getResellerAccountDetails = async (reseller_id) => {
  try {
    const formData = new FormData();
    formData.append("reseller_id", reseller_id);

    // Make the API call
    const response = await fetch(`${API_HOST}/view_reseller_tagging_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export async function getTeamLists() {
  try {
    const response = await fetch(`${API_HOST}/get_teamlists/`, {
      method: "GET",
      headers: {
        Authorization: sessiontoken,
      },
    });

    const data = await response.json();

    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function assignTeam(teamData) {
  try {
    const response = await fetch(`${API_HOST}/assign_teams_forreseller/`, {
      method: "POST", // Change to POST to send data
      headers: {
        Authorization: sessiontoken,
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(teamData), // Convert your JavaScript object to JSON
    });

    const data = await response.json();

    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export const getResellerTeamDetails = async (reseller_id) => {
  try {
    const formData = new FormData();
    formData.append("reseller_id", reseller_id);

    // Make the API call
    const response = await fetch(`${API_HOST}/view_reseller_team_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const generateQuotationByAdmin = async (formDataToSend) => {
  try {
    // Make the API call
    const response = await fetch(`${API_HOST}/send_quotation_byadmin/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formDataToSend,
    });
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getSKUPrice = async (
  skuname,
  partnerdiscount,
  planType,
  planDuration,
  userCount
) => {
  try {
    const formData = new FormData();
    formData.append("skuname", skuname);
    formData.append("partnerdiscount", partnerdiscount);
    formData.append("plantype", planType);
    formData.append("planduration", planDuration);
    formData.append("usercount", userCount);
    // Define the API endpoint and the method to update the case
    const response = await fetch(`${API_HOST}/get_skuprice/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to get skuprice: " + error.message);
  }
};
export async function placeOrderByAdmin(
  quotation_id,
  plan_type,
  plan_duration,
  voucher_code
) {
  try {
    const formData = new FormData();
    formData.append("quotation_id", quotation_id);
    formData.append("plan_type", plan_type);
    formData.append("plan_duration", plan_duration);
    formData.append("voucher_code", voucher_code);
    const response = await fetch(
      `${API_HOST}/place_order_for_customer_byadmin/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    // const data = await response.json();
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function getRenewalDetails(admin_email) {
  try {
    const formData = new FormData();

    formData.append("admin_email", admin_email);
    const response = await fetch(`${API_HOST}/get_renewal_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function placeOrderRenewalByAdmin(formData) {
  try {
    const response = await fetch(
      `${API_HOST}/place_order_for_customer_byadmin_forrenewal/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    return response;
  } catch (error) {
    throw new Error(
      "Failed to place order for renewal from API: " + error.message
    );
  }
}

export async function getRenewalNotificationsDetails() {
  try {
    const response = await fetch(
      `${API_HOST}/get_licence_reduction_approvalnotifications/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export const licenceApproval = async (formData) => {
  try {
    const response = await fetch(
      `${API_HOST}/approval_status_forlicence_reduce/`,
      {
        method: "POST",
        body: formData,
      }
    );
    //console.log(response.json());
    return response;
  } catch (error) {
    throw new Error("Login failed: " + error.message);
  }
};
export async function getCallbackDetails(adminid) {
  const formData = new FormData();
  formData.append("adminid", adminid);
  try {
    const response = await fetch(`${API_HOST}/get_callback_requests_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export async function updateCallbackStatus(callbackid, callback_status) {
  const formData = new FormData();
  formData.append("callbackid", callbackid);
  formData.append("callback_status", callback_status);
  try {
    const response = await fetch(`${API_HOST}/update_callback_request/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
//##################################################### Reseller API Function ##############################################
export const resellerlogin = async (email, password) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const response = await fetch(`${API_HOST}/resellerlogin/`, {
      method: "POST",
      body: formData,
    });
    //console.log(response.json());
    return response;
  } catch (error) {
    throw new Error("Login failed: " + error.message);
  }
};
export const resellersendOTPforget = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const response = await fetch(`${API_HOST}/resellerforgotpassword/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to send OTP " + error.message);
  }
};
export const resellersignup = async (
  name,
  company,
  email,
  password,
  confirmPassword,
  phnno,
  logo,domainname
) => {
  try {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("company", company);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("cnfpassword", confirmPassword);
    formData.append("phnno", phnno);
    formData.append("logo", logo);
    formData.append("domain", domainname);
    const response = await fetch(`${API_HOST}/resellerregister_user/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Signup failed: " + error.message);
  }
};

export const resellersendOTP = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    const response = await fetch(`${API_HOST}/resellersignupotp/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to send OTP " + error.message);
  }
};

export const resellerverifyOTP = async (email, otp) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("otp", otp);
    const response = await fetch(`${API_HOST}/resellerverifysignupotp/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("OTP Error " + error.message);
  }
};

export const resellerresetpassword = async (
  userIdParam,
  otp,
  newPassword,
  confirmPassword
) => {
  try {
    const formData = new FormData();
    formData.append("id", userIdParam);
    formData.append("otp", otp);
    formData.append("password", newPassword);
    formData.append("repassword", confirmPassword);
    const response = await fetch(`${API_HOST}/resellerreset_password/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Reset Password failed: " + error.message);
  }
};
export const resellerupdatepassword = async (
  email,
  newPassword,
  confirmPassword
) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", newPassword);
    formData.append("repassword", confirmPassword);
    //console.log(name, company, email, password, confirmPassword);
    const response = await fetch(`${API_HOST}/resellerupdate_password/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Reset Password failed: " + error.message);
  }
};

export const update_user_account_details = async (profileInfo) => {
  try {
    const response = await fetch(
      `${API_HOST}/update_reseller_account_details/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessiontoken,
        },
        body: JSON.stringify(profileInfo),
      }
    );
    // const responseData = await response.json();
    return response; // You can handle the response as needed
  } catch (error) {
    console.error("Error sending data to API:", error);
    throw error; // Throw the error for the calling function to handle
  }
};

export const get_bank_details = async (ifsccode) => {
  try {
    const formData = new FormData();
    formData.append("ifsccode", ifsccode);
    const response = await fetch(`${API_HOST}/get_bank_details/`, {
      method: "POST", // Assuming you are making a GET request to fetch bank details
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
      // No need to send a body in a GET request
    });
    const responseData = await response.json();
    return responseData.result; // You can handle the response as needed
  } catch (error) {
    console.error("Error sending data to API:", error);
    throw error; // Throw the error for the calling function to handle
  }
};
export const submitFormData = async (formDataToSend) => {
  try {
    console.log(formDataToSend);
    const response = await fetch(`${API_HOST}/reseller_kyc_registration/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formDataToSend,
    });

    return response;
  } catch (error) {
    throw new Error("Submit Data failed: " + error.message);
  }
};

export const registerCustomer = async (formDataToSend) => {
  try {
    const response = await fetch(`${API_HOST}/customer_details_registration/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formDataToSend,
    });

    return response;
  } catch (error) {
    throw new Error("Submit Data failed: " + error.message);
  }
};

export const registerNewCase = async (formDataToSend) => {
  try {
    const response = await fetch(`${API_HOST}/register_case/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formDataToSend,
    });

    return response;
  } catch (error) {
    throw new Error("Submit Data failed: " + error.message);
  }
};

export const get_products_data = async () => {
  try {
    // Make the API call
    const response = await fetch(`${API_HOST}/get_products_data/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
    });
    const jsonData = await response.json();
    return jsonData.data;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const updateCustomerDetails = async (
  cust_id,
  updatedName,
  updatedDomain,
  updatedEmail,
  updatedPhone,
  updatedAddredd,
  updatedPancardNo,
  updatedGSTNo,
  updatedcompany,
  email,
  pancardFile,
  // gstFile
  customerPriority,
  industryType,
  updatedCustomerDesignation
) => {
  try {
    const formData = new FormData();
    formData.append("cust_id", cust_id);
    formData.append("cust_name", updatedName);
    formData.append("cust_domain", updatedDomain);
    formData.append("cust_email", updatedEmail);
    formData.append("cust_phnno", updatedPhone);
    formData.append("cust_address", updatedAddredd);
    formData.append("custpanno", updatedPancardNo);
    formData.append("custgstno", updatedGSTNo);
    formData.append("cust_company", updatedcompany);
    formData.append("reseller_email", email);
    formData.append("panCard", pancardFile);
    // formData.append("gstcertificate", gstFile);
    formData.append("priority", customerPriority);
    formData.append("industrytype", industryType);
    formData.append("customer_designation", updatedCustomerDesignation);
    const response = await fetch(`${API_HOST}/update_customer_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
};

export const deleteCustomerDetails = async (cust_id) => {
  try {
    const formData = new FormData();
    formData.append("cust_id", cust_id);
    const response = await fetch(`${API_HOST}/delete_customer_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
};

// api.js
export async function getCustomerlist(email) {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    const response = await fetch(`${API_HOST}/get_customer_list_of_reseller/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function getCustomerlistwthorders(email) {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    const response = await fetch(
      `${API_HOST}/get_customer_list_of_reseller_wthorders/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export const get_products_datdetails = async () => {
  try {
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_products_data_withprice_desc/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessiontoken,
        },
      }
    );
    const jsonData = await response.json();
    return jsonData.data;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const requestQuotation = async (
  caseId,
  // planType,
  // planDuration,
  resellerEmail
) => {
  try {
    const quotationdata = new FormData();
    quotationdata.append("caseid", caseId);
    // quotationdata.append("plantype", planType);
    // quotationdata.append("planduration", planDuration);
    quotationdata.append("reseller_email", resellerEmail);
    // Make the API call
    const response = await fetch(`${API_HOST}/request_quotation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: quotationdata,
    });
    // const jsonData = await response.json(); // Parse response body as JSON
    // console.log(jsonData);
    // return jsonData.data;
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export async function getCustomerCaseslist(email) {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    const response = await fetch(`${API_HOST}/get_customer_cases_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function placeOrder(
  quotation_id,
  plan_type,
  plan_duration,
  reseller_email,
  reseller_type,
  voucher_code
) {
  try {
    const formData = new FormData();
    formData.append("reseller_email", reseller_email);
    formData.append("quotation_id", quotation_id);
    formData.append("plan_type", plan_type);
    formData.append("plan_duration", plan_duration);
    formData.append("reseller_type", reseller_type);
    formData.append("voucher_code", voucher_code);
    const response = await fetch(`${API_HOST}/place_order_for_customer/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    // const data = await response.json();
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function getCustomerorderslist(email, reseller_type) {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    formData.append("reseller_type", reseller_type);

    const response = await fetch(`${API_HOST}/get_orders_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export const deleteOrderDetails = async (orderid) => {
  try {
    const formData = new FormData();
    formData.append("order_id", orderid);
    const response = await fetch(`${API_HOST}/delete_order/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
};

export const updateCaseDetails = async (caseData) => {
  try {
    // Define the API endpoint and the method to update the case
    const response = await fetch(`${API_HOST}/update_case_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: caseData,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
};
export async function getCustomerQuotationlist(email) {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    const response = await fetch(`${API_HOST}/get_customer_quotation_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

// export const sendEstimation = async (estimationData) => {
//   try {
//     const response = await fetch(`${API_HOST}/send_estimation/`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(estimationData),
//     });
//     // const responseData = await response.json();
//     return response; // You can handle the response as needed
//   } catch (error) {
//     console.error("Error sending data to API:", error);
//     throw error; // Throw the error for the calling function to handle
//   }
// };
export const sendQuotationNew = async (estimationData) => {
  try {
    const response = await fetch(`${API_HOST}/send_estimation/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: JSON.stringify(estimationData),
    });
    // const responseData = await response.json();
    return response; // You can handle the response as needed
  } catch (error) {
    console.error("Error sending data to API:", error);
    throw error; // Throw the error for the calling function to handle
  }
};

export const importCustomerData = async (file, email) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("reseller_email", email);
    // Define the API endpoint and the method to update the case
    const response = await fetch(`${API_HOST}/import_customer_data/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to import the data: " + error.message);
  }
};

export const getDiscount = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Define the API endpoint and the method to update the case
    const response = await fetch(
      `${API_HOST}/get_reseller_discount_latestvalue/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to import the data: " + error.message);
  }
};
export async function getCustomerTermsconditions() {
  try {
    const response = await fetch(`${API_HOST}/get_customer_conditions/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to fetch customer terms and conditions from API: " + error.message
    );
  }
}
export async function getResellerTermsconditions() {
  try {
    const response = await fetch(`${API_HOST}/get_reseller_conditions/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to fetch resellers terms and conditions from API: " +
        error.message
    );
  }
}
export async function mapQuotation(casename, quotationid) {
  try {
    const formdata = new FormData();
    // formdata.append("cust_id",cust_id)
    // formdata.append("quotation_id",quotationid)
    formdata.append("casename", casename);
    formdata.append("quotation_id", quotationid);
    const response = await fetch(`${API_HOST}/map_case_wt_quotation/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formdata,
    });
    // const data = await response.json();
    return response;
  } catch (error) {
    throw new Error("Failed to map data from API: " + error.message);
  }
}
export async function getCasesnames(reseller_id) {
  try {
    const formdata = new FormData();
    // formdata.append("cust_id",cust_id)
    formdata.append("reseller_id", reseller_id);
    const response = await fetch(`${API_HOST}/get_casesname_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formdata,
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to map data from API: " + error.message);
  }
}
export async function getInvoiceLists(reseller_email) {
  try {
    const formdata = new FormData();
    // formdata.append("cust_id",cust_id)
    formdata.append("reseller_email", reseller_email);
    const response = await fetch(`${API_HOST}/get_invoice_list/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formdata,
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to map data from API: " + error.message);
  }
}

// export async function getCustCasesLists(custID) {
//   try {
//     const formdata = new FormData();
//     // formdata.append("cust_id",cust_id)
//     formdata.append("custid", custID);
//     const response = await fetch(
//       `${API_HOST}/get_customers_cases_list_custid/`,
//       {
//         method: "POST",
//         headers: {
//           Authorization: sessiontoken,
//         },
//         body: formdata,
//       }
//     );
//     const data = await response.json();
//     if (data.success) {
//       return data.data;
//     } else {
//       throw new Error(data.error);
//     }
//   } catch (error) {
//     throw new Error("Failed to map data from API: " + error.message);
//   }
// }

export async function getSkudetails(custID) {
  try {
    const formdata = new FormData();
    // formdata.append("cust_id",cust_id)
    formdata.append("custid", custID);
    const response = await fetch(`${API_HOST}/get_existing_sku_details/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formdata,
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to map data from API: " + error.message);
  }
}

export async function add_licence_customer(
  customerId,
  productData,
  email,
  reseller_type
) {
  try {
    console.log(customerId, productData, email, reseller_type);
    const formdata = new FormData();
    // formdata.append("cust_id",cust_id)
    formdata.append("cust_id", customerId);
    formdata.append("productsData", productData);
    formdata.append("reseller_email", email);
    formdata.append("reseller_type", reseller_type);
    const response = await fetch(`${API_HOST}/add_licence/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formdata,
    });
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed toadd licence from API: " + error.message);
  }
}
export const getCommision = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Define the API endpoint and the method to update the case
    const response = await fetch(
      `${API_HOST}/get_reseller_commision_latestvalue/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to get the commision value: " + error.message);
  }
};
export async function getCommisionDetails(reselleremail) {
  try {
    const formdata = new FormData();
    // formdata.append("cust_id",cust_id)
    formdata.append("reseller_email", reselleremail);
    const response = await fetch(
      `${API_HOST}/get_reseller_commision_details/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessiontoken,
        },
        body: formdata,
      }
    );
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to map data from API: " + error.message);
  }
}
export const getVoucherAmount = async (email, vouchercode, quotation_id) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    formData.append("voucher_code", vouchercode);
    formData.append("quotation_id", quotation_id);
    // Define the API endpoint and the method to update the case
    const response = await fetch(`${API_HOST}/get_voucher_amount/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to get the voucher value: " + error.message);
  }
};

export const getTotalResellerCommision = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Define the API endpoint and the method to update the case
    const response = await fetch(
      `${API_HOST}/get_reseller_total_earned_commision/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );

    return response;
  } catch (error) {
    throw new Error(
      "Failed to get the total commision value: " + error.message
    );
  }
};
export async function getIndustryTypes() {
  try {
    const response = await fetch(`${API_HOST}/get_industry_types/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to get industry types from record: " + error.message
    );
  }
}
// export const getResellerDashboardData = async (email) => {
//   try {
//     const formData = new FormData();
//     formData.append("reseller_email", email);
//     // Make the API call
//     const response = await fetch(
//       `${API_HOST}/get_reseller_dashboard_data/`,
//       {
//         method: "POST",
//         // headers: {
//         //   "Content-Type": "application/json", // Specify JSON content type
//         // },
//         body: formData,
//       }
//     );
//     const jsonData = await response.json();
//     return jsonData;
//   } catch (error) {
//     throw new Error("Error Occured: " + error.message);
//   }
// };
export const getTotalCustomerCount = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_total_customercount_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getYearlyAquiredCustomer = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_yearly_customer_aquired_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getTotalCaseCount = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(`${API_HOST}/get_total_casecount_dashboard/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getTotalSalesByProducts = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_totalsales_byproducts_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getProductsWiseCustomer = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_productwise_customeraquired_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getTotalResellerProfit = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(`${API_HOST}/get_total_profit_dashboard/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getTotalResellerSalesRevenue = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_total_salesrevenue_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getMonthYearwiseSalesRevenue = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_yearmonthwise_sales_revenue_dashboard/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getRenewalsDataMonthlyYearly = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_renewal_details_reseller_for_dashobarddata/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getTopSalesCustomers = async (email) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_top_highest_sales_customer/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const fetchDetailsDashboardData = async (
  email,
  chart_name,
  chart_value
) => {
  try {
    const formData = new FormData();
    formData.append("reseller_email", email);
    formData.append("chart_name", chart_name);
    formData.append("chart_value", chart_value);
    // Make the API call
    const response = await fetch(`${API_HOST}/get_details_dashboard_data/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const jsonData = await response.json();
    return jsonData.data;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export async function getCompanyTypes() {
  try {
    const response = await fetch(`${API_HOST}/get_reseller_comptypes/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error(
      "Failed to get industry types from record: " + error.message
    );
  }
}
export const exportCustomerData = async (
  selectedColumns,
  startDate,
  endDate
) => {
  try {
    const formData = new FormData();
    formData.append("selectedcolumns", JSON.stringify(selectedColumns));
    formData.append("startdate", startDate);
    formData.append("enddate", endDate);
    // Make the API call
    const response = await fetch(`${API_HOST}/export_customer_data/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const exportReports = async (
  modulename,
  option,
  suboption,
  monthyear,
  year,
  email
) => {
  try {
    const formData = new FormData();
    formData.append("modulename", modulename);
    formData.append("option", option);
    formData.append("suboption", suboption);
    formData.append("monthyear", monthyear);
    formData.append("year", year);
    formData.append("email", email);
    // Make the API call
    const response = await fetch(`${API_HOST}/get_details_report_data_admin/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const exportResellerReports = async (
  modulename,
  option,
  suboption,
  monthyear,
  year,
  email
) => {
  try {
    const formData = new FormData();
    formData.append("modulename", modulename);
    formData.append("option", option);
    formData.append("suboption", suboption);
    formData.append("monthyear", monthyear);
    formData.append("year", year);
    formData.append("reseller_email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_details_report_data_reseller/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const generateQuotationByCase = async (
  case_id,
  reseller_email,
  quotfor,
  customerterms,
  resellerterms
) => {
  try {
    const formData = new FormData();
    formData.append("case_id", case_id);
    formData.append("reseller_email", reseller_email);
    formData.append("quotationfor", quotfor);
    formData.append("customer_terms", customerterms);
    formData.append("reseller_terms", resellerterms);
    // Make the API call
    const response = await fetch(`${API_HOST}/send_quotation_bycaseid/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export async function getSkupricewthPartnerDiscount(skunames, case_id) {
  try {
    const formData = new FormData();

    formData.append("skuname", skunames);
    formData.append("caseid", case_id);
    const response = await fetch(
      `${API_HOST}/get_skupricewth_partnerdiscount/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function getRenewalDetailsReseller(reseller_email) {
  try {
    const formData = new FormData();

    formData.append("reseller_email", reseller_email);
    const response = await fetch(`${API_HOST}/get_renewal_details_reseller/`, {
      method: "POST",
      headers: {
        Authorization: sessiontoken,
      },
      body: formData,
    });
    const data = await response.json();
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

export async function getApprovalForLicenceRedcution(formData) {
  try {
    const response = await fetch(
      `${API_HOST}/get_approvalfor_licencereduction_renewal/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
export const getLicenceApprovalStatus = async (licid) => {
  try {
    const formData = new FormData();
    formData.append("licid", licid);
    const response = await fetch(
      `${API_HOST}/get_approval_details_forrenewal_reducelic/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formData,
      }
    );
    const data = await response.json();
    console.log("api.js", data);
    if (data.success) {
      return data.data;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Login failed: " + error.message);
  }
};

export async function sendCallbackRequest(reseller_email) {
  try {
    const formdata = new FormData();
    // formdata.append("cust_id",cust_id)
    formdata.append("reseller_email", reseller_email);
    const response = await fetch(
      `${API_HOST}/request_for_callback_toaccountmanager/`,
      {
        method: "POST",
        headers: {
          Authorization: sessiontoken,
        },
        body: formdata,
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to map data from API: " + error.message);
  }
}

// ################################old COde####################################################

export const verifyDetails = async (jsonData) => {
  try {
    // // Convert formData to JSON string
    // const jsonData = JSON.stringify(formData);

    // Make the API call
    const response = await fetch(`${API_HOST}/verify_kyc_details/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: jsonData, // Pass the JSON data in the request body
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export async function getListAssignProduct() {
  try {
    const response = await fetch(
      `${API_HOST}/get_registered_customer_list_assignproduct/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessiontoken,
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      return data.data.data_result;
    } else {
      throw new Error(data.error);
    }
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}
// export const getUserDetails = async (user_reg_id) => {
//   try {
//     const formData = new FormData();
//     formData.append("user_reg_id", user_reg_id);
//     const response = await fetch(
//       "${API_HOST}/get_customer_details/",
//       {
//         method: "POST",
//         body: formData, // Pass the form data in the request body
//       }
//     );

//     const data = await response.json();
//     console.log(data.data.data_result);
//     if (data.success) {
//       return data.data.data_result[0];
//     } else {
//       throw new Error(data.error);
//     }
//   } catch (error) {
//     throw new Error("Failed to fetch data from API: " + error.message);
//   }
// };
export const view_assigned_products = async (user_id, cust_id) => {
  try {
    // Convert formData to JSON string
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("cust_id", cust_id);
    // Make the API call
    const response = await fetch(`${API_HOST}/view_assigned_products/`, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const delete_assigned_products = async (user_reg_id, products) => {
  try {
    // Make sure products is an array
    if (!Array.isArray(products) || products.length === 0) {
      throw new Error("Please select at least one product for deletion");
    }

    // Construct formData object including user_reg_id and products
    const formData = {
      user_reg_id,
      products,
    };

    // Convert formData to JSON string
    const jsonData = JSON.stringify(formData);

    // Make the API call
    const response = await fetch(`${API_HOST}/delete_assigned_products/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify JSON content type
      },
      body: jsonData,
    });

    return response;
  } catch (error) {
    throw new Error("Error Occurred: " + error.message);
  }
};
export const sendDataToApi = async (selectedData) => {
  try {
    const response = await fetch(`${API_HOST}/apply_product/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedData), // Convert selectedData to JSON string
    });
    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }
    // const responseData = await response.json();
    return response; // Return the parsed JSON response
  } catch (error) {
    console.error("Error sending data to API:", error);
    throw error; // Throw the error for the calling function to handle
  }
};
export const get_json_data_with_user_email = async (
  email,
  selectedCustomerId
) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("cust_id", selectedCustomerId);
    // Make the API call
    const response = await fetch(`${API_HOST}/get_json_data_db_with_email/`, {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json", // Specify JSON content type
      // },
      body: formData,
    });
    // const data = await response.json();
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export async function updateRejectedFieldsSubmit(updatedRejectedFields) {
  try {
    const formData = new FormData();

    // Append each key-value pair from updatedRejectedFields to formData
    for (const key in updatedRejectedFields) {
      if (updatedRejectedFields.hasOwnProperty(key)) {
        formData.append(key, updatedRejectedFields[key]);
      }
    }
    const response = await fetch(`${API_HOST}/update_registration_details/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data from API: " + error.message);
  }
}

//R&D Related API Call//
export const get_json_data = async () => {
  try {
    // Make the API call
    const response = await fetch(`${API_HOST}/get_json_data_db/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
    });
    const jsonData = await response.json();
    return jsonData.data;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
export const getCustomerData = async (email) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_customer_list_for_reseller/`,
      {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json", // Specify JSON content type
        // },
        body: formData,
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

//delete this below code
export const assign_products = async (requestBody) => {
  try {
    // Make the API call
    const response = await fetch(`${API_HOST}/assign_products/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessiontoken,
      },
      body: JSON.stringify(requestBody),
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};
// export const get_json_data_with_aggreement = async (email) => {
//   try {
//     const formData = new FormData();
//     console.log("email", email);
//     formData.append("email", email);
//     // Make the API call
//     const response = await fetch(
//       "${API_HOST}/get_customer_list_for_reseller/",
//       {
//         method: "POST",
//         // headers: {
//         //   "Content-Type": "application/json", // Specify JSON content type
//         // },
//         body: formData,
//       }
//     );
//     console.log(response);
//     return response;
//   } catch (error) {
//     throw new Error("Error Occured: " + error.message);
//   }
// };
export const getDashboardData = async () => {
  try {
    // Make the API call
    const response = await fetch(`${API_HOST}/get_admin_dashboard_data/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json", // Specify JSON content type
      },
    });
    const jsonData = await response.json();
    return jsonData.result;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

//change the api
export const getDetailsData = async () => {
  try {
    // Make the API call
    const response = await fetch(`${API_HOST}/get_admin_dashboard_data/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json", // Specify JSON content type
      },
    });
    const jsonData = await response.json();
    return jsonData.result;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

//R&D api call for details dashboard data
export const getResellerDashboardDetailedData = async (
  email,
  chartname,
  chartvalue
) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("chart_name", chartname);
    formData.append("chartvalue", chartvalue);

    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_detailed_dashboard_details_data/`,
      {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json", // Specify JSON content type
        // },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const getAdminDashboardDetailedData = async (chartname, chartvalue) => {
  try {
    const formData = new FormData();
    formData.append("chart_name", chartname);
    formData.append("chartvalue", chartvalue);

    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_admin_dashboard_details_data/`,
      {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json", // Specify JSON content type
        // },
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const add_product_json = async (serviceName, productName) => {
  try {
    const formData = new FormData();
    formData.append("serviceName", serviceName);
    formData.append("productName", productName);
    // Make the API call
    const response = await fetch(`${API_HOST}/update_product_data/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const delete_product_json = async (
  deleteSelection,
  deleteInputValue
) => {
  try {
    const formData = new FormData();
    formData.append("deleteSelection", deleteSelection);
    formData.append("deleteInputValue", deleteInputValue);
    // Make the API call
    const response = await fetch(`${API_HOST}/delete_product_data/`, {
      method: "POST",
      body: formData,
    });
    return response;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const get_daterangewise_data_montiwise_chart = async (
  email,
  frm_date,
  to_date
) => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("frm_date", frm_date);
    formData.append("to_date", to_date);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_daterangewise_monthlyadded_customers_count/`,
      {
        method: "POST",
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const get_daterangewise_data_montiwise_chart_admin_side = async (
  frm_date,
  to_date
) => {
  try {
    const formData = new FormData();
    formData.append("frm_date", frm_date);
    formData.append("to_date", to_date);
    // Make the API call
    const response = await fetch(
      `${API_HOST}/get_daterangewise_monthlyadded_resellers_count/`,
      {
        method: "POST",
        body: formData,
      }
    );
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error("Error Occured: " + error.message);
  }
};

export const transferCustomer = async (formDataToSend) => {
  try {
    //console.log(name, company, email, password, confirmPassword);
    const response = await fetch(
      `${API_HOST}/raise_customer_transfer_request/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessiontoken,
        },
        body: formDataToSend,
      }
    );

    return response;
  } catch (error) {
    throw new Error("Submit Data failed: " + error.message);
  }
};
