import React, { Fragment, useState, useEffect } from "react";
import {
  registerCustomer,
  get_products_data,
  getIndustryTypes,
} from "../../utils/api";
import { FaEdit } from "react-icons/fa";
import { Tab, Tabs, Button } from "react-bootstrap";

const CustomerRegister = () => {
  const [formData, setFormData] = useState({
    customerName: "",
    customerCompanyname: "",
    customerDomainname: "",
    customerEmail: "",
    customerPhnno: "",
    customerAddress: "",
    // customerPancardnumber: "",
    // customerGstnumber: "",
    additionalInformation: "",
    customerDesignation: "",
    oems: "",
    products: "",
    subproducts: "",
    userCount: "",
    registerCaseFlag: "No",
    existingProdFlag: "No",
    existingProducts: [], // Each product will now have an `isEditable` flag
  });

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [panNumberError, setPanNumberError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [products, setProducts] = useState([]);
  const [userCount, setUserCount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedOEM, setSelectedOEM] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedSubproduct, setSelectedSubproduct] = useState("");
  const [subproductList, setSubproductList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [buyPlanType, setBuyPlanType] = useState("");
  const [buyPlanDuration, setBuyPlanDuration] = useState("");
  const [customerPriority, setCustomerPriority] = useState("");
  const [customerIndustryType, setcustomerIndustryType] = useState("");
  const [caseType, setCaseType] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const [industryTypeList, setIndustryTypeList] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1"); // State for active tab
  // useEffect(() => {
  //   document.title = "Customer Register Page";
  // }, []);
  const [productData, setProductData] = useState({}); // Initialize productData state

  useEffect(() => {
    // Fetch product data when component mounts
    const fetchProductData = async () => {
      try {
        setLoading(true);
        const data = await get_products_data(); // Call the API function to get product data
        setProductData(data); // Set the fetched product data in state
      } catch (error) {
        console.error("Error fetching product data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData(); // Invoke the fetchProductData function
  }, []);

  useEffect(() => {
    // Fetch product data when component mounts
    const fetchIndustryTypes = async () => {
      try {
        setLoading(true);
        const industlist = await getIndustryTypes(); // Call the API function to get product data
        setIndustryTypeList(industlist); // Set the fetched product data in state
      } catch (error) {
        console.error("Error fetching industry types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchIndustryTypes(); // Invoke the fetchProductData function
  }, []);

  const handleFileUpload = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
      [`${name}status`]: "", // Set the corresponding status to empty when file is uploaded
    }));
  };
  const validateEmail = (email) => {
    // Email regex pattern for basic validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateContactNumber = (contactNumber) => {
    // Contact number regex pattern for basic validation
    const contactNumberPattern = /^[0-9]{10}$/;
    return contactNumberPattern.test(contactNumber);
  };

  const validatePANNumber = (panNumber) => {
    // PAN card number regex pattern for basic validation
    const panNumberPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    return panNumberPattern.test(panNumber);
  };
  const validateGSTNumber = (gstNumber) => {
    // GST number regex pattern for basic validation
    const gstNumberPattern =
      /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/;
    return gstNumberPattern.test(gstNumber);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      customerEmail: emailValue,
    }));
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };
  const handleContactNumberChange = (e) => {
    const contactNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      customerPhnno: contactNumberValue,
    }));
    setContactNumberError(
      validateContactNumber(contactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };
  const handlePANNumberChange = (e) => {
    const panNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      customerPancardnumber: panNumberValue.toUpperCase(),
    }));
    setPanNumberError(
      validatePANNumber(panNumberValue) ? "" : "Invalid PAN card number format"
    );
  };

  const handleGSTNumberChange = (e) => {
    const gstNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      customerGstnumber: gstNumberValue.toUpperCase(),
    }));
    setGstNumberError(
      validateGSTNumber(gstNumberValue) ? "" : "Invalid GST number format"
    );
  };
  const openFilePopup = (fileUrl) => {
    // Open a new window to display the file
    window.open(fileUrl, "_blank", "width=800,height=600");
  };
  const handleAddProduct = async (event) => {
    if (
      userCount.trim() === "" ||
      selectedOEM.trim() === "" ||
      selectedProduct.trim() === "" ||
      selectedSubproduct.trim() === ""
    ) {
      window.alert("Please fill in all product details.");
      return;
    }

    const newProduct = {
      oemName: selectedOEM,
      productName: selectedProduct,
      subproductName: selectedSubproduct,
      userCount: userCount,
    };

    if (editingIndex !== null) {
      // Editing existing product
      const updatedProducts = [...products];
      updatedProducts[editingIndex] = newProduct;
      setProducts(updatedProducts);
    } else {
      // Adding new product
      const isDuplicate = products.some(
        (product) =>
          product.oemName === selectedOEM &&
          product.productName === selectedProduct &&
          product.subproductName === selectedSubproduct
      );

      if (isDuplicate) {
        window.alert("Same product cannot be added again.");
        return;
      }

      setProducts((prevProducts) => [...prevProducts, newProduct]);
    }

    // Reset form fields and editingIndex
    setSelectedOEM("");
    setSelectedProduct("");
    setSelectedSubproduct("");
    setUserCount("");
    setEditingIndex(null);
    setShowModal(false);
  };

  const handleEditProduct = (index, product) => {
    if (!product) {
      console.error("Invalid product object:", product);
      return;
    }

    setSelectedOEM(product.oemName);
    setSelectedProduct(product.productName);
    setSelectedSubproduct(product.subproductName);
    setUserCount(product.userCount);
    setEditingIndex(index); // Add this line to track the editing index
    setShowModal(true);
  };

  const handleDeleteProduct = (index) => {
    // Filter out the product at the specified index
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedOEMs = products.map((product) => product.oemName).join(",");
    const selectedProducts = products
      .map((product) => product.productName)
      .join(",");
    const selectedSubproducts = products
      .map((product) => product.subproductName)
      .join(",");
    const userCounts = products.map((product) => product.userCount).join(",");

    if (products.length === 0 && formData.registerCaseFlag == "Yes") {
      window.alert("Please add products before submitting.");
      return;
    }
    setLoading(true);
    // Check for blank fields
    if (
      formData.customerName.trim() === "" ||
      formData.customerDomainname.trim() === "" ||
      formData.customerEmail.trim() === "" ||
      formData.customerPhnno.trim() === "" ||
      formData.customerAddress.trim() === "" ||
      formData.additionalInformation.trim() === "" ||
      // formData.customerGstnumber.trim() === "" ||
      // formData.customerPancardnumber.trim() === "" ||
      formData.customerCompanyname.trim() === "" ||
      formData.customerIndustryType === "" ||
      formData.customerDesignation === "" ||
      caseType === ""
    ) {
      window.alert("Please fill in all fields before submitting.");
      setLoading(false);
      return;
    }

    // const newProduct = {
    //   oemName: selectedOEM,
    //   productName: selectedProduct,
    //   subproductName: selectedSubproduct,
    //   userCount: userCount,
    // };
    // setProducts((prevProducts) => [...prevProducts, newProduct]);
    setSelectedOEM("");
    setSelectedProduct("");
    setSelectedSubproduct("");
    setUserCount("");
    setShowModal(false);
    try {
      const formDataToSend = new FormData(event.target);

      formDataToSend.append("customername", formData.customerName);
      formDataToSend.append("customerdomainname", formData.customerDomainname);
      formDataToSend.append("customeremailid", formData.customerEmail);
      formDataToSend.append("customercontactnumber", formData.customerPhnno);
      formDataToSend.append("customeraddress", formData.customerAddress);
      formDataToSend.append(
        "additionalinformation",
        formData.additionalInformation
      );
      formDataToSend.append("customerdesign", formData.customerDesignation);
      formDataToSend.append("customercompname", formData.customerCompanyname);
      // formDataToSend.append(
      //   "customerpancardnumber",
      //   formData.customerPancardnumber
      // );
      // formDataToSend.append("customergstnumber", formData.customerGstnumber);
      formDataToSend.append("reseller_email", parsedLoginData.reseller_email);
      formDataToSend.append("plantype", buyPlanType); // Append plantype
      formDataToSend.append("planduration", buyPlanDuration); // Append planduration
      // Append selectedSubproducts and userCounts to formDataToSend
      formDataToSend.append("selectedOEMs", selectedOEMs);
      formDataToSend.append("selectedProducts", selectedProducts);
      formDataToSend.append("selectedSubproducts", selectedSubproducts);
      formDataToSend.append("userCounts", userCounts);
      formDataToSend.append("priority", customerPriority);
      formDataToSend.append("industrytype", customerIndustryType);
      formDataToSend.append("registercaseflag", formData.registerCaseFlag);
      formDataToSend.append("caseType", caseType);
      formDataToSend.append("existingprodflag", formData.existingProdFlag);
      console.log("testingexistprods", formData.existingProducts);
      formDataToSend.append(
        "existingproddetails",
        JSON.stringify(formData.existingProducts)
      );
      // // Update userCount in formData
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   products: selectedSubproducts,
      //   userCount: userCounts,
      // }));
      const response = await registerCustomer(formDataToSend);
      if (response.status === 200) {
        window.alert("Customer Registration Completed Successfully.");
        window.location.href = "/usermaincomponent";
      } else {
        const data = await response.json();
        window.alert(data.message);
      }
    } catch (error) {
      window.alert("Error submitting form:", error);
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  const handlePlanTypeChange = (e) => {
    const planType = e.target.value;
    setBuyPlanType(planType);
    if (planType === "quarterly") {
      setBuyPlanDuration("3");
    } else if (planType === "half yearly") {
      setBuyPlanDuration("6");
    } else if (planType === "yearly") {
      setBuyPlanDuration("");
    } else if (planType === "monthly") {
      setBuyPlanDuration("");
    }
  }; // Function to handle the "Next" button click
  const handleNextClick = () => {
    if (activeTab === "tab1") {
      setActiveTab("tab2");
    } else if (activeTab === "tab2") {
      setActiveTab("tab3");
    }
    // Add more conditions if you have more tabs
  };
  const handleBackClick = () => {
    if (activeTab === "tab3") {
      setActiveTab("tab2");
    } else if (activeTab === "tab2") {
      setActiveTab("tab1");
    }
    // Add more conditions if you have more tabs
  };
  const handleProductChange = (index, field, value) => {
    setFormData((prevFormData) => {
      const updatedProducts = [...prevFormData.existingProducts];
      updatedProducts[index][field] = value;
      return { ...prevFormData, existingProducts: updatedProducts };
    });
  };

  const addProductRow = () => {
    // Disable editing for all existing rows when a new row is added
    setFormData((prevFormData) => {
      const updatedProducts = prevFormData.existingProducts.map((product) => ({
        ...product,
        isEditable: false,
      }));
      return {
        ...prevFormData,
        existingProducts: [
          ...updatedProducts,
          {
            productName: "",
            planType: "",
            planDuration: "",
            licenseCount: "",
            renewalDate: "",
            isEditable: true,
            majorchallange: "",
            datamigration: "",
          },
        ],
      };
    });
  };

  const deleteProductRow = (index) => {
    setFormData((prevFormData) => {
      const updatedProducts = [...prevFormData.existingProducts];
      updatedProducts.splice(index, 1);
      return { ...prevFormData, existingProducts: updatedProducts };
    });
  };

  const toggleEditRow = (index) => {
    setFormData((prevFormData) => {
      const updatedProducts = [...prevFormData.existingProducts];
      updatedProducts[index].isEditable = true;
      return { ...prevFormData, existingProducts: updatedProducts };
    });
  };
  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-1">Register Customer Details</h4>
            <br></br>
            <p style={{ color: "red" }}>
              Note:- Here, you can register both customer details and case
              details. If the customer is already registered in the system and
              you only need to generate a case, simply navigate to the "Case
              List" option and create the case from there.
            </p>
            <form class="form-sample" onSubmit={handleSubmit}>
              <Tabs
                id="customerregistration-tabs"
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key)}
                transition={false}
                className="mb-3"
              >
                <Tab eventKey="tab1" title="Customer Details">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Customer Name<span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer Name"
                            name="customerName"
                            value={formData.customerName}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                customerName: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Customer Domain<span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer Domain"
                            name="customerDomainname"
                            value={formData.customerDomainname}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                customerDomainname: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Customer Company Name
                          <span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer Company Name"
                            name="customerCompanyname"
                            value={formData.customerCompanyname}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                customerCompanyname: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Customer Email-ID
                          <span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            className={`form-control ${
                              formData.customerEmail
                                ? emailError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            placeholder="Customer Email-ID"
                            name="customerEmail"
                            value={formData.customerEmail}
                            onChange={handleEmailChange}
                          />
                          {emailError && (
                            <small className="text-danger">{emailError}</small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Customer Phonenumber
                          <span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            className={`form-control ${
                              formData.customerPhnno
                                ? phoneError
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            placeholder="Customer Phonenumber"
                            name="customerPhnno"
                            value={formData.customerPhnno}
                            onChange={handleContactNumberChange}
                          />
                          {phoneError && (
                            <small className="text-danger">
                              {contactNumberError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Customer Designation
                          <span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer Designation"
                            name="customerDesignation"
                            value={formData.customerDesignation}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                customerDesignation: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Customer Address<span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <textarea
                            type="text"
                            className="form-control"
                            rows="1"
                            placeholder="Customer Address"
                            name="customerAddress"
                            value={formData.customerAddress}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                customerAddress: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                      Customer Pancard Number
                      <span className="text-danger">*</span>
                    </label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        className={`form-control ${
                          formData.customerPancardnumber
                            ? panNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        placeholder="Customer Pancard Number"
                        name="customerPancardnumber"
                        value={formData.customerPancardnumber}
                        onChange={handlePANNumberChange}
                        required
                      />
                      {panNumberError && (
                        <small className="text-danger">{panNumberError}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">
                      Customer GST Number
                      <span className="text-danger">(optional)</span>
                    </label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        className={`form-control ${
                          formData.customerGstnumber
                            ? gstNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        placeholder="Customer GST Number"
                        name="customerGstnumber"
                        value={formData.customerGstnumber}
                        onChange={handleGSTNumberChange}
                        // required
                      />
                      {gstNumberError && (
                        <small className="text-danger">{gstNumberError}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Upload Pan Card<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {formData.pancardURL ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => openFilePopup(formData.pancardURL)}
                          title="View Document"
                        >
                          View
                        </button>
                      ) : (
                        <input
                          type="file"
                          className="form-control"
                          name="panCard"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          required
                        />
                      )}
                    </div>
                  </div>
                </div> */}
                    <div className="col-md-6">
                      {/* <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Upload GST Certificate
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {formData.gstcertificateURL ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            openFilePopup(formData.gstcertificateURL)
                          }
                          title="View Document"
                        >
                          View
                        </button>
                      ) : (
                        <input
                          type="file"
                          className="form-control"
                          name="gstcertificate"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          required
                        />
                      )}
                    </div>
                  </div> */}
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Select Customer Priority
                          <span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <select
                            value={customerPriority}
                            onChange={(e) =>
                              setCustomerPriority(e.target.value)
                            }
                            style={{ width: "100%" }}
                            className="form-control"
                          >
                            <option value="">Select Priority</option>
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Select Industry Type
                          <span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <select
                            value={customerIndustryType}
                            onChange={(e) =>
                              setcustomerIndustryType(e.target.value)
                            }
                            style={{ width: "100%" }}
                            className="form-control"
                          >
                            <option value="">Select Industry Type</option>
                            {industryTypeList.map((industry) => (
                              <option key={industry} value={industry}>
                                {industry}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Additional Information
                          <span className="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                          <textarea
                            type="text"
                            className="form-control"
                            rows="1"
                            placeholder="Additional Information"
                            name="additionalInformation"
                            value={formData.additionalInformation}
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                additionalInformation: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="tab2" title="Existing Products Details">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          Do you want to add Existing Product Details which
                          customer is using?
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            name="existingProdFlag"
                            value="Yes"
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                existingProdFlag: e.target.value,
                              }))
                            }
                            checked={formData.existingProdFlag === "Yes"}
                          />{" "}
                          Yes{" "}
                          <input
                            type="radio"
                            name="existingProdFlag"
                            value="No"
                            onChange={(e) => {
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                existingProdFlag: e.target.value,
                              }));
                            }}
                            checked={formData.existingProdFlag === "No"}
                          />{" "}
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                  {formData.existingProdFlag === "Yes" && (
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            border: "2px solid #000",
                          }}
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Product Name</th>
                                <th>Plan Type</th>
                                <th>Plan Duration</th>
                                <th>License Count</th>
                                <th>Renewal Date</th>
                                <th>Major Challenge</th>
                                <th>Data Migration</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.existingProducts.map(
                                (product, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        value={product.productName}
                                        disabled={!product.isEditable}
                                        className="form-control"
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "productName",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={product.planType}
                                        disabled={!product.isEditable}
                                        className="form-control"
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "planType",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={product.planDuration}
                                        disabled={!product.isEditable}
                                        className="form-control"
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "planDuration",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        value={product.licenseCount}
                                        disabled={!product.isEditable}
                                        className="form-control"
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "licenseCount",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="date"
                                        value={product.renewalDate}
                                        disabled={!product.isEditable}
                                        className="form-control"
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "renewalDate",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <textarea
                                        type="text"
                                        value={product.majorchallange}
                                        disabled={!product.isEditable}
                                        className="form-control"
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "majorchallange",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name="datamigration"
                                        value={product.datamigration}
                                        disabled={!product.isEditable}
                                        checked={
                                          product.datamigration === "Yes"
                                        }
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "datamigration",
                                            e.target.value
                                          )
                                        }
                                      />{" "}
                                      Yes{" "}
                                      <input
                                        type="radio"
                                        name="datamigration"
                                        value={product.datamigration}
                                        disabled={!product.isEditable}
                                        onChange={(e) =>
                                          handleProductChange(
                                            index,
                                            "datamigration",
                                            e.target.value
                                          )
                                        }
                                        checked={
                                          formData.datamigration === "No"
                                        }
                                      />{" "}
                                      No
                                    </td>
                                    <td>
                                      {" "}
                                      <span
                                        variant="danger"
                                        size="sm"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => deleteProductRow(index)}
                                      >
                                        ✘
                                      </span>
                                      {!product.isEditable && (
                                        <span
                                          variant="warning"
                                          size="sm"
                                          style={{
                                            marginLeft: "10px",
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => toggleEditRow(index)}
                                        >
                                          <FaEdit />
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <br></br>
                        <button
                          className="btn btn-primary"
                          onClick={addProductRow}
                          type="button"
                        >
                          Add Product
                        </button>
                      </div>
                    </div>
                  )}
                  <br></br>
                </Tab>
                <Tab eventKey="tab3" title="Case Details">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          Do you want to Register Case?
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            name="registerCaseFlag"
                            value="Yes"
                            onChange={(e) =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                registerCaseFlag: e.target.value,
                              }))
                            }
                            checked={formData.registerCaseFlag === "Yes"}
                          />{" "}
                          Yes{" "}
                          <input
                            type="radio"
                            name="registerCaseFlag"
                            value="No"
                            onChange={(e) => {
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                registerCaseFlag: e.target.value,
                              }));
                            }}
                            checked={formData.registerCaseFlag === "No"}
                          />{" "}
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                  {formData.registerCaseFlag === "Yes" && (
                    <>
                      <div class="row">
                        <div className="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Select Case Type
                              <span className="text-danger">*</span>
                            </label>
                            <div class="col-sm-9">
                              <select
                                value={caseType}
                                onChange={(e) => setCaseType(e.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                              >
                                <option value="">Select Case Type</option>
                                <option value="new_case">New Case</option>
                                <option value="renewal_case">
                                  Renewal Case
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Select Plan Type
                              <span className="text-danger">*</span>
                            </label>
                            <div class="col-sm-9">
                              <select
                                value={buyPlanType}
                                onChange={handlePlanTypeChange}
                                name="plantype"
                                className="form-control"
                              >
                                <option value="">Select Plan Type</option>
                                <option value="monthly">Monthly</option>
                                {/* <option value="quarterly">Quarterly</option>
                        <option value="half yearly">Half Yearly</option> */}
                                <option value="yearly">Yearly</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">
                              Plan Duration
                              <span className="text-danger">*</span>
                            </label>
                            <div class="col-sm-9">
                              <input
                                type="number"
                                value={buyPlanDuration} // Use buyPlanDuration instead of formData.planduration
                                onChange={(e) =>
                                  setBuyPlanDuration(e.target.value)
                                }
                                placeholder="Plan Duration"
                                style={{ width: "100%" }}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>
                              <strong>Product Details</strong>
                            </label>
                            <br></br>
                            <div className="d-flex">
                              <select
                                className="form-control mr-2"
                                value={selectedOEM}
                                onChange={(e) => {
                                  setSelectedOEM(e.target.value);
                                  setSelectedProduct("");
                                  setSelectedSubproduct("");
                                }}
                              >
                                <option value="">Select OEM</option>
                                {Object.keys(productData).map((oem) => (
                                  <option key={oem} value={oem}>
                                    {oem}
                                  </option>
                                ))}
                              </select>
                              <select
                                className="form-control mr-2"
                                value={selectedProduct}
                                onChange={(e) => {
                                  setSelectedProduct(e.target.value);
                                  setSubproductList(
                                    productData[selectedOEM][e.target.value] ||
                                      []
                                  );
                                  setSelectedSubproduct("");
                                }}
                              >
                                <option value="">Select Product</option>
                                {selectedOEM &&
                                  Object.keys(productData[selectedOEM]).map(
                                    (product) => (
                                      <option key={product} value={product}>
                                        {product}
                                      </option>
                                    )
                                  )}
                              </select>
                              <select
                                className="form-control mr-2"
                                value={selectedSubproduct}
                                onChange={(e) =>
                                  setSelectedSubproduct(e.target.value)
                                }
                              >
                                <option value="">Select Skuname</option>
                                {subproductList.map((subproduct) => (
                                  <option key={subproduct} value={subproduct}>
                                    {subproduct}
                                  </option>
                                ))}
                              </select>
                              <input
                                type="number"
                                className="form-control mr-2"
                                placeholder="User Count"
                                value={userCount}
                                onChange={(e) => setUserCount(e.target.value)}
                              />
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddProduct}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                        {products.length > 0 && (
                          <div class="col-md-6">
                            <div
                              className="form-group"
                              style={{ marginTop: "-1.5rem" }}
                            >
                              <label>
                                <strong>Added Products</strong>
                              </label>
                              <div className="row">
                                <div className="col-12">
                                  <div
                                    style={{
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      border: "2px solid #000",
                                    }}
                                  >
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>OEM Name</th>
                                          <th>Product Name</th>
                                          <th>SkuName</th>
                                          <th>User Count</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {products.map((product, index) => (
                                          <tr key={index}>
                                            <td>{product.oemName}</td>
                                            <td>{product.productName}</td>
                                            <td>{product.subproductName}</td>
                                            <td>{product.userCount}</td>
                                            <td>
                                              <span
                                                variant="warning"
                                                size="sm"
                                                style={{
                                                  marginLeft: "10px",
                                                  color: "blue",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleEditProduct(
                                                    index,
                                                    product
                                                  )
                                                }
                                              >
                                                <FaEdit />
                                              </span>
                                              {"   "}
                                              <span
                                                variant="danger"
                                                size="sm"
                                                style={{
                                                  color: "red",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleDeleteProduct(index)
                                                }
                                              >
                                                ✘
                                              </span>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="form-group d-flex justify-content-center">
                    <button
                      className="btn btn-primary submit-btn btn-block"
                      type="submit"
                      style={{ width: "100px" }}
                    >
                      Submit
                    </button>
                  </div>
                </Tab>
              </Tabs>{" "}
              {activeTab !== "tab1" && activeTab !== "tab3" && (
                <Button
                  variant="secondary"
                  onClick={handleBackClick}
                  className="mr-2"
                >
                  Back
                </Button>
              )}
              {activeTab !== "tab3" && (
                <Button variant="primary" onClick={handleNextClick}>
                  Next
                </Button>
              )}
              <br></br>
              {activeTab === "tab3" && (
                <div className="form-group d-flex justify-content-center">
                  <button
                    className="btn btn-secondary mr-3" // Add margin to the right of the "Back" button
                    type="button"
                    onClick={handleBackClick}
                  >
                    Back
                  </button>
                  {/* <button className="btn btn-primary" type="submit">
                    Submit
                  </button> */}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerRegister;
